import React, { Component } from 'react';
import { ApiReq, notySend, urlNavigate } from './function';
import Select from 'react-select';
import CustomModal from './CustomModal';
import Loading from '../pages/Loading';

export default class TestingAndInstallerModal extends Component<any, any> {
    constructor(props: any){
      super(props);
      this.state = {
        host:'localhost',
        user:'',
        pass:'',
        name:'',
        testing_file_access:null,
        testing_file_access_answer:'',
        testing_file_access_data:{show:false, show_list:false, data:[]},
        testing_internal_curl:null,
        testing_internal_curl_answer:'',
      }
    }
  
    componentDidMount(){
        setTimeout(()=>{
          this.CheckFilePermission();
        }, 2000)
    }

    CheckFilePermission = () => {
        ApiReq('/tester_and_installer/check_file_permission').then(res => {
            if(res != false){ 
              if(res.success == true){
                this.setState({
                  testing_file_access:true
                })
              }
              else{
                this.setState({
                  testing_file_access:false,
                  testing_file_access_answer:res.error
                })
              }

              let not_perm_files = [];
              for(let i=0; i<res.data.length; i++){
                if(res.data[i].perm !== '0755'){
                  not_perm_files.push(res.data[i])
                }
              }

              if(not_perm_files.length > 0){
                
                this.setState({
                  testing_file_access_data:{show:true, data:not_perm_files}
                })
              }

              this.CheckInternalCurl();

            }
        })
    }

    CheckInternalCurl = () => {
      ApiReq('/tester_and_installer/check_internal_curl').then(res => {
        if(res != false){
          if(res.success == true){
            this.setState({
              testing_internal_curl:true
            })
          }
          else{
            this.setState({
              testing_internal_curl:false,
              testing_internal_curl_answer:res.error
            })
          }
        }
      })
    }

    SetDbConfig = () => {
        ApiReq('/system_config/set_db_config', {host:this.state.host, user:this.state.user, pass:this.state.pass, name:this.state.name}).then(res => {
            if(res != false){
              if(res.success == true){
                this.setState({
                    answer:'success'
                })
                setTimeout(()=>{
                    window.location.reload();
                }, 1000)
              }
              else{
                this.setState({
                    answer:res.error
                })
              }
            }
        })
    }

    GetDbConfig = () => {
        // ApiReq('/system_config/get_db_config').then(res => {
        //     if(res != false){
        //         this.setState({
        //             host:res.host,
        //             user:res.user,
        //             pass:res.pass,
        //             name:res.name
        //         })
        //     }
        // })
    }
  
    RenderAlertForPermFiles = () => {
      if(this.state.testing_file_access_data.show == true){
        return(
          <div>
              <div className="alert alert-warning">
                <strong>system detect {this.state.testing_file_access_data.data.length} files and folder who have another permission !</strong>
                <div><strong onClick={()=>{let t=this.state.testing_file_access_data; t.show_list=!t.show_list; this.setState({testing_file_access_data:t})}} className='c_p'>(click to show more)</strong></div>
                {this.RenderAlertPermFilesNotPermList()}
              </div>
          </div>
        )
      }
      else{
        return null;
      }
    }

    RenderAlertPermFilesNotPermList = () => {
      if(this.state.testing_file_access_data.show_list == true){
        return(
          <div>
              {this.state.testing_file_access_data.data.map((item:any) => {
                return (
                  <div>type: {item.type}; permission: {item.perm}; path:{item.path}</div>
                )
              })}
          </div>
        )
      }
      else{
        return null
      }
    }

    RenderAlertCheckInternalCurl = () => {
      if(this.state.testing_internal_curl == false){
        return(
          <div className="alert alert-danger">
            <strong>{this.state.testing_internal_curl_answer}</strong>
          </div>
        )
      }
      else{
        return null
      }
    }
  
    render() {
        return (
            <CustomModal visible={true} size={'large'} header={'Tester and installer'} callback={()=>{this.props.callback()}}>
                <div className='form-group'>
                    <div>
                        <div>
                            <strong>{(this.state.testing_file_access == null)?<div className="spinner-border spinner-border-sm text-primary"></div>:(this.state.testing_file_access)?<i className="material-icons check text-success">&#xe5ca;</i>:<i className="material-icons clear text-danger">&#xe14c;</i>}  1) Checking files access level (needed permission 0755)</strong>
                            {(this.state.testing_file_access_answer != '')?<div><div className="alert alert-danger"><strong>{this.state.testing_file_access_answer}</strong></div></div>:null}
                            {this.RenderAlertForPermFiles()}
                        </div>
                    </div>
                    <div>
                        <div>
                          <strong>{(this.state.testing_internal_curl == null)?<div className="spinner-border spinner-border-sm text-primary"></div>:(this.state.testing_internal_curl)?<i className="material-icons check text-success">&#xe5ca;</i>:<i className="material-icons clear text-danger">&#xe14c;</i>} 2) Checking the correct operation of curl requests within the system</strong>
                          {this.RenderAlertCheckInternalCurl()}
                        </div>
                    </div>
                    <div>
                        <div>
                            <strong> 3) Connecting to the database</strong>
                        </div>
                        <div>

                        </div>
                        <div className='form-group card card-body'>
                            <label>Host</label>
                            <input value={this.state.host} onInput={(t)=>{this.setState({host:t.currentTarget.value})}} className='form-control' ></input>
                            <label>User</label>
                            <input value={this.state.user} onInput={(t)=>{this.setState({user:t.currentTarget.value})}} className='form-control' ></input>
                            <label>Pass</label>
                            <input value={this.state.pass} onInput={(t)=>{this.setState({pass:t.currentTarget.value})}} className='form-control' ></input>
                            <label>Name</label>
                            <input value={this.state.name} onInput={(t)=>{this.setState({name:t.currentTarget.value})}} className='form-control' ></input>
                            <p className='text-danger text-center'>{this.state.answer}</p>
                            <div className='text-center'>
                                <button onClick={()=>{this.SetDbConfig()}} className="btn btn-outline-primary" style={{borderRadius:20}}>START</button>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <hr></hr>
                <div className='text-center'>
                    if you need help, contact the manager <p></p> Telegram <a href='https://t.me/trafpar' target={'_blank'} style={{textDecoration:'none'}}>@trafpar</a>
                </div>
            </CustomModal>
        )
    }
}

