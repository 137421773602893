import React, { Component } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonSelect, IonSelectOption, IonItem } from '@ionic/react';
import { notySend, ApiReq, urlNavigate, getUserData } from '../components/function';
import UpdateCacheModal from '../components/UpdateCacheModal';
import BrandManagersSelect from '../components/BrandManagersSelect';

export default class AddBrokerPage extends Component<any, any> {
    constructor(props: any){
      super(props);
      this.state = {
        name:'',
        comment:'',
        brand_manager:{label:'select', value:null},
        updateCacheModal:false
      }
    }
  
    componentDidMount(){
      
    }


    AddBrocker = () => {
      if(this.state.name != ''){
        ApiReq('/brokers/add_broker/', {name:this.state.name, comment:this.state.comment, brand_manager:this.state.brand_manager}).then(res => {
          if(res != false){
            if(res.success == true){
              this.setState({
                updateCacheModal:true
              })
              //localStorage.removeItem(window.location.origin+'_pixelcrm_cache_select_data')
              setTimeout(()=>{
                let fastinter = setInterval(()=>{
                  if(this.state.updateCacheModal == false){
                    clearInterval(fastinter);
                    urlNavigate('/edit_broker/'+res.broker_id+'/');
                  }
                }, 10);
              }, 100)
              
            }
            else{
              notySend('error', 'error #'+res.error+'\n'+res.info);
            }
          }
        })
      }
      else{
        notySend('error', 'name nust not be empty!');
      }
      
    }
  
  
    render() {
      return (
        <div className='page-content'>
          <div className="card">
            <div className='card-body'>
                <div className='form-group'>
                    <label>Name*</label>
                    <input onInput={(t)=>{this.setState({name:t.currentTarget.value})}} className='form-control'></input>
                    {
                      (getUserData('role') == 'admin' ? <><label>Brand manager</label><BrandManagersSelect value={this.state.brand_manager} onChange={(d:any)=>{this.setState({brand_manager:d})}}></BrandManagersSelect></>:null)
                    }
                    
                    
                    <label>Comment</label>
                    <textarea onInput={(t)=>{this.setState({comment:t.currentTarget.value})}} className='form-control'></textarea>
                    
                    <br></br>
                    <button onClick={()=>{this.AddBrocker()}} className='btn btn-primary form-control'>Add Broker</button>
                </div>
            </div>
          </div>
          <UpdateCacheModal callback={()=>{this.setState({updateCacheModal:false})}} visible={this.state.updateCacheModal}></UpdateCacheModal>
        </div>
      );
    }
}

