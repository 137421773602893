import React, { Component } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonSelect, IonSelectOption, IonItem, IonPopover } from '@ionic/react';
import { ApiReq, notySend, getApiToken, getApiDomain, getUserData, urlNavigate, getUrlPath } from '../components/function';
import PartnersSelect from '../components/PartnersSelect';
import BrokersSelect from '../components/BrokersSelect';
import WeekDaysSelect from '../components/WeekDaysSelect';
import TimeZonesSelect from '../components/TimeZonesSelect';
import GeoListSelect from '../components/GeoListSelect';
import LangListSelect from '../components/LangListSelect';
import ManagersSelect from '../components/ManagersSelect';


import Select from 'react-select'
const options = [
  { value: 'CPA', label: 'CPA' },
  { value: 'CPL', label: 'CPL' },
  { value: 'CRG', label: 'CRG' },
]



export default class AddCempaignPage extends Component<any, any> {
    constructor(props: any){
      super(props);
      this.state = {
        limiter:{label:'false', value:false},
        autochecker_cl:{label:'false', value:false},
        resending_lead:{label:'false', value:false},
        c_status:{label:'ON', value:'ON'},
        c_name:'',
        c_comment:'',
        c_cap:0,
        c_country:[{value:'AD', label:'AD'}],
        c_lang:[{value:'ab', label:'ab'}],
        c_time_zone:{"value":"Etc/GMT+12","label":"(UTC-12:00) International Date Line West (Etc/GMT+12)"},
        c_time_zone_days:[{ value: 'Mondey', label: 'Mondey' }],
        c_time_zone_time_from:'00:00',
        c_time_zone_time_to:'00:00',
        c_model:{ value: 'CPA', label: 'CPA' },
        c_cpa_model:0,
        c_crg_model:{value:0, percent:0},
        c_cpl_model:0,
        c_manager:{value:null, label:'select'},
        c_partner:{value:null, label:'select'},
        c_broker:{value:null, label:'select'},
        c_checker_funnel:{label:'false', value:false},
        c_funnel_data:'',
      }
    }
  
    componentDidMount(){
      if(getUrlPath().length >= 2){
        if(getUrlPath()[1] != ''){
          this.setState(JSON.parse(decodeURIComponent(atob(getUrlPath()[1]))))
        }
      }
    }

    AddCampaign = () => {
      let checkparams = true;
      let params = [this.state.c_name, this.state.c_cap, this.state.c_partner.value, this.state.c_broker.value];
      if(this.state.limiter.value == true){
        params.push(this.state.c_time_zone_time_from);
        params.push(this.state.c_time_zone_time_to);
        params.push(this.state.c_time_zone_days);
      }
      
      for(let i = 0; i<params.length; i++){
        if(params[i] == '' || params[i] == null){
          checkparams = false;
          break;
        }
      }

      if(checkparams == false){
        notySend('error', 'All parameters marked with * must not be empty!');
      }
      else{
        let campaign_data:any = {
          limiter:this.state.limiter.value,
          autochecker_cl:this.state.autochecker_cl.value,
          resending_lead:this.state.resending_lead.value,
          status:this.state.c_status.value,
          name:this.state.c_name,
          comment:this.state.c_comment,
          cap:this.state.c_cap,
          country:this.state.c_country,
          lang:this.state.c_lang,
          model:this.state.c_model.value,
          partner:this.state.c_partner.value,
          broker:this.state.c_broker.value,
          checker_funnel:this.state.c_checker_funnel.value,
          funnel_data:this.state.c_funnel_data,
        }

        switch (campaign_data.model) {
          case 'CPA':
            campaign_data.model_deposit = this.state.c_cpa_model;
            break;
          case 'CPL':
            campaign_data.model_deposit = this.state.c_cpl_model;
            break;
          case 'CRG':
            campaign_data.model_deposit = this.state.c_crg_model.value;
            campaign_data.model_percent = this.state.c_crg_model.percent;
            break;
        }

        if(campaign_data.limiter == true){
          campaign_data.time_zone_name = this.state.c_time_zone.label;
          campaign_data.time_zone_value = this.state.c_time_zone.value;
          campaign_data.time_zone_days = this.state.c_time_zone_days;
          campaign_data.time_zone_time_from = this.state.c_time_zone_time_from;
          campaign_data.time_zone_time_to = this.state.c_time_zone_time_to;
        }

        if(getUserData('role') == 'admin'){
          if(this.state.c_manager.value != null){
            campaign_data.manager = this.state.c_manager.value;
          }
        }

        ApiReq('/campaigns/add/', campaign_data).then(res => {
          if(res != false){
            if(res.success == true){
              urlNavigate('/edit_campaign/'+res.campaign_id+'/')
            }
            else{
              notySend('error', 'error #'+res.error+'\n'+res.info);
            }
          }
        })
      }
    }

    RenderModelForm = () => {
      if(this.state.c_model.value == 'CPA'){
        return(
          <div>
            <label>deposit*</label>
            <input value={this.state.c_cpa_model} onInput={(t)=>{this.setState({c_cpa_model:t.currentTarget.value})}} className="form-control" type={'number'}></input>
          </div>
        )
      }
      if(this.state.c_model.value == 'CRG'){
        return(
          <div className='row'>
            <div className='col'>
              <label>deposit*</label>
              <input value={this.state.c_crg_model.value} onInput={(t)=>{this.setState({c_crg_model:{value:t.currentTarget.value, percent:this.state.c_crg_model.percent}})}} className="form-control" type={'number'}></input>
            </div>
            <div className='col'>
              <label>conversion percentage*</label>
              <input value={this.state.c_crg_model.percent} onInput={(t)=>{this.setState({c_crg_model:{value:this.state.c_crg_model.value, percent:t.currentTarget.value}})}} className="form-control" type={'number'}></input>
            </div>
          </div>
        )
      }
      if(this.state.c_model.value == 'CPL'){
        return(
          <div>
            <label>lead price*</label>
            <input value={this.state.c_cpl_model} onInput={(t)=>{this.setState({c_cpl_model:t.currentTarget.value})}} className="form-control" type={'number'}></input>
          </div>
        )
      }
    }

    RenderFormLimiter = () => {
      if(this.state.limiter.value == false){
        return null
      }
      else{
        return(
            <div>
              <label>Time Zones*</label>
              <TimeZonesSelect value={this.state.c_time_zone} onChange={(t:any)=>{this.setState({c_time_zone:t})}}></TimeZonesSelect>
              <div className='row'>
                <div className='col'>
                  <label>Days*</label>
                  <WeekDaysSelect onChange={(t:any)=>{this.setState({c_time_zone_days:t})}}></WeekDaysSelect>
                </div>
                <div className='col'>
                  <label>Time from*</label>
                  <input value={this.state.c_time_zone_time_from} onInput={(t)=>{this.setState({c_time_zone_time_from:t.currentTarget.value})}} className='form-control' type={'time'}></input>
                </div>
                <div className='col'>
                  <label>Time to*</label>
                  <input value={this.state.c_time_zone_time_to} onInput={(t)=>{this.setState({c_time_zone_time_to:t.currentTarget.value})}} className='form-control' type={'time'}></input>
                </div>
              </div>
            </div>
        )
      }
    }


    RenderFormAutoCheckerCL = () => {
      // if(this.state.autochecker_cl.value == false){
      //   return null
      // }
      // else{
      //   return(
      //       <div>
      //           <label>country*</label>
      //           {/* <input list='list_geo' value={this.state.c_country} onInput={(t)=>{this.setState({c_country:t.currentTarget.value})}} className='form-control'></input> */}
      //           <GeoListSelect value={this.state.c_country} onChange={(r:any)=>{this.setState({c_country:r})}}></GeoListSelect>
      //           <label>lang*</label>
      //           <LangListSelect value={this.state.c_lang} onChange={(r:any)=>{this.setState({c_lang:r})}}></LangListSelect>
      //           {/* <input value={this.state.c_lang} onInput={(t)=>{this.setState({c_lang:t.currentTarget.value})}} className='form-control'></input> */}
      //       </div>
      //   )
      // }
      return(
        <div>
            <label>country*</label>
            {/* <input list='list_geo' value={this.state.c_country} onInput={(t)=>{this.setState({c_country:t.currentTarget.value})}} className='form-control'></input> */}
            <GeoListSelect isMulti={true} value={this.state.c_country} onChange={(r:any)=>{this.setState({c_country:r})}}></GeoListSelect>
            <label>lang*</label>
            <LangListSelect isMulti={true} value={this.state.c_lang} onChange={(r:any)=>{this.setState({c_lang:r})}}></LangListSelect>
            {/* <input value={this.state.c_lang} onInput={(t)=>{this.setState({c_lang:t.currentTarget.value})}} className='form-control'></input> */}
        </div>
      )
    }

    RenderFormMenegerSelect = () => {
      if(getUserData('role') != 'admin'){
        return null
      }
      else{
        return(
            <div>
                <label>manager</label>
                <ManagersSelect value={this.state.c_manager}  onChange={(r:any)=>{this.setState({c_manager:r})}}></ManagersSelect>
            </div>
        )
      }
    }
  
  
    render() {
      return (
        <div className='page-content'>
          <div className="card">
            <div className='card-body'>
              <div className='form-group'>
                  <label>Name*</label>
                  <input value={this.state.c_name} onInput={(t)=>{this.setState({c_name:t.currentTarget.value})}} className='form-control'></input> 
                  <label>Cap*</label>
                  <input value={this.state.c_cap} onInput={(t)=>{this.setState({c_cap:t.currentTarget.value})}} className='form-control' type={'number'} ></input> 
                  <label>Comment</label>
                  <textarea value={this.state.c_comment} onInput={(t)=>{this.setState({c_comment:t.currentTarget.value})}} className='form-control' ></textarea>
                  <label>partner*</label>
                  <PartnersSelect value={this.state.c_partner} onChange={(t:any)=>{this.setState({c_partner:t})}}></PartnersSelect>
                  <label>broker*</label>
                  <BrokersSelect value={this.state.c_broker} onChange={(t:any)=>{this.setState({c_broker:t})}}></BrokersSelect>
                  {this.RenderFormMenegerSelect()}
                  <label>model*</label>
                  <Select value={this.state.c_model} onChange={(t)=>{this.setState({c_model:t})}} options={options} />
                  {this.RenderModelForm()}
                  <label>status*</label>
                  <Select value={this.state.c_status} onChange={(t)=>{this.setState({c_status:t})}}  options={[{label:'ON', value:'ON'},{label:'OFF', value:'OFF'}]} />
                  {/* <label>auto checker country & lang*</label>
                  <Select value={this.state.autochecker_cl} onChange={(t)=>{this.setState({autochecker_cl:t})}} options={[{label:'true', value:true},{label:'false', value:false}]} /> */}
                  {this.RenderFormAutoCheckerCL()}
                  <label>checker funnel</label>
                  <div className='row'>
                    <div className='col'>
                      <Select value={this.state.c_checker_funnel} onChange={(t)=>{this.setState({c_checker_funnel:t})}} options={[{label:'true', value:true},{label:'false', value:false}]}></Select>
                    </div>
                    <div className='col'>
                      <input value={this.state.c_funnel_data} onInput={(t)=>{this.setState({c_funnel_data:t.currentTarget.value})}} placeholder='write funnel' className='form-control'></input>
                    </div>
                  </div>
                  <label>Lead sending limiter* (used when the broker can accept leads in a certain period of time or days)</label>
                  <Select value={this.state.limiter} onChange={(t)=>{this.setState({limiter:t})}} options={[{label:'true', value:true},{label:'false', value:false}]} />
                  {this.RenderFormLimiter()}
                  {/* <label>resending lead* (Sending a lead to other brokers from the list if the lead is not accepted by the first broker)</label>
                  <Select value={this.state.resending_lead} onChange={(t)=>{this.setState({resending_lead:t})}} options={[{label:'true', value:true},{label:'false', value:false}]} /> */}
                  <hr></hr>
                  
                  <button onClick={()=>{this.AddCampaign()}} className='btn btn-primary form-control'>add campaign</button>
                  
              </div>
            </div>
          </div>
        </div>
      );
    }
}

