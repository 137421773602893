import React, { Component } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonSelect, IonSelectOption, IonItem } from '@ionic/react';
import { ApiReq, notySend, getApiToken, getApiDomain, getUserData, urlNavigate, getUrlPath, isJson } from '../components/function';

import Loading from '../pages/Loading';
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-php_laravel_blade";
import "ace-builds/src-noconflict/theme-monokai";

let add_lead_example_code = `<?php
$headers = [
    'Content-Type: application/json',
    'Partner-Token: `+getUserData('partner_token')+`'
];

$j_data = array(
    'first_name' => 'test_lead',
    'last_name' => 'test_lead',
    'email' => 'testlead@testlead.com',
    'phone' => '+19185789789',
    'country' => 'US',
    'lang' => 'en',
    'user_ip' => '1111.1111.1111.1111', 
    'funnel' => 'funnel',
    'utm_comment' => 'utm comment'
);

$ch = curl_init();
curl_setopt($ch, CURLOPT_URL, '`+getApiDomain()+`/leads/add_lead/');
curl_setopt($ch, CURLOPT_HEADER, 0);
curl_setopt($ch, CURLOPT_HTTPHEADER, $headers);
curl_setopt($ch, CURLOPT_RETURNTRANSFER,true);
curl_setopt($ch, CURLOPT_POST, true);
curl_setopt($ch, CURLOPT_POSTFIELDS, json_encode($j_data));
echo curl_exec($ch);`;

let get_leads_example_code = `<?php


$leads = json_decode(file_get_contents('`+getApiDomain()+`/leads/get_leads_partner/?partner_token=`+getUserData('partner_token')+`&leads_per_page=100'),true); //array leads 
echo $leads;
`;

export default class IntegrationPage extends Component<any, any> {
    constructor(props: any){
      super(props);
      this.state = {
        chart:false,
        example_code:false,
        type_example_code:'add_lead',
        code:add_lead_example_code,
        answer:'',
        run_btn:false,
        run_btn_loader:<div className="spinner-border text-warning spinner-border-sm"></div>
      }
    }
  
    componentDidMount(){
        
      
    }

    SendCode = () => {
        this.setState({
          run_btn:true
        })
        const requestOptions = {
            method: 'POST',
            headers: {   'Auth-Token': getApiToken()},
            body:JSON.stringify({code:this.state.code})
        };
        fetch(getApiDomain()+'/sendbox/send_code/', requestOptions)
        .then(response => response.text())
        .then(data => {
            let answer = data;
            if(isJson(answer) == true){
              answer = JSON.parse(answer)
              answer = JSON.stringify(answer, undefined, 4)
            }
            this.setState({
                answer:answer,
                run_btn:false
            })
        });
    }    

    RenderExampleCode = () => {
        return (
            <>
                <AceEditor
                    style={{width:'100%', height:'50vh', borderRadius:5}}
                    mode="php"
                    theme="monokai"
                    value={this.state.code}
                    highlightActiveLine={false}
                    onChange={(val)=>{
                        this.setState({
                            code:val
                        })
                        }}
                    // name="UNIQUE_ID_OF_DIV"
                    editorProps={{ $blockScrolling: true }}
                    setOptions={{
                        // fontFamily:'menlo',
                        printMarginColumn:-1,
                        enableBasicAutocompletion: false,
                        enableLiveAutocompletion: true,
                        enableSnippets: true,
                        showLineNumbers: true,
                        tabSize: 2
                    }}
                />
                <br></br>
                <div className='row'>
                    <div className='col'>
                        <span>Answer</span>
                    </div>
                    <div className='col' style={{textAlign:'right'}}>
                        <button disabled={this.state.run_btn} onClick={()=>{this.SendCode()}} className='btn btn-primary'>{this.state.run_btn ? this.state.run_btn_loader : null} Run code</button>
                    </div>
                </div>
                <hr></hr>
                <textarea defaultValue={this.state.answer} className='form-control' style={{minHeight:200}}></textarea>
            </>
          );
    }
  
    RenderPageDocumentation = () => {
    return (
        <>
        <div><span style={{fontSize:22, fontWeight:'bold'}}>Add lead </span> <span onClick={()=>{this.setState({example_code:true, type_example_code:'add_lead', code:add_lead_example_code})}} style={{fontSize:11}} className='badge bg-primary rounded-pill ms-auto c_p' >show example code</span></div>
                <div>Example POST URL : <div className='card card-body'>{getApiDomain()+'/leads/add_lead/'}</div></div>
                <br></br>
                <div>Example POST headers :
                    <div className='card card-body'>
                        <pre>
                            <code>
{`{
	"Content-type": "application/json",
	"Partner-Token": "`+getUserData('partner_token')+`",
}`}
                            </code>
                        </pre>
                    </div>
                </div>
                <br></br>
                <div>Example POST body : 
                    <div className='card card-body'>
                        <pre>
                            <code>
{`{
	"first_name": "test",
	"last_name": "test",
	"email": "test_1209@test.com",
	"phone": "+123123123",
	"country": "RU",
	"lang":"ru",
	"campaign_id": 15, //optional param, to use contact the manager!
	"password": "123123",
	"user_ip":"111.111.111.111",
	"funnel":"test funnel", 
	"utm_content":"content", //optional param
	"utm_comment":"comment", //optional param
}`}
                            </code>
                        </pre>
                    </div>
                </div>

                <div>Example success answer : 
                    <div className='card card-body'>
                        <pre>
                            <code>
{`{
	"success": true,
	"lead_id": 214,
	"status": "New",
	"autologin_url": "https://autologin_url.com",
}`}
                            </code>
                        </pre>
                    </div>
                </div>

                <div>Example error answer : 
                    <div className='card card-body'>
                        <pre>
                            <code>
{`{"success": false, "error":4, "info": "param email incorrect!"}\n`}
{`{"success": false, "info": "Partner-Token undefined or invalid"}\n`}
{`{"success": false, "error":5, "info": "partner with this token undefined"}\n`}
{`{"success": false, "error":5, "info": "Campaign with this country and lang undefined"}\n`}
{`{"success": false, "info": "Error in transferring the lead to the broker"}\n`}
                            </code>
                        </pre>
                    </div>
                </div>
                <hr></hr>
                <div><span style={{fontSize:22, fontWeight:'bold'}}>Get leads </span> <span onClick={()=>{this.setState({example_code:true, type_example_code:'get_leads', code:get_leads_example_code})}} style={{fontSize:11}} className='badge bg-primary rounded-pill ms-auto c_p' > show example code</span></div>
                {/* <h4>Get leads <span onClick={()=>{this.setState({example_code:true, type_example_code:'get_leads', code:get_leads_example_code})}} style={{fontSize:11, position:'absolute', left:110, marginTop:2}} className='badge bg-primary rounded-pill ms-auto c_p' > show example code</span></h4> */}
                <div>Example GET URL : <div className='card card-body'>{getApiDomain()+'/leads/get_leads_partner/?partner_token='+getUserData('partner_token')}</div></div>
                <div>Example GET URL with params from, to, leads_per_page and page : <div className='card card-body'>{getApiDomain()+'/leads/get_leads_partner/?partner_token='+getUserData('partner_token')+'&from=2023-04-07&to=2023-04-20&leads_per_page=100&page=2'}</div></div>
                <div>Example success answer : 
                    <div className='card card-body'>
                        <pre>
                            <code>
{`{
  "success": true,
  "total_leads": "9",
  "pages": 1,
  "current_page": 1,
  "data": [
    {
      "id": "27",
      "status": "New",
      "ftd": false
    },
    {
      "id": "28",
      "status": "New",
      "ftd": false
    },
    {
      "id": "29",
      "status": "New",
      "ftd": false
    },
    {
      "id": "30",
      "status": "New",
      "ftd": false
    },
    {
      "id": "31",
      "status": "New",
      "ftd": false
    },
    {
      "id": "32",
      "status": "New",
      "ftd": false
    },
    {
      "id": "33",
      "status": "New",
      "ftd": false
    },
    {
      "id": "34",
      "status": "New",
      "ftd": false
    },
    {
      "id": "35",
      "status": "New",
      "ftd": false
    }
  ]
}`}
                            </code>
                        </pre>
                    </div>
                </div>

                <div>Example error answer : 
                    <div className='card card-body'>
                        <pre>
                            <code>
{`{"success": false, "error":5, "info": "Partner-Token undefined!"}\n`}
{`{
  "success": true,
  "total_leads": "9",
  "pages": 1,
  "current_page": 10,
  "error": "The page you specified is out of range and therefore the data array is empty!",
  "data": []
}`}
                            </code>
                        </pre>
                    </div>
                </div>


        </>
      );
    }

   
  
    render() {
      return(
        <div className='page-content'>
          <div className="card">
            <div className='card-body'>
                <div>
                    {this.state.example_code? <span onClick={()=>{this.setState({example_code:false})}} style={{opacity:0.6}} className='c_p'><i style={{fontSize:20}} className="material-icons arrow_circle_left">&#xeaa7;</i></span>:null}
                    <span style={{fontSize:20}}> Integration manual</span>
                </div>
                <hr></hr>
                {this.state.example_code ? this.RenderExampleCode() : this.RenderPageDocumentation()}
            </div>
          </div>
        </div>
      )
    }
}

