import React, { Component } from 'react';
import { ApiReq, notySend, urlNavigate, getClientConfig, sync_config } from '../components/function';
import StartSettingModal from '../components/StartSettingModal';
import TestingAndInstallerModal from '../components/TestingAndInstallerModal';
export default class LoginPage extends Component<any, any> {
    constructor(props: any){
      super(props);
      this.state = {
        remember:false,
        login:'',
        password:'',
        setting_modal:false,
        page_load:true,
        testingAndInstallerModal:false
      }
    }
  
    componentDidMount(){
      document.addEventListener("keydown", (e)=>{
        if(e.key === 'F2'){
          this.setState({
            testingAndInstallerModal:true
          })
        }
      });

      // sync_config();
      if(localStorage.getItem(window.location.origin+'_pixelcrm_user_login') != undefined){
        let user_login:any = localStorage.getItem(window.location.origin+'_pixelcrm_user_login');
        user_login = JSON.parse(user_login)
        this.setState({
          login:user_login.login,
          password:user_login.password
        })
      }
      // this.CheckSettingModal();
    }

    SetRemember = () => {
      if(this.state.remember == false){
        this.setState({
          remember:true
        })
      }
      else{
        this.setState({
          remember:false
        })
      }
    }
  
    Login = () => {
      if(this.state.login != '' && this.state.password != ''){
        this.setState({
          page_load:false
        })
        if(this.state.remember == true){
          localStorage.setItem(window.location.origin+'_pixelcrm_user_login', JSON.stringify({login:this.state.login, password:this.state.password}))
        }
        ApiReq('/auth/login/', {login:this.state.login, password:this.state.password}).then(res => {
          // console.log(res)
          if(res != false){
            if(res.success == true){
              localStorage.setItem(window.location.origin+'_pixelcrm_user_data', JSON.stringify(res.data))
              localStorage.setItem(window.location.origin+'_pixelcrm_check_last_auth_date', String(Date.now()))
              // if(this.state.remember == true){
              //   localStorage.setItem(window.location.origin+'_pixelcrm_user_login', JSON.stringify({login:this.state.login, password:this.state.password}))
              // }
              urlNavigate('/main/');
            }
            else{
              this.setState({
                page_load:true
              })
              notySend('error', 'error #'+res.error+'\n'+res.info)
            }
          }
        })
       
      }
      else{
        notySend('error', 'email and password must not be empty!');
      }
    }

    CheckSettingModal = () => {
      ApiReq('/system_config/get_db_config').then(res => {
        if(res != false){
            if(res.connect == false){
              this.setState({
                setting_modal:true
              })
            }
        }
      })
    }
  
    render() {
      return (
        <main className="authentication-content">
          {this.state.setting_modal ? <StartSettingModal></StartSettingModal> : null}
          {this.state.testingAndInstallerModal ? <TestingAndInstallerModal callback={()=>{this.setState({testingAndInstallerModal:false})}}></TestingAndInstallerModal>:null}
          <div className="container-fluid">
            <div className="authentication-card">
              <div className="card">
                <div className="row">
                  <div className="col-lg-6 bg-login d-flex align-items-center justify-content-center text-center">
                    {/* <img src="/admin/template/assets/images/error/login-img.jpg" className="img-fluid" alt=""> */}
                    <span>
                      <h1 style={{textTransform:'uppercase', color:'white'}}>{getClientConfig('name')}</h1>
                      <br></br>
                      <span><a style={{textDecoration:'none',color:'white', opacity:0.8}} href='https://t.me/trafpar'>pixelcrm @trafpar</a></span>
                    </span>
                    
                  </div>
                  
                  <div className="col-lg-6 ">
                    <div className="card-body p-4 p-sm-5 ">
                      <h3 style={{fontSize:30}} className="card-title text-center text-white">Authorization</h3>
                      <div className="text-center text-danger"></div>
                      <div  className="form-body">
                        
                        <div className="login-separater text-center mb-4 text-white"> <span style={{backgroundColor:'transparent'}}></span>
                          <hr></hr>
                        </div>
                          <div className="row g-3">
                              
                            <div className="col-12">
                              <label  className="form-label text-white">Email</label>
                              <div className="ms-auto position-relative">
                                <div className="position-absolute top-50 translate-middle-y search-icon px-3 text-white"><i className="material-icons local_post_office icon-input">&#xe554;</i></div>
                                <input defaultValue={this.state.login} onInput={(e)=>{this.setState({login:e.currentTarget.value})}} type="email" style={{borderRadius:50}} className="form-control  ps-5" placeholder="Write email"></input>
                              </div>
                            </div>
                            
                            <div className="col-12">
                              <label  className="form-label text-white">Password</label>
                              <div className="ms-auto position-relative">
                                <div className="position-absolute top-50 translate-middle-y search-icon px-3 text-white"><i className="material-icons https icon-input">&#xe88d;</i></div>
                                <input defaultValue={this.state.password} onInput={(e)=>{this.setState({password:e.currentTarget.value})}} type="password" style={{borderRadius:50}} className="form-control radius-30 ps-5" placeholder="Write password"></input>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="form-check form-switch">
                                <label className="form-check-label text-white">
                                  <input checked={this.state.remember} onChange={()=>{this.SetRemember()}}  type="checkbox" className="form-check-input"></input>Remember me
                                </label>
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="d-grid">
                                <button onClick={()=>{this.Login()}} style={{borderRadius:50}} className="btn btn-primary radius-30">{(this.state.page_load == false) ? <div className="spinner-border text-warning spinner-border-sm"></div>:null} Login</button>
                              </div>
                              {/* <div className='text-center' style={{marginTop:10}}>
                                <span className='text-warning' style={{fontSize:8, textAlign:'center'}}>press F2 to run tester and installer window</span>
                              </div> */}
                            </div>
                            <div className="col-12">
                              {/* <p className="mb-0 text-center">Нет аккаунта fastpush? <a href="authentication-signup.html">Регистрация</a></p> */}
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      );
    }
}


