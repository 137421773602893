import React, { Component } from 'react';
import { ApiReq, notySend, urlNavigate, clear_selected_array_from_selectpoint } from '../components/function';
import Select from 'react-select'

let statuses_data = [
  {
    "label": "select",
    "value": null
  },
  {
    "value": "New",
    "label": "New"
  },
  {
    "value": "No answer",
    "label": "No answer"
  },
  {
    "value": "Not interested",
    "label": "Not interested"
  },
  {
    "value": "Callback",
    "label": "Callback"
  },
  {
    "value": "Wrong info",
    "label": "Wrong info"
  },
  {
    "value": "Wrong number",
    "label": "Wrong number"
  },
  {
    "value": "Wrong country",
    "label": "Wrong country"
  },
  {
    "value": "Trash",
    "label": "Trash"
  },
  {
    "value": "Under 18",
    "label": "Under 18"
  },
  {
    "value": "Duplicate",
    "label": "Duplicate"
  },
  {
    "value": "No potential",
    "label": "No potential"
  },
  {
    "value": "Low potential",
    "label": "Low potential"
  },
  {
    "value": "High potential",
    "label": "High potential"
  },
  {
    "value": "In progress",
    "label": "In progress"
  },
  {
    "value": "Partial deposit",
    "label": "Partial deposit"
  },
  {
    "value": "Deposited",
    "label": "Deposited"
  }
];

export default class StatusSelect extends Component<any, any> {
    constructor(props: any){
      super(props);
      this.state = {
        select_data:[
            
        ],
      }
    }
  
    componentDidMount(){
        if(this.props.isMulti == true){
          statuses_data = clear_selected_array_from_selectpoint(statuses_data);
        }
        
        this.setState({
          select_data:statuses_data,
        })
      
        // let select_data:any =  [{label:'select', value:null}];
        // ApiReq('/statuses_replacement/get_status_list/').then(res => {
        //     if(res != false){
        //       if(res.success == true){
                
        //         for(let i=0; i<res.data.length; i++){
        //             select_data.push({value:res.data[i].name, label:res.data[i].name});
        //         }

        //         this.setState({
        //           select_data:select_data,
        //         })
               
        //       }
        //       else{
        //         notySend('error', 'error #'+res.error+'\n'+res.info)
        //       }
        //     }
        // })

        
    }

    Change = (res:any) => {
        if(this.props.onChange != null){
            this.props.onChange(res);
        }
        // let select_data = [{value:null, label:null}];
        // for(let i=0; i<this.state.select_data.length; i++){
        //     if(res.value == this.state.select_data[i].value){
        //         select_data[0].value = res.value;
        //         select_data[0].label = res.label;
        //     }
        //     else{
        //         select_data.push(this.state.select_data[i]);
        //     }
        // }
        // this.setState({
        //     select_data:select_data
        // })
    }
  
  
    render() {
        if(this.props.isMulti == true){
          return (
              <Select isMulti={true} onChange={(res)=>{this.Change(res)}}  options={this.state.select_data} />
          )
        }
        else{
          if(this.props.value != null && this.props.value != undefined){
            return(
                <Select value={this.props.value}  onChange={(res)=>{this.Change(res)}}   options={this.state.select_data} />
            )
          }
          else{
              return (
                  <Select isMulti={true} onChange={(res)=>{this.Change(res)}}   options={this.state.select_data} />
              )
          }
        }
        
    }
}

