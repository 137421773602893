import React, { Component } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonSelect, IonSelectOption, IonItem } from '@ionic/react';
import { notySend, ApiReq, getUrlPath, urlNavigate, getUserData } from '../components/function';
import { unescape } from 'querystring';
import CustomModal from '../components/CustomModal';
import Loading from '../pages/Loading';
import UpdateCacheModal from '../components/UpdateCacheModal';
import BrandManagersSelect from '../components/BrandManagersSelect';

let url = getUrlPath();
export default class EditBrokerPage extends Component<any, any> {
    constructor(props: any){
      super(props);
      this.state = {
        table_data:[],
        customModal:false,
        page_lock:true,
        updateCacheModal:false
      }
    }

    UpdateData = (type:any, data:any) => {
      let table_data:any = this.state.table_data;
      table_data[type] = data;
      this.setState({
        table_data:table_data
      })
    }
  
    componentDidMount(){
      if(Number.isInteger(Number(url[1]))){
        ApiReq('/brokers/get_brokers/', {select:['id', 'name', 'comment', 'brand_manager'], where:[{id:url[1]}]}).then(res => {
          if(res != false){
            if(res.success == true){
              this.setState({
                table_data:res.data[0],
                page_lock:false
              })
              
            }
            else{
              notySend('error', 'error #'+res.error+'\n'+res.info)
            }
          }
        })
      }
      else{
        notySend('error', 'incorrect url, dotn have broker id')
      }
    }


    UpdateBroker = () => {
      if(this.state.table_data.name != ''){
        ApiReq('/brokers/update_broker/', {name:this.state.table_data.name, comment:this.state.table_data.comment, brand_manager:this.state.table_data.brand_manager, broker_id:url[1]}).then(res => {
          if(res != false){
            if(res.success == true){
              //localStorage.removeItem(window.location.origin+'_pixelcrm_cache_select_data')
              this.setState({
                updateCacheModal:true
              })
              notySend('success', 'success')
            }
            else{
              notySend('error', 'error #'+res.error+'\n'+res.info)
            }
          }
        })
      }
      else{
        notySend('error', 'param name must not by empty!')
      }
    }

    DeleteBroker = () => {
      ApiReq('/brokers/delete_broker/', {broker_id:url[1]}).then(res => {
        if(res != false){
          if(res.success == true){
            // notySend('success', 'success')
            this.HideModal();
            this.setState({
              updateCacheModal:true
            })
            //localStorage.removeItem(window.location.origin+'_pixelcrm_cache_select_data')
            
            setTimeout(()=>{
              let fastinter = setInterval(()=>{
                if(this.state.updateCacheModal == false){
                  clearInterval(fastinter);
                  urlNavigate('/brokers/');
                }
              }, 10);
            }, 800)
          }
          else{
            notySend('error', 'error #'+res.error+'\n'+res.info)
          }
        }
      })
    }
  
    HideModal = () => {
      this.setState({
        customModal:false
      })
    }
  
    render() {
      return (
        <div className='page-content'>
          <div className="card">
            <div className='card-body'>
                <div className='form-group'>
                    <label>Id</label>
                    <input disabled={true} defaultValue={this.state.table_data.id} className='form-control'></input>
                    <label>Name*</label>
                    <input onInput={(t)=>{this.UpdateData('name', t.currentTarget.value)}} defaultValue={this.state.table_data.name} className='form-control'></input>
                    {
                      (getUserData('role') == 'admin' ? <><label>Brand manager</label><BrandManagersSelect value={this.state.table_data.brand_manager} onChange={(d:any)=>{this.UpdateData('brand_manager', d)}}></BrandManagersSelect></>:null)
                    }
                    <label>Comment</label>
                    <textarea onInput={(t)=>{this.UpdateData('comment', t.currentTarget.value)}} defaultValue={this.state.table_data.comment} className='form-control'></textarea>
                    <br></br>
                    <div>
                        <div className='text-center'> 
                          {this.state.page_lock ? <div><Loading></Loading></div> : null}
                          Integration code
                        </div>
                        <br></br>
                        <div className='row'>
                            <div className='col'>
                                <button disabled={this.state.page_lock} onClick={()=>{window.location.href = '/edit_integration_code/'+btoa((encodeURIComponent(JSON.stringify({broker_id:this.state.table_data.id, broker_name:this.state.table_data.name, type_integ:'add'}))))+'/'}}   className='btn btn-outline-info form-control text-white'>add leads integration code</button>
                            </div>
                            <div className='col'>
                                <button disabled={this.state.page_lock} onClick={()=>{window.location.href = '/edit_integration_code/'+btoa((encodeURIComponent(JSON.stringify({broker_id:this.state.table_data.id, broker_name:this.state.table_data.name, type_integ:'update'}))))+'/'}}  className='btn btn-outline-info form-control text-white'>update leads integration code</button>
                            </div>
                        </div>
                    </div>
                    <br></br>
                    <button disabled={this.state.page_lock} onClick={()=>{this.UpdateBroker()}} className='btn btn-primary form-control'>Save</button>
                    <hr></hr>
                    <button disabled={this.state.page_lock} onClick={()=>{this.setState({customModal:true})}} className='btn btn-outline-danger form-control'>Delete</button>
                </div>
            </div>
          </div>
          <UpdateCacheModal callback={()=>{this.setState({updateCacheModal:false})}} visible={this.state.updateCacheModal}></UpdateCacheModal>
          <CustomModal visible={this.state.customModal} header={'Attention!'} callback={this.HideModal}>
            <div className='text-center'>
              <h4>Confirm the deletion request!</h4>
            </div>
            <br></br>
            <button onClick={()=>{this.DeleteBroker()}} className='btn btn-outline-danger form-control'>delete</button>
          </CustomModal>
        </div>
      );
    }
}

