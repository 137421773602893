import React, { Component } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonSelect, IonSelectOption, IonItem } from '@ionic/react';

import Select from 'react-select'
const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' }
]



export default class AddPartnerPage extends Component<any, any> {
    constructor(props: any){
      super(props);
      this.state = {

      }
    }
  
    componentDidMount(){
      
    }
  
  
    render() {
      return (
        <div className='page-content'>
          <div className="card">
            <div className='card-body'>
              <div className='form-group'>
                  <label>Name</label>
                  <input className='form-control'></input> 
                  <label>Email</label>
                  <input className='form-control' type={'email'}></input>
                  <label>Contact</label>
                  <input className='form-control' type={'email'}></input>
                  <label>Comment</label>
                  <textarea className='form-control' ></textarea>
                  <label>api-key</label>
                  <input className='form-control'></input>
                  <label>Password</label>
                  <input className='form-control'></input>
                  <label>Manager</label>
                  <Select options={options} />
                  <hr></hr>
                  <button className='btn btn-primary form-control'>add partner</button>
              </div>
            </div>
          </div>
        </div>
      );
    }
}

