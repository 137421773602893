import React, { Component } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonSelect, IonSelectOption, IonItem } from '@ionic/react';
import { ApiReq, notySend } from '../components/function';
import Loading from '../pages/Loading';

export default class BrokersPage extends Component<any, any> {
    constructor(props: any){
      super(props);
      this.state = {
        table_data:[],
        req_status:false
      }
    }
  
    componentDidMount(){
      this.GetBrokers();
    }

    GetBrokers = () => {
      ApiReq('/brokers/get_brokers/', {select:['id', 'name', 'comment'], where:{}}).then(res => {
        if(res != false){
          if(res.success == true){
            this.setState({
              table_data:res.data,
              req_status:true
            })
          }
          else{
            notySend('error', 'error #'+res.error+'\n'+res.info)
          }
        }
      })
    }

    RenderTable = () => {
      if(this.state.table_data.length == 0 && this.state.req_status == false){
        return <div className='text-center'><Loading></Loading></div>;
      }
      else{
        if(this.state.table_data.length == 0){
          return <div className='text-center'>you don't have brokers</div>;
        }
        else{
          return (
            <div className='table-responsive'>
              <table className="table text-white">
                <thead>
                  <tr>
                    <th>id</th>
                    <th>name</th>
                    <th>comment</th>
                    <th>edit</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.table_data.map((item:any) => {
                    return (
                      <tr key={item.id}>
                          <td>{item.id}</td>
                          <td>{item.name}</td>
                          <td>{item.comment}</td>
                          <td><a href={'/edit_broker/'+item.id+'/'} className="btn btn-outline-primary">edit</a></td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          )
        }
      }
    }
  
  
    render() {
      return (
        <div className='page-content'>
          <div className="card">
            <div className='card-body'>
                <div style={{textAlign:'right'}}>
                    <a href='/add_broker/' className='btn btn-primary'><i style={{fontSize:16, margin:5}} className="material-icons control_point c_p">&#xe3ba;</i> add broker</a>
                </div>
                <br></br>
                {this.RenderTable()}
            </div>
          </div>
        </div>
      );
    }
}

