import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { UserAgentProvider, useUserAgent } from 'react-ua';

export function cache_select_data(type:any, select_name:any, select_data:any=null){
    // console.warn(localStorage.getItem(window.location.origin+'_pixelcrm_cache_select_data'))
    if(type == 'set' || type == 'get'){
        if(type == 'set'){
            let data:any = localStorage.getItem(window.location.origin+'_pixelcrm_cache_select_data');
            if(data == null){
                data = [{select_name:select_name, select_data:select_data}];
                localStorage.setItem(window.location.origin+'_pixelcrm_cache_select_data', JSON.stringify(data));
            }
            else{
                let check_select = false;
                data = JSON.parse(data);
                for(let i=0; i<data.length; i++){
                    if(data[i].select_name == select_name){
                        data[i].select_data = select_data;
                        check_select = true;
                    }
                }

                if(check_select == false){
                    data.push({select_name:select_name, select_data:select_data});
                }

                localStorage.setItem(window.location.origin+'_pixelcrm_cache_select_data', JSON.stringify(data));
            }
        }
        else{
            let data:any = localStorage.getItem(window.location.origin+'_pixelcrm_cache_select_data');
            let answer = false;
            if(data != null){
                data = JSON.parse(data);
                for(let i=0; i<data.length; i++){
                    if(data[i].select_name == select_name){
                        answer =  data[i].select_data;
                    }
                }
            }
            
            return answer;
        }
    }
    else{
        return false;
    }
}

export function getUrlPath() {
    let url:any = window.location.pathname;
    url = url.split('/');
    url = url.filter(function (el:any) {
        return (el != null && el != "" || el === 0);
    });

    return url;
}

export function getCurrentApiUrl() {
    return window.location.host+'/api/leads/add_lead/';
}

export function urlNavigate(url:any) {
    window.location.replace(url);
}

export function getApiDomain() {
    if(window.location.host.indexOf('localhost') == -1){
        return 'https://'+window.location.host+'/api';
    }
    else{
        return 'http://argocpm:8888/api';
    }
    
    // return 'https://demo.cryptotrafpar.tech/api';
}

export function getCheckerDomain() {
    // return 'http://checkerpixelcrm:8888/api';
    return 'https://system.pixelcrm.dev/api';
}

export function generateToken(length=20) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}


export function notySend(type:any=null, text:any) {
    let toastOption:any = {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "dark",
        newestOnTop:true,
        
    };

    switch (type) {
        case 'success':
            // toast.promise(function(){alert('asd')}, 'asd', 'asd')
            
            toast.success(text, toastOption);
            break;
        case 'error':
            toast.error(text, toastOption);
            break;
        case 'warning':
            toast.warning(text, toastOption);
            break;
        default:
            toast.error(text, toastOption);
    }
}


export function parsGetParams(url:any) {
    if(url.indexOf('?') != -1){
        let params = url.split('?');
        if(params[1].indexOf('&') != -1){
            let arr_params = params[1].split('&');
            let retern_arr_params:any = {};
            for(let i=0; i<arr_params.length; i++){
                let temp = arr_params.split('=');
                retern_arr_params[temp[0]] = temp[1];
            }
            return retern_arr_params;
        }
        else{

            let temp = params[1].split('=');
            let retern_arr_params:any = {};
            retern_arr_params[temp[0]] = temp[1];
            return retern_arr_params;
        }
        
        return params;
    }
    else{
        return false;
    }
}


export function ApiReq(url:any=null, body:any=null) {
    let check_dop_req = true;
    let apidomain = getApiDomain();
    let headers = {'Content-Type': 'application/json', 'Auth-Token': getApiToken()};
    let reqParam:any = {
        method: 'GET',
        headers: headers,
    };
    if(body != null){
        reqParam.method = 'POST';
        reqParam.body = JSON.stringify(body)
    }

    // console.warn({headers:headers, body:body, url:url})
    return fetch(apidomain+url, reqParam).then((response) => {
        // console.log(url)
        if(response.status == 200){
            return response.json().then(res => {
                if(res.info != undefined){
                    if(res.info.indexOf('auth undefined') !== -1){
                        notySend('warning', 'The server returned an authorization error, first try reloading the page, perhaps the server simply did not have time to process your request, if the situation has not changed, contact the manager!');
                        // urlNavigate('/login/');
                        // console.log('alarm razlogin '+ res)
                    }
                }
                return res; 
            });
        }
        else{
            return DopApiReq(apidomain+url, reqParam);
        }
    }).catch(error => {
        notySend('error', error+'\nurl:'+apidomain+url);
        return false;
    });;
}

export function DopApiReq(url:string, req_param:any){
    return fetch(url, req_param).then((response) => {
        if(response.status == 200){
            return response.json().then(res => {
                return res; 
            });
        }
        else{
            return false;
        }
    }).catch(error => {
        //notySend('error', error+'\nurl:'+url);
        return false;
    });;
}

export function getApiToken() {
    let user_data:any = localStorage.getItem(window.location.origin+'_pixelcrm_user_data');
    if(user_data != null && user_data != undefined){
        user_data = JSON.parse(user_data);
        return user_data.auth_token;
    }
    else{
        return false;
    }
}

export function getUserData(param:string) {
    let user_data:any = localStorage.getItem(window.location.origin+'_pixelcrm_user_data');
    if(user_data != null && user_data != undefined){
        user_data = JSON.parse(user_data)
        return user_data[param];
    }
    else{
        return false;
    }
}

export function searchItem(items:any, searchData:any) {
   let new_items:any = [];
   for(let i=0; i<items.length; i++){
        Object.keys(items[i]).forEach(key => {
            Object.keys(searchData).forEach(key_s => {
                if(key == key_s){
                    if(searchData[key_s] != ''){
                        if(items[i][key].indexOf(searchData[key_s]) != -1){
                            new_items.push(items[i]);
                        }
                    }
                    else{
                        // new_items.push(items[i]);
                    }
                }
                // console.log(key_s, searchData[key_s]);
            });
            // console.log(key, items[i][key]);
        });
   }
   return new_items;
}

export function parseUAgent (userAgent:string){
    if(userAgent != ''){
        // return useUserAgent(userAgent);
    }
    else{
        // return useUserAgent();
    }
}

export function sync_config (){
    ApiReq('/system_config/get_all_config/').then(res => {
        if(res != false){
            if(res.success == true){
                // console.log(res)
                let sync_config:any = {};
                for(let i=0; i<res.data.length; i++){
                    sync_config[res.data[i].name] = res.data[i].val;
                }
                localStorage.setItem('pixel_crm_config', JSON.stringify(sync_config));
            }
            else{
                notySend('error', 'error #'+res.error+'\n'+res.info)
            }
        }
    })
}

export function getClientConfig (param:string=''){
    if(param == 'logo' || param == 'version'){
        if(param == 'logo'){
            // return require('../components/img/testlogo.png');
            // return window.location.origin+'/client/build/uploads/logo.png';
            let domain = getApiDomain()
            return domain.substring(0, domain.length-4)+'/client/build/uploads/logo.png';
        }
        if(param == 'version'){
            return '0.5.0'
        }
        
    }
    else{
        
        let clietnConfig:any = {
            name:'PIXEL CRM',
            version:'0.0.0',
            logo:require('../components/img/testlogo.png'),
            resending_leads:false
        };
        if(param != ''){
            
            if(localStorage.getItem('pixel_crm_config') != null){
                let temp:any = localStorage.getItem('pixel_crm_config');
                let hash_config:any = JSON.parse(temp);
                return hash_config[param]
            }
            else{
                return clietnConfig[param]
            }
        }
        else{
            return clietnConfig;
        }
    }
    
}

export function isJson(str:string) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export function clear_selected_array_from_selectpoint(array:any){
    let new_array = [];
    for(let i=0; i<array.length; i++){
        if(array[i].label != 'select' && array[i].value != null){
            new_array.push(array[i])
        }
    }
    return new_array;
}
