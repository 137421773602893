import React, { Component } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonSelect, IonSelectOption, IonItem } from '@ionic/react';
import { ApiReq, notySend, getApiToken, getApiDomain, getUserData, urlNavigate, getClientConfig, sync_config } from '../components/function';
import PartnersSelect from '../components/PartnersSelect';
import BrokersSelect from '../components/BrokersSelect';
import GeoListSelect from '../components/GeoListSelect';
import LangListSelect from '../components/LangListSelect';
import Select from 'react-select';
import Loading from '../pages/Loading';

export default class CampaignsPage extends Component<any, any> {
    constructor(props: any){
      super(props);
      this.state = {
        table_data_load:true,
        table_data:[],
        btn_dop_load:false,
        c_partner:{value:null, label:'select'},
        c_broker:{value:null, label:'select'},
        c_country:{value:null, label:'select'},
        c_lang:{value:null, label:'select'},
        c_status:{value:null, label:'select'},
        limit:[0, 20]
      }
    }
  
    componentDidMount(){
      this.GetList()
    }

    GetList = () => {
      this.setState({
        table_data_load:true,
        btn_dop_load:false
      })
      ApiReq('/campaigns/get_list/', {partner:this.state.c_partner.value, broker:this.state.c_broker.value, status:this.state.c_status.value, country:this.state.c_country.value, lang:this.state.c_lang.value, limit:this.state.limit}).then(res => {
        if(res != false){
          // console.log(res)
          if(res.success == true){
            this.setState({
              table_data:res.data,
              table_data_load:false,
              btn_dop_load:true
            })
          }
          else{
            notySend('error', 'error #'+res.error+'\n'+res.info)
          }
        }
      })
    }

    LoadMore = () => {
      this.setState({
        table_data_load:true,
        btn_dop_load:false,
        limit:[this.state.limit[0]+20, this.state.limit[1]]
      })
      setTimeout(()=>{
        ApiReq('/campaigns/get_list/', {partner:this.state.c_partner.value, broker:this.state.c_broker.value, status:this.state.c_status.value, country:this.state.c_country.value, lang:this.state.c_lang.value, limit:this.state.limit}).then(res => {
          if(res != false){
            if(res.success == true){
              // console.log(res.data)
              if(res.data.length == 0){
                this.setState({
                  table_data_load:false,
                  btn_dop_load:false
                })
              }
              else{
                let table_data = this.state.table_data;
                for(let i=0; i<res.data.length; i++){
                  table_data.push(res.data[i]);
                }
                this.setState({
                  table_data:table_data,
                  table_data_load:false,
                  btn_dop_load:true
                })
              }
              
            }
            else{
              notySend('error', 'error #'+res.error+'\n'+res.info)
            }
          }
        })
      },1000)
    }

    SetStatusCampaign = (campaign_id:any, status:any) => {
      ApiReq('/campaigns/set_status_campaign/', {campaign_id:campaign_id}).then(res => {
        if(res != false){
          if(res.success == true){
            notySend('success', 'success!');
            let table_data = this.state.table_data;
            for(let i=0; i<table_data.length; i++){
              if(table_data[i].id == campaign_id){
                if(status == 'ON'){
                  table_data[i].status = 'OFF';
                }
                else{
                  table_data[i].status = 'ON';
                }
                this.setState({
                  table_data:table_data,
                })
              }
            }
          }
          else{
            notySend('error', 'error #'+res.error+'\n'+res.info)
          }
        }
      })
    }


    RenderLoading = () => {
      if(this.state.table_data_load == true){
        return(
          <div className='text-center'><Loading></Loading></div>
        )
      }
      else{
        return null;
      }
    }

    RenderBtnDopLoad = () => {
      if(this.state.btn_dop_load == true){
        return(
          <div className='text-center'><button onClick={()=>{this.LoadMore()}} style={{borderRadius:20}} className='btn btn-outline-primary'>load more</button></div>
        )
      }
      else{
        return null;
      }
    }

    SetResendingLeads = () => {
      let val = getClientConfig('resending_leads');
      if(val == true){
        val = false
      }
      else{
        val = true
      }
      ApiReq('/system_config/set_config/', {name:'resending_leads', val:val}).then(res => {
        if(res != false){
            if(res.success == true){
                notySend('success', 'success');
                sync_config();
            }
            else{
                notySend('error', 'error #'+res.error+'\n'+res.info)
            }
        }
      })
    }

    SetVisibleInfoGeoAndLang = (obj:any) =>{
      if(obj.classList.value.indexOf('lgo_no_full') != -1){
        obj.classList.remove('lgo_no_full');
      }
      else{
        obj.classList.add('lgo_no_full');
      }
    }
  
  
    render() {
      return (
        <div className='page-content'>
          <div className="card">
            <div className='card-body'>
                <div className='row'>
                  <div className='col'>
                    {/* <div className="form-check form-switch">
                        <input onClick={()=>{this.SetResendingLeads()}} className="form-check-input" type="checkbox" defaultChecked={getClientConfig('resending_leads')}></input>
                        <label className="form-check-label" >Resending leads</label>
                    </div> */}
                  </div>
                  <div className='col'>
                    <div style={{textAlign:'right'}}>
                        <a href='/add_campaign/' className='btn btn-primary'><i style={{fontSize:16, margin:5}} className="material-icons control_point c_p">&#xe3ba;</i> add campaign</a>
                    </div>
                  </div>
                </div>
                <br></br>
                <div className='row'>
                  <div className='col'>
                    <label>Partner</label>
                    <PartnersSelect value={this.state.c_partner} onChange={(t:any)=>{this.setState({c_partner:t})}}></PartnersSelect>
                  </div>
                  <div className='col'>
                    <label>Broker</label>
                    <BrokersSelect value={this.state.c_broker} onChange={(t:any)=>{this.setState({c_broker:t})}}></BrokersSelect>
                  </div>
                  <div className='col'>
                    <label>Status</label>
                    <Select value={this.state.c_status} onChange={(t)=>{this.setState({c_status:t})}}  options={[{label:'ALL', value:null}, {label:'ON', value:'ON'},{label:'OFF', value:'OFF'}]} />
                  </div>
                  <div className='col'>
                    <label>Country</label>
                    <GeoListSelect isMulti={false} value={this.state.c_country} onChange={(t:any)=>{this.setState({c_country:t})}}></GeoListSelect>
                  </div>
                  <div className='col'>
                    <label>Lang</label>
                    <LangListSelect isMulti={false} value={this.state.c_lang} onChange={(t:any)=>{this.setState({c_lang:t})}}></LangListSelect>
                  </div>
                  <div className='col'>
                    <label>Filter</label>
                    <button onClick={()=>{this.setState({limit:[0, 20]}); setTimeout(()=>{this.GetList()}, 500)}} className='btn btn-outline-primary form-control'>Filter</button>
                  </div>
                </div>
                <div className='table-responsive'>
                    <table className="table text-white">
                      <thead>
                        <tr>
                          <th>id</th>
                          <th>name</th>
                          <th>partner</th>
                          <th>broker</th>
                          <th>country</th>
                          <th>lang</th>
                          <th>cap</th>
                          <th>limiter</th>
                          <th>status</th>
                          <th>edit</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.table_data.map((item:any) => {
                          let status_badge:any = null;
                          if(item.status == 'ON'){
                            status_badge = <span onClick={()=>{this.SetStatusCampaign(item.id, item.status)}} className="badge bg-success rounded-pill ms-auto c_p">{item.status}</span>;
                          }
                          if(item.status == 'OFF'){
                            status_badge = <span onClick={()=>{this.SetStatusCampaign(item.id, item.status)}} className="badge bg-danger rounded-pill ms-auto c_p">{item.status}</span>;
                          }
                          return (
                            <tr key={item.id}>
                              <td>{item.id}</td>
                              <td>{item.name}</td>
                              <td><a style={{textDecoration:'none'}} target={'_blank'} href={'/edit_user/'+item.partner+'/'}>{item.partner_email}</a></td>
                              <td><a style={{textDecoration:'none'}} target={'_blank'} href={'/edit_broker/'+item.broker+'/'}>{item.broker_name}</a></td>
                              
                              <td> 
                                <div onClick={(t)=>{this.SetVisibleInfoGeoAndLang(t.currentTarget)}} className='lgo_no_full badge bg-primary rounded-pill ms-auto c_p' style={{padding:8}}>
                                  {item.country.map((item_c:any) => {
                                    return <span key={item_c.value}>{item_c.value} | </span>
                                  })}
                                </div>
                              </td>
                              <td>
                                <div onClick={(t)=>{this.SetVisibleInfoGeoAndLang(t.currentTarget)}} className='lgo_no_full badge bg-primary rounded-pill ms-auto c_p' style={{padding:8}}>
                                  {item.lang.map((item_c:any) => {
                                    return <span key={item_c.value}>{item_c.value} | </span>
                                  })}
                                </div>
                              </td>
                              {/* <td><span>{item.lang} </span></td> */}
                              <td>{item.count_leads+'/'+item.cap}</td>
                              <td><span className='text-warning'>{item.lead_sender_limited.toString()}</span></td>
                              <td>{status_badge}</td>
                              <td><a href={'/edit_campaign/'+item.id+"/"} className="btn btn-outline-primary">edit</a></td>
                            </tr>
                          )
                        })}
                        
                      </tbody>
                    </table>
                    {this.RenderLoading()}
                    {this.RenderBtnDopLoad()}
                    <br></br>
                  </div>
            </div>
          </div>
        </div>
      );
    }
}

