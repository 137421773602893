import React, { Component } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonSelect, IonSelectOption, IonItem } from '@ionic/react';
import { ApiReq, notySend, searchItem } from '../components/function';
import Loading from '../pages/Loading';

export default class PartnersPage extends Component<any, any> {
    constructor(props: any){
      super(props);
      this.state = {
        table_data:[],
        search_filter:{name:'', email:'', comment:'', partner_token:''},
        save_table_data:[],
        load_page:false
      }
    }
  
    componentDidMount(){
      searchItem('', '');
      this.GetUsers();
    }

    GetUsers = () => {
      ApiReq('/users/get_users/', {select:['id', 'name', 'email', 'role', 'comment', 'partner_token', 'manager'], where:[{role:'partner'}]}).then(res => {
        if(res != false){
          if(res.success == true){
            // console.log(res)
            this.setState({
              table_data:res.data,
              save_table_data:res.data,
              load_page:true
            })
          }
          else{
            notySend('error', 'error #'+res.error+'\n'+res.info)
          }
        }
      })
    }


    UpdateFilter = (name:any, data:any) => {
      let search_filter = this.state.search_filter;
      search_filter[name] = data;
      this.setState({
        search_filter:search_filter
      })

      setTimeout(()=>{
        this.SearchFilter()
      }, 500)
    }


    SearchFilter = () => {
      let search:any = false;
      let search_filter = this.state.search_filter;
      Object.keys(search_filter).forEach(key => {
        if(search_filter[key] != ''){
          search = true;
        }
      });
      if(search == true){
        this.setState({
          table_data:searchItem(this.state.save_table_data, this.state.search_filter)
        })
      }
      else{
        this.setState({
          table_data:this.state.save_table_data
        })
      }
    }


  
    RenderTable = () => {
      if(this.state.load_page == false){
        return <div className='text-center'><Loading></Loading></div>;
      }
      else{
        if(this.state.table_data.length == 0){
          return(
            <div className='text-center'>partners not found!</div>
          )
        }
        else{
          return (
            <div className='table-responsive'>
              <table className="table text-white">
                <thead>
                  <tr>
                    <th>id</th>
                    <th>name</th>
                    <th>email</th>
                    <th>comment</th>
                    <th>partner_token</th>
                    <th>manager</th>
                    <th>edit</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.table_data.map((item:any) => {
                    return (
                      <tr key={item.id}>
                        <td>{item.id}</td>
                        <td>{item.name}</td>
                        <td>{item.email}</td>
                        <td>{item.comment}</td>
                        <td>{item.partner_token}</td>
                        <td>{item.manager.label}</td>
                        <td><a href={'/edit_user/'+item.id+'/'} className="btn btn-outline-primary">edit</a></td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
  
          )
        }
        
      }
    }
  
  
    render() {
      return (
        <div className='page-content'>
          <div className="card">
            <div className='card-body'>
                <div style={{textAlign:'right'}}>
                    <a href='/add_user/?role=partner' className='btn btn-primary'><i style={{fontSize:16, margin:5}} className="material-icons control_point c_p">&#xe3ba;</i> add partner</a>
                </div>
                
                <br></br>
                <div className='row'>
                  <div className='col'>
                    <label>Name</label>
                    <input onInput={(r)=>{this.UpdateFilter('name', r.currentTarget.value)}} className='form-control'></input>
                  </div>
                  <div className='col'>
                    <label>Email</label>
                    <input onInput={(r)=>{this.UpdateFilter('email', r.currentTarget.value)}} className='form-control'></input>
                  </div>
                  <div className='col'>
                    <label>Comment</label>
                    <input onInput={(r)=>{this.UpdateFilter('comment', r.currentTarget.value)}} className='form-control'></input>
                  </div>
                  <div className='col'>
                    <label>partner_token</label>
                    <input onInput={(r)=>{this.UpdateFilter('partner_token', r.currentTarget.value)}} className='form-control'></input>
                  </div>
                </div>
                
                <br></br>
                {this.RenderTable()}
            </div>
          </div>
        </div>
      );
    }
}

