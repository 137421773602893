import React, { Component } from 'react';
import { ApiReq, notySend, urlNavigate } from '../components/function';
import Select from 'react-select'

export default class WeekDaysSelect extends Component<any, any> {
    constructor(props: any){
      super(props);
      this.state = {
        select_data:[
            { value: 'Monday', label: 'Monday' },
            { value: 'Tuesday', label: 'Tuesday' },
            { value: 'Wednesday', label: 'Wednesday' },
            { value: 'Thursday', label: 'Thursday' },
            { value: 'Friday', label: 'Friday' },
            { value: 'Saturday', label: 'Saturday' },
            { value: 'Sunday', label: 'Sunday' },
        ],
      }
    }
  
    componentDidMount(){
       
    }

    Change = (res:any) => {
        if(this.props.onChange != null){
            this.props.onChange(res);
        }
        // let select_data = [{value:null, label:null}];
        // for(let i=0; i<this.state.select_data.length; i++){
        //     if(res.value == this.state.select_data[i].value){
        //         select_data[0].value = res.value;
        //         select_data[0].label = res.label;
        //     }
        //     else{
        //         select_data.push(this.state.select_data[i]);
        //     }
        // }
        // this.setState({
        //     select_data:select_data
        // })
    }
  
  
    render() {
        if(this.props.value != null && this.props.value != undefined){
            return(
                <Select value={this.props.value} isMulti={true} onChange={(res)=>{this.Change(res)}}   options={this.state.select_data} />
            )
        }
        else{
            return (
                <Select isMulti={true} onChange={(res)=>{this.Change(res)}}   options={this.state.select_data} />
            )
        }
    }
}

