import React, { Component } from 'react';
import { ApiReq, notySend, urlNavigate } from './function';
import Select from 'react-select';
import CustomModal from './CustomModal';

export default class StartSettingModal extends Component<any, any> {
    constructor(props: any){
      super(props);
      this.state = {
        host:'localhost',
        user:'',
        pass:'',
        name:''
       
      }
    }
  
    componentDidMount(){
        this.GetDbConfig();
    }

    SetDbConfig = () => {
        ApiReq('/system_config/set_db_config', {host:this.state.host, user:this.state.user, pass:this.state.pass, name:this.state.name}).then(res => {
            if(res != false){
              if(res.success == true){
                this.setState({
                    answer:'success'
                })
                setTimeout(()=>{
                    window.location.reload();
                }, 1000)
              }
              else{
                this.setState({
                    answer:res.error
                })
              }
            }
        })
    }

    GetDbConfig = () => {
        ApiReq('/system_config/get_db_config').then(res => {
            if(res != false){
                this.setState({
                    host:res.host,
                    user:res.user,
                    pass:res.pass,
                    name:res.name
                })
            }
        })
    }
  
  
    render() {
        return (
            <CustomModal visible={true} header={'Connect database'} callback={()=>{window.location.reload()}}>
                <div className='form-group'>
                    <label>Host</label>
                    <input value={this.state.host} onInput={(t)=>{this.setState({host:t.currentTarget.value})}} className='form-control' ></input>
                    <label>User</label>
                    <input value={this.state.user} onInput={(t)=>{this.setState({user:t.currentTarget.value})}} className='form-control' ></input>
                    <label>Pass</label>
                    <input value={this.state.pass} onInput={(t)=>{this.setState({pass:t.currentTarget.value})}} className='form-control' ></input>
                    <label>Name</label>
                    <input value={this.state.name} onInput={(t)=>{this.setState({name:t.currentTarget.value})}} className='form-control' ></input>
                    <p className='text-danger text-center'>{this.state.answer}</p>
                    <div className='text-center'>
                        <button onClick={()=>{this.SetDbConfig()}} className="btn btn-outline-primary" style={{borderRadius:20}}>START</button>
                    </div>
                </div>
                <hr></hr>
                <div className='text-center'>
                    if you need help, contact the manager <p></p> Telegram <a href='https://t.me/trafpar' target={'_blank'} style={{textDecoration:'none'}}>@trafpar</a>
                </div>
            </CustomModal>
            // <div className="modal fade show" id="myModal" aria-modal="true" role="dialog" style={{display:'block', backgroundColor:'rgba(0, 0, 0, 0.7)'}}>
            //     {/* <div className="modal-dialog modal-lg modal-dialog-centered"> */}
            //     <div className="modal-dialog modal-md modal-dialog-centered">
            //     <div className="modal-content" >
            //         <div className="modal-body" style={{backgroundColor:'#33404a'}}>
            //         <div>
            //             <h3 className='text-center'>Connect database</h3>
            //             <hr></hr>
            //             {/* <div className='text-center'>connect database</div> */}
            //             <div className='form-group'>
            //                 <label>Host</label>
            //                 <input value={this.state.host} onInput={(t)=>{this.setState({host:t.currentTarget.value})}} className='form-control' ></input>
            //                 <label>User</label>
            //                 <input value={this.state.user} onInput={(t)=>{this.setState({user:t.currentTarget.value})}} className='form-control' ></input>
            //                 <label>Pass</label>
            //                 <input value={this.state.pass} onInput={(t)=>{this.setState({pass:t.currentTarget.value})}} className='form-control' ></input>
            //                 <label>Name</label>
            //                 <input value={this.state.name} onInput={(t)=>{this.setState({name:t.currentTarget.value})}} className='form-control' ></input>
            //                 <p className='text-danger text-center'>{this.state.answer}</p>
            //                 <div className='text-center'>
            //                     <button onClick={()=>{this.SetDbConfig()}} className="btn btn-outline-primary" style={{borderRadius:20}}>START</button>
            //                 </div>
            //             </div>
            //             <hr></hr>
            //             <div className='text-center'>
            //             if you need help, contact the manager <p></p> Telegram <a href='https://t.me/trafpar' target={'_blank'} style={{textDecoration:'none'}}>@trafpar</a>
            //             </div>
            //         </div>
            //         </div>
            //     </div>
            //     </div>
            // </div>
        )
    }
}

