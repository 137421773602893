import React, { Component } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonSelect, IonSelectOption, IonItem } from '@ionic/react';
import { ApiReq, notySend, urlNavigate, generateToken, parsGetParams } from '../components/function';
import RolesSelect from '../components/RolesSelect';
import ManagersSelect from '../components/ManagersSelect';
import UpdateCacheModal from '../components/UpdateCacheModal';


export default class AddUserPage extends Component<any, any> {
    constructor(props: any){
      super(props);
      this.state = {
        send_data:{name:'', email:'', comment:'', password:generateToken(), role:{label:'select', value:null}, manager:{label:'select', value:null}, leads_display:'full', 	brokers_display:'all', partners_display:'all', access_to_create_broker:0},
        maneger_select:null,
        updateCacheModal:false,
      }
    }
  
    componentDidMount(){
      
    }

    UpdateState = (name:any, data:any) => {
      let send_data = this.state.send_data;
      send_data[name] = data;
      this.setState({
        send_data:send_data
      })
      
      setTimeout(()=>{
        this.RenderManagersSelect();
      }, 500)
    }

    AddUsers = () => {
      if(this.state.send_data.name != '' && this.state.send_data.email != '' && this.state.send_data.password != ''){
        if(this.state.send_data.role.label == 'partner'){
          // if(this.state.send_data.manager.value == null){
          //   this.AddUserReq();
          // }
          // else{
          //   notySend('error', 'select manager for this partner!');
          // }
          this.AddUserReq();
        }
        else{
          this.AddUserReq();
        }
      }
      else{
        notySend('error', 'name and email and password must by not empty!');
      }
    }

    AddUserReq = () => {
      let send_data = this.state.send_data;
      if(send_data.role == ''){
        send_data.role = this.state.send_data.role.label;
      }
      if(send_data.role.label != undefined){
        send_data.role = this.state.send_data.role.label;
      }
      
      
      ApiReq('/users/add_user/', this.state.send_data).then(res => {
        if(res != false){
          if(res.success == true){
            this.setState({
              updateCacheModal:true
            })
            setTimeout(()=>{
              let fastinter = setInterval(()=>{
                if(this.state.updateCacheModal == false){
                  clearInterval(fastinter);
                  urlNavigate('/edit_user/'+res.user_id+'/');
                }
              }, 10);
            }, 100)
            //for reset cache select 
            //localStorage.removeItem(window.location.origin+'_pixelcrm_cache_select_data')
          }
          else{
            notySend('error', 'error #'+res.error+'\n'+res.info);
          }
        }
      })
    }


    RenderRole = () => {
      let getParam = parsGetParams(window.location.href);
      if(getParam.role != undefined && getParam.role != null){
        
        setTimeout(()=>{
          this.UpdateState('role', getParam.role)
          setTimeout(()=>{
            this.RenderManagersSelect();
          }, 500)
        }, 1000)
        return (
          <input disabled={true} className='form-control' value={getParam.role}></input>
        )
      }
      else{
        return (
          <RolesSelect isMulti={false} value={this.state.send_data.role} onChange={(res:any)=>{this.UpdateState('role', res);}}  />
        )
      }
    }

    RenderManagersSelect = () => {
        if(this.state.send_data.role.label === 'partner' || this.state.send_data.role === 'partner'){
          this.setState({
            manager_select:<><label>Manager</label><ManagersSelect value={this.state.send_data.manager} onChange={(res:any)=>{this.UpdateState('manager', res);}}></ManagersSelect></>
          })
        }
        else{
          this.setState({
            manager_select:null
          })
        }
    }
  
    RenderManagerParams = () => {
      if(this.state.send_data.role.label === 'manager'){
        return(
          <>
            <label>partners display</label>
            <select className='form-control' value={this.state.send_data.partners_display} onChange={(t)=>{this.UpdateState('partners_display', t.currentTarget.value)}}><option value={'all'}>all</option><option value={'basic'}>only manager partners</option></select>
            <label>leads display</label>
            <select className='form-control' value={this.state.send_data.leads_display} onChange={(t)=>{this.UpdateState('leads_display', t.currentTarget.value)}}><option value={'full'}>full data</option><option value={'basic'}>basic data</option></select>
          </>
        )
      }
      else{
        return null
      }
    }

    RenderBrandManagerParams = () => {
      if(this.state.send_data.role.label === 'brand manager'){
        return(
          <>
            <label>brokers display</label>
            <select className='form-control' value={this.state.send_data.brokers_display} onChange={(t)=>{this.UpdateState('brokers_display', t.currentTarget.value)}}><option value={'all'}>all</option><option value={'basic'}>only manager brokers</option></select>
            <label>leads display</label>
            <select className='form-control' value={this.state.send_data.leads_display} onChange={(t)=>{this.UpdateState('leads_display', t.currentTarget.value)}}><option value={'full'}>full data</option><option value={'basic'}>basic data</option></select>
            <label>access to create brokers</label>
            <select className='form-control' value={this.state.send_data.access_to_create_broker} onChange={(t)=>{this.UpdateState('access_to_create_broker', t.currentTarget.value)}}><option value={0}>false</option><option value={1}>true</option></select>
            
          </>
        )
      }
      else{
        return null
      }
    }
  
    render() {
      return (
        <div className='page-content'>
          <div className="card">
            <div className='card-body'>
              <div className='form-group'>
                  <label>Name*</label>
                  <input onInput={(t)=>{this.UpdateState('name', t.currentTarget.value);}} className='form-control'></input> 
                  <label>Role*</label>
                  {this.RenderRole()}
                  {this.state.manager_select}
                  {this.RenderManagerParams()}
                  {this.RenderBrandManagerParams()}
                  <label>Email*</label>
                  <input onInput={(t)=>{this.UpdateState('email', t.currentTarget.value)}} className='form-control' type={'email'}></input>
                  <label>Comment</label>
                  <textarea onInput={(t)=>{this.UpdateState('comment', t.currentTarget.value)}} className='form-control' ></textarea>
                  <label>Password*</label>
                  <input onInput={(t)=>{this.UpdateState('password', t.currentTarget.value)}} defaultValue={this.state.send_data.password} className='form-control'></input>
                  
                  <hr></hr>
                  <button onClick={()=>{this.AddUsers()}} className='btn btn-primary form-control'>add user</button>
              </div>
            </div>
          </div>
          <UpdateCacheModal callback={()=>{this.setState({updateCacheModal:false})}} visible={this.state.updateCacheModal}></UpdateCacheModal>
        </div>
      );
    }
}

