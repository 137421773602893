import React, { Component } from 'react';
import { ApiReq, notySend, urlNavigate, cache_select_data, clear_selected_array_from_selectpoint } from '../components/function';
import Select from 'react-select'

export default class BrokersSelect extends Component<any, any> {
    constructor(props: any){
      super(props);
      this.state = {
        select_data:[{ value: null, label: 'Loading, wait...' }],
        def_val:null
      }
    }
  
    componentDidMount(){
        let select_data:any = cache_select_data('get', 'brokers_select');
        if(select_data != false){
            if(this.props.isMulti == true){
                select_data = clear_selected_array_from_selectpoint(select_data);
            }
            else{
                select_data.unshift({label:'select', value:null})
            }
            this.setState({
                select_data:select_data,
                def_val:select_data[0]
            })
        }
        else{
            ApiReq('/brokers/get_brokers/', {select:['id', 'name'], where:{}}).then(res => {
                if(res != false){
                  if(res.success == true){
                    select_data = [{label:'select', value:null}];
                    if(this.props.current != null){
                        for(let i=0; i<res.data.length; i++){
                            if(res.data[i].name == this.props.current){
                                select_data.push({ value: res.data[i].id, label: res.data[i].name })
                            }
                        }
                        for(let i=0; i<res.data.length; i++){
                            if(res.data[i].name != this.props.current){
                                select_data.push({ value: res.data[i].id, label: res.data[i].name })
                            }
                        }
                    }
                    else{
                        for(let i=0; i<res.data.length; i++){
                            select_data.push({ value: res.data[i].id, label: res.data[i].name })
                        }
                    }
                    
                    if(this.props.isMulti == true){
                        select_data = clear_selected_array_from_selectpoint(select_data);
                    }
                    
                    this.setState({
                        select_data:select_data,
                        def_val:select_data[0]
                    })
                    cache_select_data('set', 'brokers_select', select_data);

                  }
                  else{
                    notySend('error', 'error #'+res.error+'\n'+res.info);
                  }
                }
            })
        }
        
    }

    Change = (res:any) => {
        if(this.props.onChange != null){
            this.props.onChange(res);
        }
        let select_data = [{value:null, label:null}];
        for(let i=0; i<this.state.select_data.length; i++){
            if(res.value == this.state.select_data[i].value){
                select_data[0].value = res.value;
                select_data[0].label = res.label;
            }
            else{
                select_data.push(this.state.select_data[i]);
            }
        }
        this.setState({
            select_data:select_data
        })
    }
  
  
    render() {
        if(this.props.isMulti != undefined){
            if(this.props.isMulti == true){
                return (
                    <Select isMulti={true} onChange={(res)=>{this.Change(res)}}  options={this.state.select_data} />
                )
            }
            else{
                return (
                    <Select onChange={(res)=>{this.Change(res)}} value={this.state.select_data[0]}   options={this.state.select_data} />
                )
            }
        }
        else{
            if(this.props.value != null){
                return (
                    <Select onChange={(res)=>{this.Change(res)}} value={this.props.value}  options={this.state.select_data} />
                )
            }
            else{
                return(
                    <Select onChange={(res)=>{this.Change(res)}} value={this.state.select_data[0]}  options={this.state.select_data} />
                )
            }
        }
    }
}

