import React, { Component } from 'react';
import { ApiReq, notySend, urlNavigate, getCheckerDomain } from './function';


export default class CustomModal extends Component<any, any> {
    constructor(props: any){
      super(props);
      this.state = {
        
      }
    }
  
    componentDidMount(){
        
    }

    
    ReturnSizeModal = () => {
        switch (this.props.size) {
            case 'small':
                return 'modal-md';
            case 'large':
                return 'modal-lg';
            case 'big':
                return 'modal-xl';
            default:
                return 'modal-md';
        }
    }
  
  
    render() {
        if(this.props.visible == true){
            return (
                <div className="modal fade show" aria-modal="true" role="dialog" style={{display:'block', backgroundColor:'rgba(0, 0, 0, 0.7)'}}>
                    <div className={'modal-dialog '+this.ReturnSizeModal()+' modal-dialog-centered'}>
                    <div className="modal-content" style={{borderRadius:20}}>
                        <div className="modal-body" style={{backgroundColor:(localStorage.getItem(window.location.origin+'_pixelcrm_theme') == null)?'#33404a':(localStorage.getItem(window.location.origin+'_pixelcrm_theme') == 'dark')?'#33404a':'white', borderRadius:15}}>
                            <div>
                                <div className='row'>
                                    <div className='col' style={{fontSize:20, fontWeight:'bold'}}>{this.props.header}</div>
                                    <div className='col' style={{textAlign:'right'}}><i onClick={()=>{this.props.callback();}} className="material-icons highlight_off text-danger c_p">&#xe888;</i></div>
                                </div>
                                <hr></hr>
                                {this.props.children}
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            )
        }
        else{
            return null;
        }
    }
}

