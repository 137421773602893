import React, { Component } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonSelect, IonSelectOption, IonItem } from '@ionic/react';
import { getClientConfig, getUserData } from '../components/function';

export default class Navigation extends Component<any, any> {
    constructor(props: any){
      super(props);
      this.state = {
          page:null,
          menu:[
            {
                name:'Main',
                href:'/main/',
                icon:<i className="material-icons home ">&#xe88a;</i>,
                roles:['admin', 'manager', 'integrator', 'partner', 'brand manager']
            },
            {
                name:'Campaigns',
                href:'/campaigns/',
                icon:<i className="material-icons device_hub">&#xe335;</i>,
                roles:['admin', 'manager']
            },
            {
                name:'Leads',
                href:'/leads/',
                icon:<i className="material-icons insights">&#xf092;</i>,
                roles:['admin', 'manager', 'partner', 'brand manager']
            },
            {
                name:'Partners',
                href:'/partners/',
                icon:<i className="material-icons import_export">&#xe0c3;</i>,
                roles:['admin', 'manager']
            },
            {
                name:'Brokers',
                href:'/brokers/',
                icon:<i className="material-icons newspaper">&#xeb81;</i>,
                roles:['admin', 'integrator', 'brand manager']
            },
            {
                name:'Sender lead',
                href:'/sender_lead/',
                icon:<i className="material-icons upload">&#xf09b;</i>,
                roles:['admin', 'integrator', 'brand manager']
            },
            {
                name:'Sendbox code',
                href:'/sendbox_code/',
                icon:<i className="material-icons code">&#xe86f;</i>,
                roles:['admin', 'integrator']
            },
            {
                name:'Service connect',
                href:'/service_connect/',
                icon:<i className="material-icons hub">&#xe9f4;</i>,
                roles:['admin']
            },
            {
                name:'Users',
                href:'/users/',
                icon:<i className="material-icons group">&#xe7ef;</i>,
                roles:['admin']
            },
            {
                name:'Logs',
                href:'/logs/',
                icon:<i className="material-icons code">&#xe86f;</i>,
                roles:['admin']
            },
            {
                name:'Integration',
                href:'/integration/',
                icon:<i className="material-icons info_outline">&#xe88f;</i>,
                roles:['admin', 'manager', 'partner', 'integrator', 'brand manager']
            },
            {
                name:'Settings',
                href:'/settings/',
                icon:<i className="material-icons construction">&#xea3c;</i>,
                roles:['admin']
            },
          ]
      }
    }
  
    componentDidMount(){
        if(document.getElementById('menu') != null){
            let menu:any = document.getElementById('menu');
            let menu_a = menu.getElementsByTagName('a');
            for(let i=0; i<menu_a.length; i++){
                if(window.location.href == menu_a[i].href){
                    menu.getElementsByTagName('a')[i].classList.add('manu_a_active');
                    break;
                }
            }
        }
        
    }
  
    

  
    render() {
      return (
        <aside className="sidebar-wrapper" data-simplebar="true" style={{overflow:'scroll'}}>
            {/* <div className="sidebar-header" style={{textAlign:'center'}}>
                <div >
                    <img src={getClientConfig('logo')} className="logo-icon" alt="logo icon"></img>
                </div>
            </div> */}
            <div className="sidebar-header" style={{textAlign:'center'}}>
                <div >
                    <img src={getClientConfig('logo')} style={{width:'50%'}} alt="logo icon"></img>
                </div>
            </div>
            {/* metismenu
            .sidebar-wrapper .sidebar-header
            border-right: 1px solid #e2e3e4; */}
            {/* <div style={{height:84, backgroundColor:'#283138', width:'100%', zIndex:999999}}>h</div> */}
            <ul className="metismenu" id="menu" >
                {this.state.menu.map((item:any) => {
                    if(item.roles.indexOf(getUserData('role')) != -1){
                        let warn_icon = null;
                        if(item.warn_icon != undefined){
                            warn_icon = <span className='text-warning'><i style={{fontSize:14}} className="material-icons report_problem">&#xe8b2;</i></span>;
                        }
                        return(
                            <li key={item.name}>
                                <a className='text-white ' href={item.href}>
                                    <div className="parent-icon">{item.icon}</div>
                                    {warn_icon}
                                    <div className="menu-title">{item.name}</div>
                                </a>
                            </li>
                        )
                    }
                })}
                {/* <li>
                    <a className='text-white ' href="/main/">
                        <div className="parent-icon"><i className="material-icons home ">&#xe88a;</i></div>
                        <div className="menu-title">Main</div>
                    </a>
                </li>
                <li>
                    <a className='text-white' href="/campaigns/">
                        <div className="parent-icon"><i className="material-icons device_hub">&#xe335;</i></div>
                        <div className="menu-title">Campaigns</div>
                    </a>
                </li>
                <li>
                    <a className='text-white' href="/leads/">
                        <div className="parent-icon"><i className="material-icons insights">&#xf092;</i></div>
                        <div className="menu-title">Leads</div>
                    </a>
                </li>
                <li>
                    <a className='text-white' href="/partners/">
                        <div className="parent-icon"><i className="material-icons import_export">&#xe0c3;</i></div>
                        <div className="menu-title">Partners</div>
                    </a>
                </li>
                <li>
                    <a className='text-white' href="/brokers/">
                        <div className="parent-icon"><i className="material-icons newspaper">&#xeb81;</i></div>
                        <div className="menu-title">Brokers</div>
                    </a>
                </li>
                <li>
                    <a className='text-white' href="/sender_lead/">
                        <div className="parent-icon"><i className="material-icons upload">&#xf09b;</i></div>
                        <div className="menu-title">Sender lead</div>
                    </a>
                </li>
                <li>
                    <a className='text-white' href="/sendbox_code/">
                        <div className="parent-icon"><i className="material-icons code">&#xe86f;</i></div>
                        <div className="menu-title">Sendbox code</div>
                    </a>
                </li>
                <li>
                    <a className='text-white' href="/service_connect/">
                        <div className="parent-icon"><i className="material-icons hub">&#xe9f4;</i></div>
                        <div className="menu-title">Service connect</div>
                    </a>
                </li>
                <li>
                    <a className='text-white' href="/users/">
                        <div className="parent-icon"><i className="material-icons group">&#xe7ef;</i></div>
                        <div className="menu-title">Users</div>
                    </a>
                </li>
                <li>
                    <a className='text-white' href="/settings/">
                        <div className="parent-icon"><i className="material-icons construction">&#xea3c;</i></div>
                        <div className="menu-title">Settings </div>
                    </a>
                </li> */}
                {/* <li>
                    <a href="javascript:;" className="has-arrow text-white">
                        <div className="parent-icon"><i className="fadeIn animated bx bx-cube"></i></div>
                        <div className="menu-title">Приложения</div>
                    </a>
                    <ul>
                        <li> <a className='text-white' href="/apps/all/"><i className="fadeIn animated bx bx-radio-circle"></i>Все</a></li>
                        <li> <a className='text-white' href="/apps/group/"><i className="fadeIn animated bx bx-radio-circle"></i>Группы</a></li>
                    </ul>
                </li> */}
                {/* <li>
                    <a href="javascript:;" className="has-arrow">
                        <div className="parent-icon"><i className="fadeIn animated bx bx-notification"></i></div>
                        <div className="menu-title">Пуши</div>
                    </a>
                    <ul>
                        <li> <a href="/pushs/all/"><i className="fadeIn animated bx bx-radio-circle"></i>Все</a></li>
                        <li> <a href="/pushs/group/"><i className="fadeIn animated bx bx-radio-circle"></i>Группы</a></li>
                    </ul>
                </li>
                <li>
                    <a href="/push_tasks/">
                    <div className="parent-icon"><i className="fadeIn animated bx bx-task"></i></div>
                    <div className="menu-title">Пуш - задачи</div>
                    </a>
                </li>
                <li>
                <a href="/fast_sender/">
                    <div className="parent-icon"><i className="fadeIn animated bx bx-notification"></i></div>
                    <div className="menu-title">Быстрая рассылка</div>
                </a>
                </li> */}
            </ul>
            
        </aside>
      );
    }
}



