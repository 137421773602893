import React, { Component } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonSelect, IonSelectOption, IonItem } from '@ionic/react';
import { ApiReq, getUrlPath, notySend, getUserData, urlNavigate } from '../components/function';
import RolesSelect from '../components/RolesSelect';
import Loading from '../pages/Loading';
import CustomModal from '../components/CustomModal';
import ManagersSelect from '../components/ManagersSelect';
import UpdateCacheModal from '../components/UpdateCacheModal';

let url = getUrlPath();

export default class EditUserPage extends Component<any, any> {
    constructor(props: any){
      super(props);
      this.state = {
        page_data:{name:'', email:'', comment:'', role:'', manager:{label:'select', value:null, leads_display:'full', brokers_display:'all', partners_display:'all', access_to_create_broker:'0'}},
        load_data:false,
        update_pass:false,
        customModal:false,
        updateCacheModal:false
      }
    }
  
    componentDidMount(){
      
      if(Number.isInteger(Number(url[1]))){
        ApiReq('/users/get_users/', {select:['name', 'email', 'role', 'comment', 'partner_token', 'manager', 'leads_display', 'brokers_display', 'partners_display', 'access_to_create_broker'], where:[{id:url[1]}]}).then(res => {
          if(res != false){
            if(res.success == true){
              this.setState({
                page_data:res.data[0],
                load_data:true
              })
              // console.log(res.data[0])
            }
            else{
              notySend('error', 'error #'+res.error+'\n'+res.info)
            }
          }
        })
      }
      else{
        notySend('error', 'incorrect url, dotn have user id')
      }
    }

    UpdateUser = () => {
      let send_data:any = {name:this.state.page_data.name, comment:this.state.page_data.comment, role:this.state.page_data.role, manager:this.state.page_data.manager, user_id:url[1], leads_display:this.state.page_data.leads_display, brokers_display:this.state.page_data.brokers_display, partners_display:this.state.page_data.partners_display, access_to_create_broker:this.state.page_data.access_to_create_broker};
      if(this.state.page_data.new_pass != undefined){
        send_data.new_pass = this.state.page_data.new_pass;
      }
      console.log(send_data)
      ApiReq('/users/update_user/', send_data).then(res => {
        if(res != false){
          // console.log(res)
          if(res.success == true){
            notySend('success', 'success');
            //for reset cache select 
            //localStorage.removeItem(window.location.origin+'_pixelcrm_cache_select_data')
            this.setState({
              updateCacheModal:true
            })
          }
          else{
            notySend('error', 'error #'+res.error+'\n'+res.info)
          }
        }
      })
    }

    RenderUpdatePass = () => {
      if(getUserData('role') == 'admin'){
        return (
          <div>
            <br></br>
            <span onClick={()=>{this.setState({update_pass:!this.state.update_pass})}} className='text-primary c_p'>update password for this user</span>
          </div>
        )
      }
      else{
        return null;
      }
    }

    RenderDeleteBtn = () => {
      if(getUserData('role') == 'admin'){
        return (
          <div>
            <hr></hr>
            <button onClick={()=>{this.setState({customModal:true})}} className='btn btn-outline-danger form-control'>delete</button>
          </div>
        )
      }
      else{
        return null;
      }
    }

    DeleteUser = () => {
      let url = getUrlPath();
      ApiReq('/users/delete_user/', {id:url[1]}).then(res => {
        if(res != false){
          if(res.success == true){
            notySend('success', 'success');
            //for reset cache select 
            //localStorage.removeItem(window.location.origin+'_pixelcrm_cache_select_data')
            this.HideModal();
            this.setState({
              updateCacheModal:true
            })
            setTimeout(()=>{
              let fastinter = setInterval(()=>{
                if(this.state.updateCacheModal == false){
                  clearInterval(fastinter);
                  urlNavigate('/users/');
                }
              }, 10);
            }, 800);
          }
          else{
            notySend('error', 'error #'+res.error+'\n'+res.info)
          }
        }
      })
    }

    RenderUpdatePassInput = () => {
      if(this.state.update_pass == true){
        return (
          <div>
            <label>Write new password</label>
            <input onInput={(t)=>{let page_data=this.state.page_data; page_data.new_pass = t.currentTarget.value; this.setState({page_data:page_data})}} className='form-control'></input>
          </div>
        )
      }
      else{
        return null;
      }
    }

    RenderPage = () => {
      if(this.state.load_data == false){
        return (
          <div className='text-center'><Loading></Loading></div>
        )
      }
      else{
        let parnter_input = null;
        let manager_input = null;
        if(this.state.page_data.partner_token != ''){
          parnter_input = <><label>Partner token (no rewrite)</label><input disabled={true} defaultValue={this.state.page_data.partner_token} className='form-control'></input></>
          manager_input = <><label>Manager (no rewrite)</label><input disabled={true} defaultValue={this.state.page_data.manager} className='form-control'></input></>
        }
        return (
          <div className='form-group'>
              <label>Name*</label>
              <input value={this.state.page_data.name} onInput={(t)=>{let page_data=this.state.page_data; page_data.name=t.currentTarget.value; this.setState({page_data:page_data})}} className='form-control'></input> 
              <label>Email (no rewrite)</label>
              <input disabled={true} defaultValue={this.state.page_data.email} className='form-control' type={'email'}></input>
              <label>Comment</label>
              <textarea value={this.state.page_data.comment} onInput={(t)=>{let page_data=this.state.page_data; page_data.comment=t.currentTarget.value; this.setState({page_data:page_data})}} className='form-control' ></textarea>
              {parnter_input}
              {/* {manager_input} */}
              {this.RenderManagerParams()}
              {this.RenderBrandManagerParams()}
              {this.RenderManagersSelect()}
              <label>Role (no rewrite)</label>
              <input className='form-control' disabled={true} defaultValue={this.state.page_data.role}></input>
              {/* <RolesSelect current={this.state.page_data.role}></RolesSelect> */}
              {this.RenderUpdatePass()}
              {this.RenderUpdatePassInput()}
              <hr></hr>
              <button onClick={()=>{this.UpdateUser()}} className='btn btn-primary form-control'>save</button>
              {this.RenderDeleteBtn()}
              {/* <hr></hr> */}
              
              {/* <button className='btn btn-outline-danger form-control'>delete</button> */}
          </div>
        )
      }
    }
  
    HideModal = () => {
      this.setState({
        customModal:false
      })
    }

    RenderManagersSelect = () => {
      if(this.state.page_data.role === 'partner'){
        return (
          <div><label>Manager</label><ManagersSelect value={this.state.page_data.manager} onChange={(res:any)=>{let p_d=this.state.page_data; p_d.manager=res; this.setState({page_data:p_d})}}></ManagersSelect></div>
        )
      }
      else{
        return null;
      }
    }

    RenderManagerParams = () => {
      if(this.state.page_data.role === 'manager'){
        let partners_display_optinos = null;
        let partners_display_optinos_all = <><option value={'all'}>all</option><option value={'basic'}>only manager partners</option></>;
        let partners_display_optinos_basic = <><option value={'basic'}>only manager partners</option><option value={'all'}>all</option></>;
        if(this.state.page_data.partners_display == 'all'){
          partners_display_optinos = partners_display_optinos_all;
        }
        else{
          partners_display_optinos = partners_display_optinos_basic;
        }

        let leads_display_optinos = null;
        let leads_display_optinos_full = <><option value={'full'}>full data</option><option value={'basic'}>basic data</option></>;
        let leads_display_optinos_basic = <><option value={'basic'}>basic data</option><option value={'full'}>full data</option></>;
        if(this.state.page_data.leads_display == 'full'){
          leads_display_optinos = leads_display_optinos_full;
        }
        else{
          leads_display_optinos = leads_display_optinos_basic;
        }

        return(
          <>
            <label>partners display</label>
            <select className='form-control' value={this.state.page_data.partners_display} onChange={(t)=>{let pd=this.state.page_data; pd.partners_display=t.currentTarget.value; this.setState({page_data:pd})}}>{partners_display_optinos}</select>
            <label>leads display</label>
            <select className='form-control' value={this.state.page_data.leads_display} onChange={(t)=>{let pd=this.state.page_data; pd.leads_display=t.currentTarget.value; this.setState({page_data:pd})}}>{leads_display_optinos}</select>
          </>
        )
      }
      else{
        return null
      }
    }

    RenderBrandManagerParams = () => {
      if(this.state.page_data.role === 'brand manager'){
        let brokers_display_optinos = null;
        let brokers_display_optinos_all = <><option value={'all'}>all</option><option value={'basic'}>only manager brokers</option></>;
        let brokers_display_optinos_basic = <><option value={'basic'}>only manager brokers</option><option value={'all'}>all</option></>;
        if(this.state.page_data.brokers_display == 'all'){
          brokers_display_optinos = brokers_display_optinos_all;
        }
        else{
          brokers_display_optinos = brokers_display_optinos_basic;
        }

        let leads_display_optinos = null;
        let leads_display_optinos_full = <><option value={'full'}>full data</option><option value={'basic'}>basic data</option></>;
        let leads_display_optinos_basic = <><option value={'basic'}>basic data</option><option value={'full'}>full data</option></>;
        if(this.state.page_data.leads_display == 'full'){
          leads_display_optinos = leads_display_optinos_full;
        }
        else{
          leads_display_optinos = leads_display_optinos_basic;
        }

        let access_cr_br = null;
        let access_cr_br_true = <><option value={1}>true</option><option value={0}>false</option></>;
        let access_cr_br_false = <><option value={0}>false</option><option value={1}>true</option></>;
        if(this.state.page_data.access_to_create_broker == '1'){
          access_cr_br = access_cr_br_true;
        }
        else{
          access_cr_br = access_cr_br_false;
        }

        return(
          <>
            <label>brokers display</label>
            <select className='form-control' value={this.state.page_data.brokers_display} onChange={(t)=>{let pd=this.state.page_data; pd.brokers_display=t.currentTarget.value; this.setState({page_data:pd})}}>{brokers_display_optinos}</select>
            <label>leads display</label>
            <select className='form-control' value={this.state.page_data.leads_display} onChange={(t)=>{let pd=this.state.page_data; pd.leads_display=t.currentTarget.value; this.setState({page_data:pd})}}>{leads_display_optinos}</select>
            <label>access to create brokers</label>
            <select className='form-control' value={this.state.page_data.access_to_create_broker} onChange={(t)=>{let pd=this.state.page_data; pd.access_to_create_broker=t.currentTarget.value; this.setState({page_data:pd})}}>{access_cr_br}</select>
            
          </>
        )
      }
      else{
        return null
      }
    }
  
    render() {
      return (
        <div className='page-content'>
          <div className="card">
            <div className='card-body'>
              {this.RenderPage()}
            </div>
          </div>
          <UpdateCacheModal callback={()=>{this.setState({updateCacheModal:false});}} visible={this.state.updateCacheModal}></UpdateCacheModal>
          <CustomModal visible={this.state.customModal} header={'Attention!'} callback={this.HideModal}>
            <div className='text-center'>
              <h4>Confirm the deletion request!</h4>
            </div>
            <br></br>
            <button onClick={()=>{this.DeleteUser()}} className='btn btn-outline-danger form-control'>delete</button>
          </CustomModal>
        </div>
      );
    }
}

