import React, { Component } from 'react';
import { ApiReq, notySend, urlNavigate, cache_select_data } from '../components/function';
import Select from 'react-select'

let roles_data = [{"value":"1","label":"admin"},{"value":"2","label":"manager"},{"value":"3","label":"partner"},{"value":"4","label":"integrator"}, {"value":"5","label":"brand manager"}];

export default class RolesSelect extends Component<any, any> {
    constructor(props: any){
      super(props);
      this.state = {
        select_data:[{ value: null, label: 'Loading, wait...' }],
        def_val:null
      }
    }
  
    componentDidMount(){
        let select_data = roles_data;
        this.setState({
          select_data:select_data,
          def_val:select_data[0]
        })
        // let select_data:any = cache_select_data('get', 'roles_select');
        // if(select_data != false){
        //     this.setState({
        //         select_data:select_data,
        //         def_val:select_data[0]
        //     })
        // }
        // else{
        //     ApiReq('/users/get_roles/', this.state.send_data).then(res => {
        //       if(res != false){
        //         if(res.success == true){
        //           let select_data = [];
        //           if(this.props.current != null){
        //               for(let i=0; i<res.data.length; i++){
        //                   if(res.data[i].name == this.props.current){
        //                       select_data.push({ value: res.data[i].id, label: res.data[i].name })
        //                   }
        //               }
        //               for(let i=0; i<res.data.length; i++){
        //                   if(res.data[i].name != this.props.current){
        //                       select_data.push({ value: res.data[i].id, label: res.data[i].name })
        //                   }
        //               }
        //           }
        //           else{
        //               for(let i=0; i<res.data.length; i++){
        //                   select_data.push({ value: res.data[i].id, label: res.data[i].name })
        //               }
        //           }
                  
                  
        //           this.setState({
        //               select_data:select_data,
        //               def_val:select_data[0]
        //           })

                  

        //           cache_select_data('set', 'roles_select', select_data);
        //         }
        //         else{
        //           notySend('error', 'error #'+res.error+'\n'+res.info);
        //         }
        //       }
        //     })
        // }

        
        
    }

    Change = (res:any) => {
        if(this.props.onChange != null){
            this.props.onChange(res);
        }
        let select_data = [{value:null, label:null}];
        for(let i=0; i<this.state.select_data.length; i++){
            if(res.value == this.state.select_data[i].value){
                select_data[0].value = res.value;
                select_data[0].label = res.label;
            }
            else{
                select_data.push(this.state.select_data[i]);
            }
        }
        this.setState({
            select_data:select_data
        })
    }
  
    render() {
        if(this.props.isMulti == false){
          return (
            <Select value={this.props.value}  onChange={(res)=>{this.Change(res)}}  options={this.state.select_data} />
          )
        }
        else{
          return (
            <Select value={this.props.value} isMulti={true} onChange={(res)=>{this.Change(res)}}  options={this.state.select_data} />
          )
        }
    }

    // render() {
    //     return (
    //         <Select onChange={(res)=>{this.Change(res)}} value={this.state.select_data[0]}   options={this.state.select_data} />
    //     )
    // }
}

