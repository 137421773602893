import React, { Component } from 'react';
import { ApiReq, notySend, urlNavigate } from '../components/function';
import Select from 'react-select'

export default class TimeZonesSelect extends Component<any, any> {
    constructor(props: any){
      super(props);
      this.state = {
        select_data:[
            {"value":"Etc/GMT+12","label":"(UTC-12:00) International Date Line West (Etc/GMT+12)"},
            {"value":"Etc/GMT+11","label":"(UTC-11:00) Coordinated Universal Time-11 (Etc/GMT+11)"},
            {"value":"Pacific/Honolulu","label":"(UTC-10:00) Hawaii (Pacific/Honolulu)"},
            {"value":"America/Anchorage","label":"(UTC-09:00) Alaska (America/Anchorage)"},
            {"value":"America/Santa_Isabel","label":"(UTC-08:00) Baja California (America/Santa_Isabel)"},
            {"value":"America/Los_Angeles","label":"(UTC-08:00) Pacific Time (US and Canada) (America/Los_Angeles)"},
            {"value":"America/Chihuahua","label":"(UTC-07:00) Chihuahua, La Paz, Mazatlan (America/Chihuahua)"},
            {"value":"America/Phoenix","label":"(UTC-07:00) Arizona (America/Phoenix)"},
            {"value":"America/Denver","label":"(UTC-07:00) Mountain Time (US and Canada) (America/Denver)"},
            {"value":"America/Guatemala","label":"(UTC-06:00) Central America (America/Guatemala)"},
            {"value":"America/Chicago","label":"(UTC-06:00) Central Time (US and Canada) (America/Chicago)"},
            {"value":"America/Regina","label":"(UTC-06:00) Saskatchewan (America/Regina)"},
            {"value":"America/Mexico_City","label":"(UTC-06:00) Guadalajara, Mexico City, Monterey (America/Mexico_City)"},
            {"value":"America/Bogota","label":"(UTC-05:00) Bogota, Lima, Quito (America/Bogota)"},
            {"value":"America/Indiana/Indianapolis","label":"(UTC-05:00) Indiana (East) (America/Indiana/Indianapolis)"},
            {"value":"America/New_York","label":"(UTC-05:00) Eastern Time (US and Canada) (America/New_York)"},
            {"value":"America/Caracas","label":"(UTC-04:30) Caracas (America/Caracas)"},
            {"value":"America/Halifax","label":"(UTC-04:00) Atlantic Time (Canada) (America/Halifax)"},
            {"value":"America/Asuncion","label":"(UTC-04:00) Asuncion (America/Asuncion)"},
            {"value":"America/La_Paz","label":"(UTC-04:00) Georgetown, La Paz, Manaus, San Juan (America/La_Paz)"},
            {"value":"America/Cuiaba","label":"(UTC-04:00) Cuiaba (America/Cuiaba)"},
            {"value":"America/Santiago","label":"(UTC-04:00) Santiago (America/Santiago)"},
            {"value":"America/St_Johns","label":"(UTC-03:30) Newfoundland, (America/St_Johns)"},
            {"value":"America/Sao_Paulo","label":"(UTC-03:00) Brasilia (America/Sao_Paulo)"},
            {"value":"America/Godthab","label":"(UTC-03:00) Greenland (America/Godthab)"},
            {"value":"America/Cayenne","label":"(UTC-03:00) Cayenne, Fortaleza (America/Cayenne)"},
            {"value":"America/Argentina/Buenos_Aires","label":"(UTC-03:00) Buenos Aires (America/Argentina/Buenos_Aires)"},
            {"value":"America/Montevideo","label":"(UTC-03:00) Montevideo (America/Montevideo)"},
            {"value":"Etc/GMT+2","label":"(UTC-02:00) Coordinated Universal Time-2 (Etc/GMT+2)"},
            {"value":"Atlantic/Cape_Verde","label":"(UTC-01:00) Cape Verde (Atlantic/Cape_Verde)"},
            {"value":"Atlantic/Azores","label":"(UTC-01:00) Azores (Atlantic/Azores)"},
            {"value":"Africa/Casablanca","label":"(UTC+00:00) Casablanca (Africa/Casablanca)"},
            {"value":"Atlantic/Reykjavik","label":"(UTC+00:00) Monrovia, Reykjavik (Atlantic/Reykjavik)"},
            {"value":"Europe/London","label":"(UTC+00:00) Dublin, Edinburgh, Lisbon, London (Europe/London)"},
            {"value":"Etc/GMT","label":"(UTC+00:00) Coordinated Universal Time (Etc/GMT)"},
            {"value":"Europe/Berlin","label":"(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna (Europe/Berlin)"},
            {"value":"Europe/Paris","label":"(UTC+01:00) Brussels, Copenhagen, Madrid, Paris (Europe/Paris)"},
            {"value":"Africa/Lagos","label":"(UTC+01:00) West Central Africa (Africa/Lagos)"},
            {"value":"Europe/Budapest","label":"(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague (Europe/Budapest)"},
            {"value":"Europe/Warsaw","label":"(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb (Europe/Warsaw)"},
            {"value":"Africa/Windhoek","label":"(UTC+01:00) Windhoek (Africa/Windhoek)"},
            {"value":"Europe/Istanbul","label":"(UTC+02:00) Athens, Bucharest, Istanbul (Europe/Istanbul)"},
            {"value":"Europe/Kiev","label":"(UTC+03:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius (Europe/Kiev)"},
            {"value":"Africa/Cairo","label":"(UTC+02:00) Cairo (Africa/Cairo)"},
            {"value":"Asia/Damascus","label":"(UTC+02:00) Damascus (Asia/Damascus)"},
            {"value":"Asia/Amman","label":"(UTC+02:00) Amman (Asia/Amman)"},
            {"value":"Africa/Johannesburg","label":"(UTC+02:00) Harare, Pretoria (Africa/Johannesburg)"},
            {"value":"Asia/Jerusalem","label":"(UTC+02:00) Jerusalem (Asia/Jerusalem)"},
            {"value":"Asia/Beirut","label":"(UTC+02:00) Beirut (Asia/Beirut)"},
            {"value":"Asia/Baghdad","label":"(UTC+03:00) Baghdad (Asia/Baghdad)"},
            {"value":"Europe/Minsk","label":"(UTC+03:00) Minsk (Europe/Minsk)"},
            {"value":"Asia/Riyadh","label":"(UTC+03:00) Kuwait, Riyadh (Asia/Riyadh)"},
            {"value":"Africa/Nairobi","label":"(UTC+03:00) Nairobi (Africa/Nairobi)"},
            {"value":"Asia/Tehran","label":"(UTC+03:30) Tehran (Asia/Tehran)"},
            {"value":"Europe/Moscow","label":"(UTC+03:00) Moscow, St. Petersburg, Volgograd (Europe/Moscow)"},
            {"value":"Asia/Tbilisi","label":"(UTC+04:00) Tbilisi (Asia/Tbilisi)"},
            {"value":"Asia/Yerevan","label":"(UTC+04:00) Yerevan (Asia/Yerevan)"},
            {"value":"Asia/Dubai","label":"(UTC+04:00) Abu Dhabi, Muscat (Asia/Dubai)"},
            {"value":"Asia/Baku","label":"(UTC+04:00) Baku (Asia/Baku)"},
            {"value":"Indian/Mauritius","label":"(UTC+04:00) Port Louis (Indian/Mauritius)"},
            {"value":"Asia/Kabul","label":"(UTC+04:30) Kabul (Asia/Kabul)"},
            {"value":"Asia/Tashkent","label":"(UTC+05:00) Tashkent (Asia/Tashkent)"},
            {"value":"Asia/Karachi","label":"(UTC+05:00) Islamabad, Karachi (Asia/Karachi)"},
            {"value":"Asia/Colombo","label":"(UTC+05:30) Sri Jayewardenepura Kotte (Asia/Colombo)"},
            {"value":"Asia/Kolkata","label":"(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi (Asia/Kolkata)"},
            {"value":"Asia/Kathmandu","label":"(UTC+05:45) Kathmandu (Asia/Kathmandu)"},
            {"value":"Asia/Almaty","label":"(UTC+06:00) Astana (Asia/Almaty)"},
            {"value":"Asia/Dhaka","label":"(UTC+06:00) Dhaka (Asia/Dhaka)"},
            {"value":"Asia/Yekaterinburg","label":"(UTC+06:00) Yekaterinburg (Asia/Yekaterinburg)"},
            {"value":"Asia/Yangon","label":"(UTC+06:30) Yangon (Asia/Yangon)"},
            {"value":"Asia/Bangkok","label":"(UTC+07:00) Bangkok, Hanoi, Jakarta (Asia/Bangkok)"},
            {"value":"Asia/Novosibirsk","label":"(UTC+07:00) Novosibirsk (Asia/Novosibirsk)"},
            {"value":"Asia/Krasnoyarsk","label":"(UTC+08:00) Krasnoyarsk (Asia/Krasnoyarsk)"},
            {"value":"Asia/Ulaanbaatar","label":"(UTC+08:00) Ulaanbaatar (Asia/Ulaanbaatar)"},
            {"value":"Asia/Shanghai","label":"(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi (Asia/Shanghai)"},
            {"value":"Australia/Perth","label":"(UTC+08:00) Perth (Australia/Perth)"},
            {"value":"Asia/Singapore","label":"(UTC+08:00) Kuala Lumpur, Singapore (Asia/Singapore)"},
            {"value":"Asia/Taipei","label":"(UTC+08:00) Taipei (Asia/Taipei)"},
            {"value":"Asia/Irkutsk","label":"(UTC+09:00) Irkutsk (Asia/Irkutsk)"},
            {"value":"Asia/Seoul","label":"(UTC+09:00) Seoul (Asia/Seoul)"},
            {"value":"Asia/Tokyo","label":"(UTC+09:00) Osaka, Sapporo, Tokyo (Asia/Tokyo)"},
            {"value":"Australia/Darwin","label":"(UTC+09:30) Darwin (Australia/Darwin)"},
            {"value":"Australia/Adelaide","label":"(UTC+09:30) Adelaide (Australia/Adelaide)"},
            {"value":"Australia/Hobart","label":"(UTC+10:00) Hobart (Australia/Hobart)"},
            {"value":"Asia/Yakutsk","label":"(UTC+10:00) Yakutsk (Asia/Yakutsk)"},
            {"value":"Australia/Brisbane","label":"(UTC+10:00) Brisbane (Australia/Brisbane)"},
            {"value":"Pacific/Port_Moresby","label":"(UTC+10:00) Guam, Port Moresby (Pacific/Port_Moresby)"},
            {"value":"Australia/Sydney","label":"(UTC+10:00) Canberra, Melbourne, Sydney (Australia/Sydney)"},
            {"value":"Asia/Vladivostok","label":"(UTC+11:00) Vladivostok (Asia/Vladivostok)"},
            {"value":"Pacific/Guadalcanal","label":"(UTC+11:00) Solomon Islands, New Caledonia (Pacific/Guadalcanal)"},
            {"value":"Etc/GMT-12","label":"(UTC+12:00) Coordinated Universal Time+12 (Etc/GMT-12)"},
            {"value":"Pacific/Fiji","label":"(UTC+12:00) Fiji, Marshall Islands (Pacific/Fiji)"},
            {"value":"Asia/Magadan","label":"(UTC+12:00) Magadan (Asia/Magadan)"},
            {"value":"Pacific/Auckland","label":"(UTC+12:00) Auckland, Wellington (Pacific/Auckland)"},
            {"value":"Pacific/Tongatapu","label":"(UTC+13:00) Nuku`alofa (Pacific/Tongatapu)"},
            {"value":"Pacific/Apia","label":"(UTC+13:00) Samoa (Pacific/Apia)"}
        ],
      }
    }
  
    componentDidMount(){
       
    }

    Change = (res:any) => {
        if(this.props.onChange != null){
            this.props.onChange(res);
        }
        // let select_data = [{value:null, label:null}];
        // for(let i=0; i<this.state.select_data.length; i++){
        //     if(res.value == this.state.select_data[i].value){
        //         select_data[0].value = res.value;
        //         select_data[0].label = res.label;
        //     }
        //     else{
        //         select_data.push(this.state.select_data[i]);
        //     }
        // }
        // this.setState({
        //     select_data:select_data
        // })
    }
  
  
    render() {
        if(this.props.value != null && this.props.value != undefined){
            return (
                <Select defaultValue={this.props.value} onChange={(res)=>{this.Change(res)}}   options={this.state.select_data} />
            )
        }
        else{
            return (
                <Select onChange={(res)=>{this.Change(res)}}   options={this.state.select_data} />
            )
        }
    }
}

