import React, { Component } from 'react';
import { getClientConfig } from '../components/function';

export default class Page404 extends Component<any, any> {
    constructor(props: any){
      super(props);
      this.state = {
        
      }
    }
  
    componentDidMount(){
      
    }

  
    render() {
      return (
        <main className="authentication-content">
          <div className="container-fluid">
            <div className="authentication-card">
              <div className="card">
                <div className="row">
                  <div className="col-lg-6 bg-login d-flex align-items-center justify-content-center ">
                    {/* <img src="/admin/template/assets/images/error/login-img.jpg" className="img-fluid" alt=""> */}
                    <span><h1 style={{color:'white'}}>{getClientConfig('name')}</h1></span>
                  </div>
                  <div className="col-lg-6 ">
                    <div className="card-body p-4 p-sm-5 ">
                      <h3 style={{fontSize:30}} className="card-title text-center text-white">404 Page not found</h3>
                      <div className="text-center text-danger"></div>
                      <div  className="form-body">
                        <div className="login-separater text-center mb-4 text-white"> <span style={{backgroundColor:'transparent'}}></span>
                          <hr></hr>
                        </div>
                          <div className="row g-3">
                            <div className="col-12">
                              <div className="d-grid">
                                <a href='/main/' style={{borderRadius:50}} className="btn btn-primary radius-30">Main page</a>
                              </div>
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      );
    }
}


