import React, { Component } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonSelect, IonSelectOption, IonItem } from '@ionic/react';
import { ApiReq, notySend, getApiToken, getApiDomain, generateToken, getCurrentApiUrl, isJson } from '../components/function';
import Loading from '../pages/Loading';
import Select from 'react-select';
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-php_laravel_blade";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-monokai";
import { exit } from 'process';


const options = [
    { value: 'GET', label: 'GET' },
    { value: 'POST', label: 'POST' },
    { value: 'PUT', label: 'PUT' },
    { value: 'PATCH', label: 'PATCH' },
    { value: 'DELETE', label: 'DELETE' },
];

let temp:any = null;
let temp2:any = null;

export default class SenderLeadPage extends Component<any, any> {
    constructor(props: any){
      super(props);
      this.state = {
        answer:'',
        method:options[1].value,
        url:getApiDomain()+'/leads/add_lead/',
        q_body:{email:generateToken(10)+'@gmail.com', first_name:generateToken(5), last_name:generateToken(5), country:'EN', lang:'en', user_ip:'209.142.68.29', phone:'+14845219623', funnel:'funnel', password:generateToken(5)},
        q_headers:[{id:0, name:'Content-type', value:'application/json'}, {id:1, name:'Partner-Token', value:''}],
        showBodyType:'list',
        page_load:true
      }
    }
  
    componentDidMount(){
      
    }

  

    SendQuery = () => {
        if(this.state.method != '' && this.state.url != ''){
            this.setState({
                page_load:false
            })
            let headers:any = {};
            for(let i=0; i<this.state.q_headers.length; i++){
                headers[this.state.q_headers[i].name] = this.state.q_headers[i].value;
            }
            const requestOptions = {
                method: 'POST',
                headers: {'Auth-Token': getApiToken()},
                body:JSON.stringify({headers:headers, method:this.state.method, url:this.state.url, body:this.state.q_body})
            };
            
            fetch(getApiDomain()+'/sender_lead/send_lead/', requestOptions)
            .then(response => response.text())
            .then(data => {
                // console.log(data)
                let answer = data;
                if(isJson(answer) == true){
                  answer = JSON.parse(answer)
                  answer = JSON.stringify(answer, undefined, 4)
                }
                this.setState({
                    answer:answer,
                    page_load:true
                })
            });
        }
        else{
            notySend('error', 'params method and url must not be empty!')
        }
        
    }    

    FindIndex = (item:any) => {
        let i = 0;
        let res = 0;
        Object.keys(this.state.q_body).forEach(key => {
            if(key == item){
                res = i;
            }
            i++;
        })
        return res;
    }

    SetQHeaders = (event:string, id:number=0, name:string='', value:any='') => {
        let q_headers = this.state.q_headers;
        if(event == 'delete'){
            q_headers.splice(id, 1);
        }
        if(event == 'add'){
            q_headers.push({id:q_headers.length, name:'', value:''});
        }
        if(event == 'update'){
            if(name!=''){
                q_headers[id].name = name;
            }
            if(name==''){
                q_headers[id].value = value;
            }
        }

        
        this.setState({
            q_headers:q_headers
        })
    }

    SetQBody = (event:string, name:string='', value:any='', r:any=false) => {
        let q_body = this.state.q_body;
        if(event == 'delete'){
            let new_q_body:any = {};
            Object.keys(q_body).forEach(key => {
                if(key != name){
                    new_q_body[key] = q_body[key];
                }
            })
            q_body = new_q_body;
        }
        if(event == 'add'){
            for(let i=0; i<100; i++){
                if(q_body['param'+i] == undefined){
                    q_body['param'+i] = '';
                    break;
                }
            }
        }
        if(event == 'update'){
            let new_q_body:any = {};
            if(r!=false){
                let q_body = this.state.q_body;
                
                let i = 0;
                Object.keys(q_body).forEach(key => {
                    if(i == r){
                        // temp = q_body[key];
                        q_body[name] = q_body[key];
                        delete q_body[key];
                    }
                    else{
                        new_q_body[key] = q_body[key];
                    }
                    i++;
                })
                if(name != ''){
                    q_body = new_q_body;
                }
                
            }
            else{
                if(value!=''){
                    q_body[name] = value;
                }
            }
        }
        
        this.setState({
            q_body:q_body
        })
        
    }


    ShowQBodyList = () => {
        let list:any = [];
        Object.keys(this.state.q_body).forEach(key => {
            list.push(
                <div key={key} className='row' style={{marginBottom:10}}>
                    <div className='col-5'>
                        <input defaultValue={key} autoFocus={true} onInput={(t)=>{this.SetQBody('update', t.currentTarget.value, this.state.q_body[key], this.FindIndex(key))}} className='form-control'></input>
                    </div>
                    <div className='col-5'>
                        <input defaultValue={this.state.q_body[key]}  onInput={(t)=>{this.SetQBody('update', key, t.currentTarget.value)}} className='form-control'></input>
                    </div>
                    <div className='col-2'>
                        <button  onClick={()=>{this.SetQBody('delete', key)}} className='btn btn-danger'><i style={{fontSize:18, margin:0}} className="material-icons clear">&#xe14c;</i></button>
                    </div>
                </div>
            )
        })
        return list;
    }

    ShowQBodyCode = () => {
        return(
            <AceEditor
                style={{width:'100%', height:200, borderRadius:5}}
                mode="json"
                theme="monokai"
                defaultValue={JSON.stringify(this.state.q_body, null, " ")}
                highlightActiveLine={false}
                onChange={(val)=>{setTimeout(()=>{this.setState({q_body:JSON.parse(val)})}, 500)}}
                editorProps={{ $blockScrolling: true }}
                setOptions={{
                    printMarginColumn:-1,
                    enableBasicAutocompletion: false,
                    enableLiveAutocompletion: true,
                    enableSnippets: true,
                    showLineNumbers: true,
                    tabSize: 2
                }}
            />
        )
    }


    ShowQBody = () => {
        if(this.state.showBodyType == 'list'){
            return this.ShowQBodyList();
        }
        if(this.state.showBodyType == 'code'){
            return this.ShowQBodyCode();
        }
    }
  
    render() {
      return (
        <div className='page-content'>
          <div className="card">
            <div className='card-body'>
                <div className='form-group'>
                    <label>Method</label>
                    <Select defaultValue={options[1]} onChange={(r)=>{this.setState({method:r?.value})}} options={options}></Select>
                    <label>Url</label>
                    <input onInput={(t)=>{this.setState({url:t.currentTarget.value})}} defaultValue={this.state.url} className='form-control'></input>
                </div>
                <div>
                    <h6>
                        Headers
                        <i style={{fontSize:16, marginLeft:2}} onClick={()=>{this.SetQHeaders('add')}} className="material-icons text-primary control_point c_p">&#xe3ba;</i> 
                    </h6>
                    {this.state.q_headers.map((item:any) => {
                        return(
                            <div key={item.id} className='row' style={{marginBottom:10}}>
                                <div className='col-5'>
                                    <input defaultValue={item.name} onInput={(t)=>{this.SetQHeaders('update', item.id, t.currentTarget.value, '')}} className='form-control'></input>
                                </div>
                                <div className='col-5'>
                                    <input defaultValue={item.value} onInput={(t)=>{this.SetQHeaders('update', item.id, '', t.currentTarget.value)}} className='form-control'></input>
                                </div>
                                <div className='col-2'>
                                    <button  onClick={()=>{this.SetQHeaders('delete', item.id)}} className='btn btn-danger'><i style={{fontSize:16, margin:0}} className="material-icons clear">&#xe14c;</i></button>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div>
                    <h6>
                        Body
                        <i style={{fontSize:16, marginLeft:2}} onClick={()=>{this.SetQBody('add')}} className="material-icons text-primary control_point c_p">&#xe3ba;</i> 
                        <span className='text-info c_p' onClick={()=>{if(this.state.showBodyType == 'list'){this.setState({showBodyType:'code'})}else{this.setState({showBodyType:'list'})}}} style={{float:'right'}}><i style={{fontSize:14}} className="material-icons code_off">&#xe4f3;</i>code</span>
                    </h6>
                    {this.ShowQBody()}
                </div>
                
                <br></br>
                <div>
                    <span onClick={()=>{this.SendQuery()}} className='btn btn-primary form-control'>{(this.state.page_load == false) ? <div className="spinner-border text-warning spinner-border-sm"></div>:null} Run</span>
                </div>
                <hr></hr>
                <textarea defaultValue={this.state.answer} className='form-control' style={{minHeight:200}}></textarea>
            </div>
          </div>
        </div>
      );
    }
}

