import React, { Component } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonSelect, IonSelectOption, IonItem } from '@ionic/react';
import { ApiReq, notySend, getApiToken, getApiDomain, isJson } from '../components/function';
import Loading from '../pages/Loading';

import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-php_laravel_blade";
import "ace-builds/src-noconflict/theme-monokai";

export default class SendboxCodePage extends Component<any, any> {
    constructor(props: any){
      super(props);
      this.state = {
        code:'<?php',
        answer:'',
        run_btn:false,
        run_btn_loader:<div className="spinner-border text-warning spinner-border-sm"></div>
      }
    }
  
    componentDidMount(){
      
    }

    SendCode = () => {
        this.setState({
          run_btn:true
        })
        const requestOptions = {
            method: 'POST',
            headers: {   'Auth-Token': getApiToken()},
            body:JSON.stringify({code:this.state.code})
        };
        fetch(getApiDomain()+'/sendbox/send_code/', requestOptions)
        .then(response => response.text())
        .then(data => {
            let answer = data;
            if(isJson(answer) == true){
              answer = JSON.parse(answer)
              answer = JSON.stringify(answer, undefined, 4)
            }
            this.setState({
                answer:answer,
                run_btn:false
            })
        });
    }    
  
  
    render() {
      return (
        <div className='page-content'>
          <div className="card">
            <div className='card-body'>
                <AceEditor
                    style={{width:'100%', height:'50vh', borderRadius:5}}
                    mode="php"
                    theme="monokai"
                    value={this.state.code}
                    highlightActiveLine={false}
                    onChange={(val)=>{
                        this.setState({
                          code:val
                        })
                      }}
                    // name="UNIQUE_ID_OF_DIV"
                    editorProps={{ $blockScrolling: true }}
                    setOptions={{
                        // fontFamily:'menlo',
                        printMarginColumn:-1,
                        enableBasicAutocompletion: false,
                        enableLiveAutocompletion: true,
                        enableSnippets: true,
                        showLineNumbers: true,
                        tabSize: 2
                    }}
                />
                <br></br>
                <div className='row'>
                    <div className='col'>
                        <span>Answer</span>
                    </div>
                    <div className='col' style={{textAlign:'right'}}>
                        <button disabled={this.state.run_btn} onClick={()=>{this.SendCode()}} className='btn btn-primary'>{this.state.run_btn ? this.state.run_btn_loader : null} Run code</button>
                    </div>
                </div>
                <hr></hr>
                <textarea defaultValue={this.state.answer} className='form-control' style={{minHeight:200}}></textarea>
            </div>
          </div>
        </div>
      );
    }
}

