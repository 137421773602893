import React, { Component } from 'react';
import { ApiReq, notySend, urlNavigate, getCheckerDomain, cache_select_data } from './function';
import Loading from '../pages/Loading';


let cacheSuccess = false;
// let visible = false;

export default class UpdateCacheModal extends Component<any, any> {
    constructor(props: any){
      super(props);
      this.state = {
        cachingListName:'',
      }
    }
  
    componentDidMount(){
        // let inter = setInterval(()=>{
        //     if(this.props.visible == true && visible == true){
        //         visible = true;
        //         this.UpdateCache();
        //         console.log('asd')
        //         clearInterval(inter)
        //     }
        // }, 10)
    }

   

   
    
    ReturnSizeModal = () => {
        switch (this.props.size) {
            case 'small':
                return 'modal-md';
            case 'large':
                return 'modal-lg';
            case 'big':
                return 'modal-xl';
            default:
                return 'modal-md';
        }
    }

    UpdateBrokersList = () => {
        this.setState({cachingListName:'brokers'})
        let select_data:any = [];
        ApiReq('/brokers/get_brokers/', {select:['id', 'name'], where:{}}).then(res => {
            if(res != false){
              if(res.success == true){
                select_data = [{label:'select', value:null}];
                for(let i=0; i<res.data.length; i++){
                    select_data.push({ value: res.data[i].id, label: res.data[i].name })
                }
                cache_select_data('set', 'brokers_select', select_data);
                this.UpdateManagersList();
              }
              else{
                //notySend('error', 'error #'+res.error+'\n'+res.info);
              }
            }
        })
    }

    UpdateManagersList = () => {
        this.setState({cachingListName:'managers'})
        let select_data:any = [];
        ApiReq('/users/get_users/', {select:['id','name'], where:[{role:'manager'}]}).then(res => {
            if(res != false){
              if(res.success == true){
                select_data =  [{label:'select', value:null}];
                for(let i=0; i<res.data.length; i++){
                    select_data.push({ value: res.data[i].id, label: res.data[i].name })
                }
                cache_select_data('set', 'managers_select', select_data);
                this.UpdatePartnersList();
              }
              else{
                //notySend('error', 'error #'+res.error+'\n'+res.info);
              }
            }
        })
    }

    UpdatePartnersList = () => {
        this.setState({cachingListName:'partners'})
        let select_data:any = [];
        ApiReq('/users/get_users/', {select:['id','name'], where:[{role:'partner'}]}).then(res => {
            if(res != false){
              if(res.success == true){
                select_data = [{label:'select', value:null}];
                for(let i=0; i<res.data.length; i++){
                    select_data.push({ value: res.data[i].id, label: res.data[i].name })
                }
                cache_select_data('set', 'partners_select', select_data);
                cacheSuccess = true;
                setTimeout(()=>{
                    this.SaveCacheInServer();
                    this.props.callback();
                }, 100)
              }
              else{
                this.UpdatePartnersList();
                //notySend('error', 'error #'+res.error+'\n'+res.info);
              }
            }
        })
    }

    UpdateCache = () => {
        // if(cacheSuccess == false){
        //     localStorage.removeItem(window.location.origin+'_pixelcrm_cache_select_data');
        //     //this.UpdateBrokersList();
        // }
        // else{
        //     this.props.callback();
        // }
        this.SaveCacheInServer();
    }
  
    SaveCacheInServer = () => {
        // let user_cache:any = localStorage.getItem(window.location.origin+'_pixelcrm_cache_select_data');
        // if(user_cache != null){
        //     user_cache = JSON.parse(user_cache);
        // }
        // else{
        //     user_cache = [];
        // }
        ApiReq('/system_config/set_users_cache/').then(res => {
            if(res != false){
              if(res.success == true){
                //console.log(res)
                this.props.callback();
              }
              else{
                this.props.callback();
                notySend('error', res.error);
              }
            }
        })
        
    }
  
    render() {
        if(this.props.visible == true){
            let inter = setInterval(()=>{
                if(this.props.visible == true){
                    this.UpdateCache();
                    clearInterval(inter)
                }
            }, 10)
            return (
                <div className="modal fade show" aria-modal="true" role="dialog" style={{display:'block', backgroundColor:'rgba(0, 0, 0, 0.7)'}}>
                    <div className={'modal-dialog '+this.ReturnSizeModal()+' modal-dialog-centered'}>
                    <div className="modal-content" style={{borderRadius:20}}>
                        <div className="modal-body" style={{backgroundColor:'#33404a', borderRadius:15}}>
                            <div>
                                <div className='row text-center'>
                                    <div>cache update please wait</div>
                                    {/* <div className='col' style={{fontSize:20, fontWeight:'bold'}}>{this.props.header}</div>
                                    <div className='col' style={{textAlign:'right'}}><i onClick={()=>{this.props.callback();}} className="material-icons highlight_off text-danger c_p">&#xe888;</i></div> */}
                                </div>
                                <hr></hr>
                                <div className='text-center'>
                                    <Loading></Loading>
                                    <br></br>
                                    <div>caching {this.state.cachingListName}</div>
                                    <br></br>
                                    <div style={{fontSize:10}}>we use drop-down list caching for faster system performance and query optimization!</div>
                                    <br></br>
                                    <div style={{fontSize:10}} className={'text-warning'}>if you have problems with the cache, you can reset it in the settings!</div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            )
        }
        else{
            return null;
        }
    }
}

