import React, { Component } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonSelect, IonSelectOption, IonItem } from '@ionic/react';
import { notySend, getUrlPath, ApiReq, getApiDomain, getApiToken, isJson, generateToken } from '../components/function';
import CustomModal from '../components/CustomModal';

import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-php_laravel_blade";
import "ace-builds/src-noconflict/theme-monokai";
// import "ace-builds/src-noconflict/ext-language_tools";



export default class EditIntegCodePage extends Component<any, any> {
    constructor(props: any){
      super(props);
      this.state = {
        broker:{},
        code:'',
        start_code:'',
        answer:'',
        customModal:false,
        add_lead_data_var_name:'$lead_data->',
        add_lead_data_value:'',
        lead_data:[
          {first_name:'testName'},
          {last_name:'testLastname'},
          {email:generateToken(7)+'@gmail.com'},
          {country:'GE'},
          {lang:'ka'},
          {user_ip:'92.54.195.121'},
          {phone:'+380958374789'},
          {funnel:'funnel'},
          {password:'testpass'}
        ],
        run_btn:true,
        run_btn_loader:<div className="spinner-border text-warning spinner-border-sm"></div>,
      
      }
    }
  
    componentDidMount(){
      if(getUrlPath()[1] == undefined){
        notySend('error', 'error url data about broker!');
      }
      else{
        this.setState({
          broker:JSON.parse(decodeURIComponent(atob(getUrlPath()[1])))
        })
        // console.log(decodeURIComponent(atob(getUrlPath()[1])))
        setTimeout(()=>{
          this.GetCodeBroker()
        }, 500)
      }
    }

    GetCodeBroker = () => {
      if(this.state.broker.type_integ == 'add'){
        ApiReq('/brokers/get_brokers/', {select:['integ_code_add_lead'], where:[{id:this.state.broker.broker_id}]}).then(res => {
          if(res != false){
            if(res.success == true){
              this.setState({
                run_btn:false
              })
              if(res.data[0].integ_code_add_lead != null && res.data[0].integ_code_add_lead != ''){
                this.setState({
                  code:'<?php\n'+res.data[0].integ_code_add_lead,
                  start_code:'<?php\n'+res.data[0].integ_code_add_lead,
                })
              }
              else{
                this.ReadExampleCodeAddLead()
              }
            }
            else{
              notySend('error', 'error #'+res.error+'\n'+res.info)
            }
          }
        })
      }
      else{
        ApiReq('/brokers/get_brokers/', {select:['integ_code_update_lead'], where:[{id:this.state.broker.broker_id}]}).then(res => {
          if(res != false){
            if(res.success == true){
              this.setState({
                run_btn:false
              })
              if(res.data[0].integ_code_update_lead != null && res.data[0].integ_code_update_lead != ''){
                this.setState({
                  code:'<?php\n'+res.data[0].integ_code_update_lead,
                  start_code:'<?php\n'+res.data[0].integ_code_update_lead,
                })
              }
              else{
                this.ReadExampleCodeUpdateLead()
              }
            }
            else{
              notySend('error', 'error #'+res.error+'\n'+res.info)
            }
          }
        })
      }
    }

    ReadExampleCodeAddLead = () => {
      let add_lead_example_code = 
`<?php
//example code (add lead to broker)
//$lead_data - an array in which there will be data received from a partner (also when testing, you can adjust the data of this array, to do this, press the $$lead_data button on the top right)

$headers = [
    'Content-Type: application/json',
];

$j_data = array(
    'first_name' => $lead_data->first_name, // Lead Name
    'last_name' => $lead_data->last_name, // Lead Last Name
    'email' => $lead_data->email, // Lead Email
    'phone' => $lead_data->phone, // Lead phone
    'country' => strtolower($lead_data->country), // Lead country
    'lang' => strtolower($lead_data->lang), // Language for lead
    'user_ip' => $lead_data->user_ip, 
    'funnel' => $lead_data->funnel,
    'campaing_id' => 649, // check with the manager
    'pass' => $lead_data->password,
    'key' => 'sicret_key_from_broker'
);

$ch = curl_init();
curl_setopt($ch, CURLOPT_URL, 'https://api.my_broker.com/add_lead/');
curl_setopt($ch, CURLOPT_HEADER, 0);
curl_setopt($ch, CURLOPT_HTTPHEADER, $headers);
curl_setopt($ch, CURLOPT_RETURNTRANSFER,true);
curl_setopt($ch, CURLOPT_POST, true);
curl_setopt($ch, CURLOPT_POSTFIELDS, json_encode($j_data));
$result = json_decode(curl_exec($ch), true);

//the data that the code returns is not sent in the response to the partner, but goes for further processing in the system
//the resulting array is considered successful completion of the code, the parameters of which are lead_id and broker_answer (full response from the broker)
//example of returned arrays
if(isset($result['id'])){
    return array('success'=>true, 'lead_id'=>$result['id'], 'broker_answer'=>$result, 'autologin_url'=>$result['autologin_url']);
}
else{
    return array('success'=>false, 'info'=>'error adding lead', 'lead_data'=>$j_data, 'broker_answer'=>$result);
}`;
      this.setState({
        code:add_lead_example_code,
        start_code:add_lead_example_code
      })
    }

    ReadExampleCodeUpdateLead = () => {
      let update_leads_example_code = 
`<?php
//example code for update leads
//to successfully update leads, you need to return an array with information about leads to the system, where such parameters as lead_id, status, ftd, ftd_date will be indicated
//example array
// [
//     {
//         lead_id:22,
//         status:'deposit',
//         ftd:true,
//         ftd_date:'2023-05-22 12:49:55' // optional parameter, if you use it, then the format is required as the date and time are specified, if the parameter is not set, the system will automatically set the ftd_date date when ftd is true
//     },
//     {
//         lead_id:23,
//         status:'new',
//         ftd:false,
//         ftd_date:null
//     },
//     {
//         lead_id:24,
//         status:'rejected',
//         ftd:false,
//         ftd_date:null
//     },
// ]

$answer = [];

$leads = json_decode(file_get_contents('https://api.mybroker/get_leads'),true);

for($i=0; $i<count($leads);$i++){
    $answer[] = [
        'lead_id' => $leads[$i]['id'],
        'status' => $leads[$i]['broker_status'],
        'ftd' => $leads[$i]['ftd'],
        'ftd_date' => $leads[$i]['ftd_date'],
    ];
}
return $answer;`;
        this.setState({
          code:update_leads_example_code,
          start_code:update_leads_example_code
        })
    }

    RenderHeaderPage = () => {
      if(this.state.broker.type_integ == 'add'){
        return (
          <div className='row'>
            <div className='col'><span className="badge bg-primary rounded-pill ms-auto">adding a lead (integration code)</span> Broker: {this.state.broker.broker_name}</div>
            <div className='col' style={{textAlign:'right'}}><span onClick={()=>{this.setState({customModal:true})}} className="badge bg-info rounded-pill ms-auto c_p">$lead_data settings</span></div>
          </div>
        )
      }
      else{
        return (
          <div><span className="badge bg-primary rounded-pill ms-auto">update a lead (integration code)</span> Broker: {this.state.broker.broker_name} </div>
        )
      }
    }
  
    UpdateIntegCode = () => {
      this.setState({
        run_btn:true
      })
      if(this.state.broker.type_integ == 'add'){
        ApiReq('/brokers/update_broker_integ_code_add_lead/', {code:this.state.code, broker_id:this.state.broker.broker_id}).then(res => {
          if(res != false){
            if(res.success == true){
              this.setState({
                run_btn:false
              })
              notySend('success', 'success')
            }
            else{
              console.log(res)
              notySend('error', 'error #'+res.error+'\n'+res.info)
            }
          }
        })
      }
      else{
        ApiReq('/brokers/update_broker_integ_code_update_lead/', {code:this.state.code, broker_id:this.state.broker.broker_id}).then(res => {
          if(res != false){
            if(res.success == true){
              this.setState({
                run_btn:false
              })
              notySend('success', 'success')
            }
            else{
              console.log(res)
              notySend('error', 'error #'+res.error+'\n'+res.info)
            }
          }
        })
      }
    }

    RunCodeInteg = () => {
      this.setState({
        run_btn:true
      })
      if(this.state.broker.type_integ == 'add'){
        const requestOptions = {
          method: 'POST',
          headers: { 'Auth-Token': getApiToken()},
          body:JSON.stringify({broker_id:this.state.broker.broker_id, type:'add', lead_data:this.state.lead_data, code:this.state.code})
        };
        // console.log(getApiDomain()+'/components/integration_code_executor.php')
        fetch(getApiDomain()+'/brokers/run_broker_integ_code/', requestOptions)
        // fetch(getApiDomain()+'/components/integration_code_executor.php', requestOptions)
        .then(response => response.text())
        .then(data => {
            let answer = data;
            if(isJson(answer) == true){
              answer = JSON.parse(answer)
              answer = JSON.stringify(answer, undefined, 4)
            }
            this.setState({
                answer:answer,
                run_btn:false
            })
        });
      }
      else{
        const requestOptions = {
          method: 'POST',
          headers: { 'Auth-Token': getApiToken()},
          body:JSON.stringify({broker_id:this.state.broker.broker_id, type:'update', code:this.state.code})
        };
        fetch(getApiDomain()+'/brokers/run_broker_integ_code/', requestOptions)
        .then(response => response.text())
        .then(data => {
          let answer = data;
          if(isJson(answer) == true){
            answer = JSON.parse(answer)
            answer = JSON.stringify(answer, undefined, 4)
          }
          this.setState({
              answer:answer,
              run_btn:false
          })
        });
      }
    }

    // RenderSaveBtn = () => {
    //   if(this.state.code !== this.state.start_code){
    //     return (
    //       <button onClick={()=>{this.UpdateIntegCode()}} className='btn btn-primary form-control'>save</button>
    //     )
    //   }
    //   else{
    //     return (
    //       <button disabled={true} onClick={()=>{this.UpdateIntegCode()}} className='btn btn-outline-primary form-control'>save</button>
    //     )
    //   }
    // }

    HideModal = () => {
      this.setState({
        customModal:false
      })
    }

    ResetModal = () => {
      this.HideModal();

      setTimeout(()=>{
        this.setState({
          customModal:true
        })
      }, 5)
    }

    AddLeadData = () => {
      if(this.state.add_lead_data_var_name != '' && this.state.add_lead_data_value != ''){
        let var_name = this.state.add_lead_data_var_name.replace('$lead_data->', '');
        let value = this.state.add_lead_data_value;
        let lead_data:any = [];
        lead_data.push({[var_name]:value});
        for(let i=0; i<this.state.lead_data.length; i++){
          lead_data.push(this.state.lead_data[i])
        }
       
        this.setState({
          lead_data:lead_data
        })

        this.ResetModal();
       
      }
      else{
        notySend('error', 'params must be not empty!');
      }
    }

    UpdateLeadData = (item:any, value:any) => {
      let lead_data = this.state.lead_data;
      const index = lead_data.indexOf(item);
      lead_data[index][Object.getOwnPropertyNames(item)[0]] = value
      
      this.setState({
        lead_data:lead_data
      })
    }

    DeleteLeadData = (item:any) => {
      let lead_data = this.state.lead_data;
      const index = lead_data.indexOf(item);
      if (index > -1) {
        lead_data.splice(index, 1);
      }
      this.setState({
        lead_data:lead_data
      })
      
      this.ResetModal();
    }
  
    render() {
      return (
        <div className='page-content'>
          <div className="card">
            <div className='card-body'>
                {this.RenderHeaderPage()}
                <div>-</div>
                <AceEditor
                    style={{width:'100%', height:'70vh'}}
                    mode="php"
                    theme="monokai"
                    highlightActiveLine={false}
                    value={this.state.code}
                    onChange={(data)=>{this.setState({code:data})}}
                    editorProps={{ $blockScrolling: true }}
                    setOptions={{
                        printMarginColumn:-1,
                        enableBasicAutocompletion: false,
                        enableLiveAutocompletion: true,
                        enableSnippets: true,
                        showLineNumbers: true,
                        tabSize: 2
                    }}
                />
                <hr></hr>
                
                <button disabled={this.state.run_btn} onClick={()=>{this.UpdateIntegCode()}} className='btn btn-outline-primary form-control'>{this.state.run_btn ? this.state.run_btn_loader : null} save</button>
                <hr></hr>
                <button disabled={this.state.run_btn} onClick={()=>{this.RunCodeInteg()}} className='btn btn-info form-control'>{this.state.run_btn ? this.state.run_btn_loader : null} save & run code</button>
                <hr></hr>
                {/* <div className='form-control' style={{minHeight:200}}><pre>{this.state.answer}</pre></div> */}
                <textarea defaultValue={this.state.answer} className='form-control' style={{minHeight:200}}></textarea>
            </div>
          </div>
          <CustomModal size={'large'} visible={this.state.customModal} header={'$lead_data'} callback={this.HideModal}>
            <div className='text-center'>
              <div className='row'>
                <div className='col-5'> 
                    <input onInput={(t)=>{this.setState({add_lead_data_var_name:t.currentTarget.value})}} className='form-control' defaultValue={this.state.add_lead_data_var_name}></input>
                </div>
                <div className='col-5'> 
                    <input onInput={(t)=>{this.setState({add_lead_data_value:t.currentTarget.value})}} className='form-control' defaultValue={this.state.add_lead_data_value}></input>
                </div>
                <div className='col-2'> 
                <div style={{textAlign:'right'}}>
                        <button onClick={()=>{this.AddLeadData()}} className='btn btn-primary form-control'><i style={{fontSize:16, margin:5}} className="material-icons control_point c_p">&#xe3ba;</i> add</button>
                    </div>
                </div>
              </div>
            </div>
            <hr></hr>
            <div>
            {this.state.lead_data.map((item:any) => {
              return (
                <div key={this.state.lead_data.indexOf(item)} className='row'>
                  <div className='col-5'>
                    <label>var in code</label>
                    <input className='form-control' disabled={true} defaultValue={'$lead_data->'+Object.getOwnPropertyNames(item)[0]}></input>
                  </div>
                  <div className='col-5'>
                    <label>value</label>
                    <input className='form-control' onInput={(t)=>{this.UpdateLeadData(item, t.currentTarget.value)}} defaultValue={item[Object.getOwnPropertyNames(item)[0]]}></input>
                  </div>
                  <div className='col-2'>
                    <label>delete</label>
                    <button onClick={()=>{this.DeleteLeadData(item)}} className='btn btn-outline-danger form-control'><i style={{fontSize:18, margin:0}} className="material-icons clear">&#xe14c;</i></button>
                  </div>
                </div>
              )
            })}
              
            </div>
            <br></br>
          </CustomModal>
        </div>
      );
    }
}

