import React, { Component } from 'react';
import { ApiReq, notySend, urlNavigate, getCheckerDomain } from './function';
import Select from 'react-select'
import TimeZonesSelect from './TimeZonesSelect';

let system_tyme_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export default class PayModal extends Component<any, any> {
    constructor(props: any){
      super(props);
      this.state = {
        pay_token:'',
        transaction_hash:'test',
        answer:''
      }
    }
  
    componentDidMount(){
        
    }

    SendPayToken = () => {
        if(this.state.pay_token != '' && this.state.transaction_hash != ''){
            const requestOptions = {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body:JSON.stringify({system_url:window.location.host, pay_token:this.state.pay_token, transaction_hash:this.state.transaction_hash, system_time_zone:system_tyme_zone}),
            };
            
            fetch(getCheckerDomain()+'/customers/accepting_pay_token/', requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.success == true){
                    window.location.reload();
                }
                else{
                    this.setState({
                        answer:data.info
                    })
                }
            });
        }
        else{
            this.setState({answer:'pay token must not be empty!'})
        }
    }
  
  
    render() {
        return (
            <div className="modal fade show" id="myModal" aria-modal="true" role="dialog" style={{display:'block', backgroundColor:'rgba(0, 0, 0, 0.7)'}}>
                {/* <div className="modal-dialog modal-lg modal-dialog-centered"> */}
                <div className="modal-dialog modal-md modal-dialog-centered">
                <div className="modal-content" >
                    <div className="modal-body" style={{backgroundColor:'#33404a'}}>
                    <div>
                        <h3 className='text-center'>WELCOME</h3>
                        <hr></hr>
                        <div className='form-group'>
                            <label>Pay token</label>
                            <input value={this.state.pay_token} onInput={(t)=>{this.setState({pay_token:t.currentTarget.value})}} className='form-control' placeholder='Write pay token'></input>
                            {/* <label>Transaction hash</label>
                            <input value={this.state.transaction_hash} onInput={(t)=>{this.setState({transaction_hash:t.currentTarget.value})}} className='form-control' placeholder='Write transaction hash'></input> */}
                            
                            <p className='text-center text-danger'>{this.state.answer}</p>
                            <div className='text-center'>
                                <button onClick={()=>{this.SendPayToken()}} className="btn btn-outline-primary" style={{borderRadius:20}}>START</button>
                            </div>
                        </div>
                        <hr></hr>
                        <div className='text-center'>
                        To gain access, you must send a payment token, a payment token can be obtained from the manager <p></p> Telegram <a href='https://t.me/trafpar' target={'_blank'} style={{textDecoration:'none'}}>@trafpar</a>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        )
    }
}

