import React, { Component } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonSelect, IonSelectOption, IonItem } from '@ionic/react';
import { ApiReq, notySend, getApiToken, getApiDomain, getUserData, urlNavigate, getUrlPath } from '../components/function';
import Loading from '../pages/Loading';
import {Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend, ArcElement, BarElement } from 'chart.js';
import { Line, Doughnut, Bar } from 'react-chartjs-2';


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  ArcElement
);

let color = '';
if(window.localStorage.getItem(window.location.origin+'_pixelcrm_theme') == null){
  color = 'white';
}
else{
  if(window.localStorage.getItem(window.location.origin+'_pixelcrm_theme') == 'dark'){
    color = 'white';
  }
  else{
    color = '#495057'
  }
}

ChartJS.defaults.color = color;

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: false,
      text: 'Chart.js Line Chart',
    },
    style:{
      color:'white'
    }
  },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
let data:any = {
  labels,
  total_leads:0,
  accepted:0,
  rejected:0,
  deposits:0,
  invalid:0,
  no_answer:0,
  datasets: [
    {
      fill: true,
      label: 'Total leads',
      data: [],
      borderColor: 'rgb(0, 123, 255)',
      backgroundColor: 'rgba(0, 123, 255, 0)',
    },
    {
      fill: true,
      label: 'Accepted',
      data: [],
      borderColor: 'rgb(40, 167, 69)',
      backgroundColor: 'rgba(40, 167, 69, 0)',
    },
    {
      fill: true,
      label: 'Rejected',
      data: [],
      borderColor: 'rgb(220, 53, 70)',
      backgroundColor: 'rgba(220, 53, 70, 0)',
    },
    {
      fill: true,
      label: 'Deposits',
      data: [],
      borderColor: 'rgb(255, 193, 7)',
      backgroundColor: 'rgba(255, 193, 7, 0)',
    },
    {
      fill: true,
      label: 'Invalid',
      data: [],
      borderColor: 'rgb(209, 19, 187)',
      backgroundColor: 'rgba(209, 19, 187, 0)',
    },
    {
      fill: true,
      label: 'No answer',
      data: [],
      borderColor: 'rgb(252, 122, 0)',
      backgroundColor: 'rgba(252, 122, 0, 0)',
    },
  ],
};

let main_chart_data_last_day:any = {
  labels: ['Accepted', 'Rejected', 'Deposits', 'Invalid', 'No answer'],
  total_leads:0,
  ftd_leads:0,
  datasets: [
    {
      data: [],
      backgroundColor: [
        'rgba(40, 167, 69, 0.3)',
        'rgba(220, 53, 70, 0.3)',
        'rgba(255, 193, 7, 0.3)',
        'rgba(209, 19, 187, 0.3)',
        'rgba(252, 122, 0, 0.3)',
      ],
      borderColor: [
        'rgba(40, 167, 69, 1)',
        'rgba(220, 53, 70, 1)',
        'rgba(255, 193, 7, 1)',
        'rgba(209, 19, 187, 1)',
        'rgba(252, 122, 0, 1)',
      ],
      borderWidth: 1,
    },
  ],
};

let main_chart_data_last_week:any = {
  labels: ['Accepted', 'Rejected', 'Deposits', 'Invalid', 'No answer'],
  total_leads:0,
  ftd_leads:0,
  datasets: [
    {
      data: [],
      backgroundColor: [
        'rgba(40, 167, 69, 0.3)',
        'rgba(220, 53, 70, 0.3)',
        'rgba(255, 193, 7, 0.3)',
        'rgba(209, 19, 187, 0.3)',
        'rgba(252, 122, 0, 0.3)',
      ],
      borderColor: [
        'rgba(40, 167, 69, 1)',
        'rgba(220, 53, 70, 1)',
        'rgba(255, 193, 7, 1)',
        'rgba(209, 19, 187, 1)',
        'rgba(252, 122, 0, 1)',
      ],
      borderWidth: 1,
    },
  ],
};

let main_chart_data_last_month:any = {
  labels: ['Accepted', 'Rejected', 'Deposits', 'Invalid', 'No answer'],
  total_leads:0,
  ftd_leads:0,
  datasets: [
    {
      data: [],
      backgroundColor: [
        'rgba(40, 167, 69, 0.3)',
        'rgba(220, 53, 70, 0.3)',
        'rgba(255, 193, 7, 0.3)',
        'rgba(209, 19, 187, 0.3)',
        'rgba(252, 122, 0, 0.3)',
      ],
      borderColor: [
        'rgba(40, 167, 69, 1)',
        'rgba(220, 53, 70, 1)',
        'rgba(255, 193, 7, 1)',
        'rgba(209, 19, 187, 1)',
        'rgba(252, 122, 0, 1)',
      ],
      borderWidth: 1,
    },
  ],
};

let data3:any = {
  labels: [],
  datasets: [
    {
      label: 'Leads',
      data: [],
      backgroundColor: 'rgba(0, 123, 255, 0.3)',
      borderColor:'rgba(0, 123, 255, 1)',
      borderWidth: 1,
    },
    // {
    //   label: 'Dataset 2',
    //   data: [0, 1, 99],
    //   backgroundColor: 'rgba(53, 162, 235, 0.5)',
    // },
  ],
};






export default class MainPage extends Component<any, any> {
    constructor(props: any){
      super(props);
      this.state = {
        chart:false,
        main_chart_data:main_chart_data_last_day,
        main_chart_data_period_name:'last day'
      }
    }
  
    componentDidMount(){
      this.GetPartnerAnalytics();
    }

    GetDataChart = () => {
      ApiReq('/analytics/get_year_analytics/').then(res => {
        if(res != false){
          if(res.success == true){
            for(let i=0; i<res.data.length; i++){
              data.total_leads = data.total_leads + res.data[i].data.total_leads;
              data.accepted = data.accepted + res.data[i].data.accepted;
              data.rejected = data.rejected + res.data[i].data.rejected;
              data.deposits = data.deposits + res.data[i].data.deposits;
              data.invalid = data.invalid + res.data[i].data.invalid;
              
              data.datasets[0].data.push(res.data[i].data.total_leads);
              data.datasets[1].data.push(res.data[i].data.accepted);
              data.datasets[2].data.push(res.data[i].data.rejected);
              data.datasets[3].data.push(res.data[i].data.deposits);
              data.datasets[4].data.push(res.data[i].data.invalid);
              data.datasets[5].data.push(res.data[i].data.no_answer_int);
              
            }
            this.setState({
              chart:true,
            })
          }
          else{
            notySend('error', 'error #'+res.error+'\n'+res.info)
          }
        }
      })
    }

    GetPartnerAnalytics = () => {
      ApiReq('/analytics/get_partner_analytics/').then(res => {
        if(res != false){
          if(res.success == true){
            for(let i=0; i<res.data.length; i++){
              data3.labels.push(res.data[i].partner);
              data3.datasets[0].data.push(res.data[i].value);
            }
            
            this.GetMainAnalytics();
          }
          else{
            notySend('error', 'error #'+res.error+'\n'+res.info)
          }
        }
      })
    }

    GetMainAnalytics = () => {
      ApiReq('/analytics/get_main_analytics/').then(res => {
        if(res != false){
          if(res.success == true){
            main_chart_data_last_day.total_leads = res.data.last_day.total_leads;
            main_chart_data_last_day.ftd = res.data.last_day.deposits;
            main_chart_data_last_day.datasets[0].data.push(res.data.last_day.accepted)
            main_chart_data_last_day.datasets[0].data.push(res.data.last_day.rejected)
            main_chart_data_last_day.datasets[0].data.push(res.data.last_day.deposits)
            main_chart_data_last_day.datasets[0].data.push(res.data.last_day.invalid)
            main_chart_data_last_day.datasets[0].data.push(res.data.last_day.no_answer_int)

            main_chart_data_last_week.total_leads = res.data.last_week.total_leads;
            main_chart_data_last_week.ftd = res.data.last_week.deposits;
            main_chart_data_last_week.datasets[0].data.push(res.data.last_week.accepted)
            main_chart_data_last_week.datasets[0].data.push(res.data.last_week.rejected)
            main_chart_data_last_week.datasets[0].data.push(res.data.last_week.deposits)
            main_chart_data_last_week.datasets[0].data.push(res.data.last_week.invalid)
            main_chart_data_last_week.datasets[0].data.push(res.data.last_week.no_answer_int)

            main_chart_data_last_month.total_leads = res.data.last_month.total_leads;
            main_chart_data_last_month.ftd = res.data.last_month.deposits;
            main_chart_data_last_month.datasets[0].data.push(res.data.last_month.accepted)
            main_chart_data_last_month.datasets[0].data.push(res.data.last_month.rejected)
            main_chart_data_last_month.datasets[0].data.push(res.data.last_month.deposits)
            main_chart_data_last_month.datasets[0].data.push(res.data.last_month.invalid)
            main_chart_data_last_month.datasets[0].data.push(res.data.last_month.no_answer_int)
            
            
            this.GetDataChart();
          }
          else{
            notySend('error', 'error #'+res.error+'\n'+res.info)
          }
        }
      })
    }

    SetMainChartPeriod = () => {
      if(this.state.main_chart_data_period_name == 'last day'){
        this.setState({
          main_chart_data_period_name:'last week',
          main_chart_data:main_chart_data_last_week,
        })
      }
      if(this.state.main_chart_data_period_name == 'last week'){
        this.setState({
          main_chart_data_period_name:'last month',
          main_chart_data:main_chart_data_last_month,
        })
      }
      if(this.state.main_chart_data_period_name == 'last month'){
        this.setState({
          main_chart_data_period_name:'last day',
          main_chart_data:main_chart_data_last_day,
        })
      }
    }

    RenderDoughnutChart = () => {
      return(
        <div className='card' >
            <div className='card-body' >
              <div>
                <h5>Analytics for <span onClick={()=>{this.SetMainChartPeriod()}} className='c_p text-info' style={{textDecorationLine:'underline'}}>{this.state.main_chart_data_period_name} </span></h5>
              </div>
              <hr></hr>
                <Doughnut style={{maxHeight:350}} data={this.state.main_chart_data}  />
              <hr></hr>
              <div className='row'>
                <div className='col text-center'>
                  <label>Total leads</label>
                  <div><strong>{this.state.main_chart_data.total_leads}</strong></div>
                </div>
                <div className='col text-center'>
                  <label>FTD</label>
                  <div><strong>{this.state.main_chart_data.ftd}</strong></div>
                </div>
              </div>
            </div>
          </div>
      )
    }

    RenderPartnersChart = () => {
      if(getUserData('role') == 'admin'){
        return(
          <div className="card">
            <div className='card-body'>
              <div >
                <h5>Partner analytics</h5>
              </div>
              <hr></hr>
              {/* <Line style={{width:'100%', maxHeight:400}} options={options} data={data} /> */}
              <Bar style={{width:'100%', maxHeight:400}} options={options} data={data3} />
            </div>
          </div>
        )
      }
      else{
        return null;
      }
    }

    RenderAnalyticsYearChart = () => {
      
        return(
          <div className='card' >
            <div className='card-body'>
              <div>
                <h5>Analytics for the year</h5>
              </div>
              <hr></hr>
              <Line style={{maxHeight:350}} options={options} data={data} />
              <hr></hr>
              <div className='row'>
                <div className='col text-center'>
                  <label>Total leads</label>
                  <div><strong>{data.total_leads}</strong></div>
                </div>
                <div className='col text-center'>
                  <label>Accepted</label>
                  <div><strong>{data.accepted} ({((data.accepted*100)/data.total_leads).toFixed(1)}%)</strong></div>
                </div>
                <div className='col text-center'>
                  <label>Rejected</label>
                  <div><strong>{data.rejected} ({((data.rejected*100)/data.total_leads).toFixed(1)}%)</strong></div>
                </div>
                <div className='col text-center'>
                  <label>Deposits</label>
                  <div><strong>{data.deposits} ({((data.deposits*100)/data.total_leads).toFixed(1)}%)</strong></div>
                </div>
                <div className='col text-center'>
                  <label>Invalid</label>
                  <div><strong>{data.invalid} ({((data.invalid*100)/data.total_leads).toFixed(1)}%)</strong></div>
                </div>
                <div className='col text-center'>
                  <label>No answer</label>
                  <div><strong>{data.no_answer} ({((data.no_answer*100)/data.total_leads).toFixed(1)}%)</strong></div>
                </div>
              </div>
            </div>
          </div>
        )
    }
  
    RenderChart = () => {
      if(window.innerWidth > 500){
        if(this.state.chart == true){
          return (
            <>
              <div style={{overflow:'hidden'}}>
                <div className='row' >
                  <div className='col-4' >
                    {this.RenderDoughnutChart()}
                  </div>
                  <div className='col-8'>
                    {this.RenderAnalyticsYearChart()}
                  </div>
                </div>
                {this.RenderPartnersChart()}
              </div>
            </>
          )
        }
        else{
          return(
            <div className="card">
              <div className='card-body'>
                <div className='table-responsive'>
                  <div style={{width:'100%', maxHeight:400, textAlign:'center'}}>
                    <Loading></Loading>
                  </div>
                </div>
              </div>
            </div>
            
            
          )
        }
      }
      else{
        return(
          //<div className='card'><div className='card-body'><div className='text-center'><h1>WELCOME</h1><br></br><span style={{fontSize:10}}>access to the chart only on desktop devices or on mobile devices in landscape orientation</span></div></div></div>
            this.RenderDoughnutChart()
          )
      }
    }

   
  
    render() {
      return (
        <div className='page-content'>
         {this.RenderChart()}
        </div>
      );
    }
}

