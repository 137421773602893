import React, { Component } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonSelect, IonSelectOption, IonItem } from '@ionic/react';
import { ApiReq, notySend, parseUAgent, getUserData, urlNavigate } from '../components/function';
import Loading from '../pages/Loading';



export default class ServiceConnectPage extends Component<any, any> {
    constructor(props: any){
      super(props);
      this.state = {
        table_data:[],
        req_status:false
      }
    }
  
    componentDidMount(){
      this.GetData();
    }

    GetData = () => {
      ApiReq('/service_connect/get_connections/').then(res => {
        if(res != false){
          if(res.success == true){
            this.setState({
              table_data:res.data,
              req_status:true
            })
          }
          else{
            notySend('error', 'error #'+res.error+'\n'+res.info)
          }
        }
      })
    }

    Delete = (token:string) => {
      ApiReq('/service_connect/delete/', {token:token}).then(res => {
        if(res != false){
          if(res.success == true){
            notySend('success', 'success');
            if(res.log_out != undefined){
              if(res.log_out == true){
                localStorage.removeItem('user_data');
                urlNavigate('/login/');
              }
              else{
                this.GetData();
              }
            }
            else{
              this.GetData();
            }
          }
          else{
            notySend('error', 'error #'+res.error+'\n'+res.info)
          }
        }
      })
    }

    RenderTable = () => {
      if(this.state.table_data.length == 0 && this.state.req_status == false){
        return <div className='text-center'><Loading></Loading></div>;
      }
      else{
        if(this.state.table_data.length == 0){
          return <div className='text-center'>you don't have brokers</div>;
        }
        else{
          return (
            <div className='table-responsive'>
              <table className="table text-white">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>status</th>
                    <th>login_email</th>
                    <th>ip</th>
                    <th>auth_time</th>
                    <th>last_connect_time</th>
                    <td>delete</td>
                  </tr>
                </thead>
                <tbody>
                  {this.state.table_data.map((item:any) => {
                    
                    return (
                      <tr key={item.id}>
                          <td>{this.state.table_data.indexOf(item) + 1}</td>
                          <td><div style={{width:20, height:20}} className="spinner-grow text-success"></div></td>
                          <td>{item.user_email}</td>
                          <td>{item.user_ip}</td>
                          <td>{item.auth_time}</td>
                          <td>{item.last_connect_time}</td>
                          <td><button onClick={()=>{this.Delete(item.token)}} className='btn btn-danger'>delete</button></td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          )
        }
      }
    }
  
  
    render() {
      return (
        <div className='page-content'>
          <div className="card">
            <div className='card-body'>
                
                <br></br>
                {this.RenderTable()}
            </div>
          </div>
        </div>
      );
    }
}

