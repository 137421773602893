import React, { Component } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonSelect, IonSelectOption, IonItem } from '@ionic/react';
import { ApiReq, notySend, urlNavigate, generateToken, parsGetParams } from '../components/function';
import RolesSelect from '../components/RolesSelect';
import ManagersSelect from '../components/ManagersSelect';
import Loading from '../pages/Loading';


export default class LogsPage extends Component<any, any> {
    constructor(props: any){
      super(props);
      this.state = {
        type:'out',
        filter_data:{date_from:'', date_to:'', req_url:'', req_from_url:'', req_ip:'', type:'out', limit:[0, 20]},
        logs_data:[],
        page_load:false,
        btn_load_more:false,
        
      }
    }
  
    componentDidMount(){
      this.GetRequestsLog();
    }

    

    GetRequestsLog = () => {
        this.setState({
            page_load:false
        })
        ApiReq('/logs/get_requests_log/', this.state.filter_data).then(res => {
            if(res != false){
                if(res.success == true){
                    this.setState({
                        logs_data:res.data,
                        page_load:true,
                        btn_load_more:true
                    })
                }
                else{
                    notySend('error', 'error #'+res.error+'\n'+res.info);
                }
            }
        })
    }

    LoadMore = () => {
        let filter_data = this.state.filter_data;
        filter_data.limit = [filter_data.limit[0]+20, filter_data.limit[1]+20]
        this.setState({
            page_load:false,
            filter_data:filter_data
        })
        ApiReq('/logs/get_requests_log/', filter_data).then(res => {
            if(res != false){
                if(res.success == true){
                    let logs_data = this.state.logs_data;
                    if(res.data.length > 0){
                        for(let i=0; i<res.data.length; i++){
                            logs_data.push(res.data[i])
                        }
                        this.setState({
                            logs_data:logs_data,
                            page_load:true,
                            btn_load_more:true
                        })
                    }
                    else{
                        this.setState({
                            page_load:true,
                            btn_load_more:false
                        })
                    }
                }
                else{
                    notySend('error', 'error #'+res.error+'\n'+res.info);
                }
            }
        })
    }

    UdateFilter = (key:any, value:any) => {
        let filter_data = this.state.filter_data;
        filter_data[key] = value;
        this.setState({
            filter_data:filter_data
        })
    }
  
    SetType = (type:string) => {
        this.UdateFilter('type', type);
        this.setState({type:type})
        setTimeout(()=>{
            this.GetRequestsLog();
        }, 200)
    }
  
    render() {
      return (
        <div className='page-content'>
          <div className="card">
            <div className='card-body'>
              <div>
                <h5>{(this.state.type == 'out') ? <><span onClick={()=>{this.SetType('out')}} className='c_p text-primary'>Requests log </span><span onClick={()=>{this.SetType('in')}} className='c_p' style={{marginLeft:30}}> Others log</span></>: <><span onClick={()=>{this.SetType('out')}} className='c_p'>Requests log </span><span onClick={()=>{this.SetType('in')}} className='c_p text-primary' style={{marginLeft:30}}> Others log</span></>}</h5>
                <hr></hr>
                <div className='row'>
                    <div className='col'>
                        <label>date from</label>
                        <input onInput={(t)=>{this.UdateFilter('date_from', t.currentTarget.value)}} value={this.state.filter_data.date_from} className='form-control' type={'datetime-local'}></input>
                    </div>
                    <div className='col'>
                        <label>date to</label>
                        <input onInput={(t)=>{this.UdateFilter('date_to', t.currentTarget.value)}} value={this.state.filter_data.date_to} className='form-control' type={'datetime-local'}></input>
                    </div>
                    <div className='col'>
                        <label>req url</label>
                        <input onInput={(t)=>{this.UdateFilter('req_url', t.currentTarget.value)}} value={this.state.filter_data.req_url} className='form-control'></input>
                    </div>
                    <div className='col'>
                        <label>from url</label>
                        <input onInput={(t)=>{this.UdateFilter('req_from_url', t.currentTarget.value)}} value={this.state.filter_data.req_from_url} className='form-control'></input>
                    </div>
                    <div className='col'>
                        <label>req ip</label>
                        <input onInput={(t)=>{this.UdateFilter('req_ip', t.currentTarget.value)}} value={this.state.filter_data.req_ip} className='form-control'></input>
                    </div>
                    <div className='col'>
                        <label>filter</label>
                        <button onClick={()=>{this.GetRequestsLog()}} className='btn btn-outline-primary form-control'><i style={{fontSize:18, margin:0}} className="material-icons text-primary filter_list_alt">&#xe94e;</i> filter</button>
                    </div>
                </div>
                <hr></hr>
              </div>
              
              {this.state.logs_data.map((item:any) => {
                return(
                    <div key={item.id}>
                        <ul className=" text-white card" style={{borderColor:'white', padding:0}}>
                            <li style={{background:'transparent'}} className="list-group-item text-white d-flex justify-content-between align-items-center">
                                Date : {item.req_date}
                                {/* <span className="badge badge-primary badge-pill">{item.req_date}</span> */}
                            </li>
                            <li style={{background:'transparent'}} className="list-group-item text-white d-flex justify-content-between align-items-center">
                                Request url : {item.req_url}
                                {/* <span className="badge badge-primary badge-pill">{item.req_url}</span> */}
                            </li>
                            <li style={{background:'transparent'}} className="list-group-item text-white d-flex justify-content-between align-items-center">
                                From : {item.req_from}
                                {/* <span className="badge badge-primary badge-pill">{item.req_from}</span> */}
                            </li>
                            <li style={{background:'transparent'}} className="list-group-item text-white d-flex justify-content-between align-items-center">
                                Ip : {item.req_ip}
                                {/* <span className="badge badge-primary badge-pill">{item.req_ip}</span> */}
                            </li>
                            <li style={{background:'transparent'}} className="list-group-item text-white ">
                                <label>Headers</label>
                                <textarea defaultValue={item.req_headers} style={{minHeight:100}} className='form-control'></textarea>
                            </li>
                            <li style={{background:'transparent'}} className="list-group-item text-white ">
                                <label>Body</label>
                                <textarea defaultValue={item.req_body} style={{minHeight:100}} className='form-control'></textarea>
                            </li>
                            <li style={{background:'transparent'}} className="list-group-item text-white ">
                                <label>Answer from system</label>
                                <textarea defaultValue={item.req_answer} style={{minHeight:100}} className='form-control'></textarea>
                            </li>
                        </ul>
                        <br></br>
                    </div>
                )
              })}
              {this.state.page_load ? null : <div className='text-center'><Loading></Loading><p></p></div>}
              {this.state.btn_load_more ? <div className='text-center'><button onClick={()=>{this.LoadMore()}} style={{borderRadius:20}} className='btn btn-outline-primary'>load more</button></div> : null}
             
            </div>
            <br></br>
          </div>
        </div>
      );
    }
}

