import React, { Component } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonSelect, IonSelectOption, IonItem } from '@ionic/react';
import { ApiReq, notySend, getUserData, urlNavigate, getClientConfig, sync_config, getCheckerDomain } from '../components/function';
import Loading from '../pages/Loading';
import StartSettingModal from '../components/StartSettingModal';
import UpdateCacheModal from '../components/UpdateCacheModal';
import { TagsInput } from "react-tag-input-component";


export default class SettingsPage extends Component<any, any> {
    constructor(props: any){
      super(props);
      this.state = {
        page:'system',
        pages:[{id:0, name:'system', activ:true}, {id:1, name:'status setting', activ:false}, {id:2, name:'api keys setting', activ:false}, {id:3, name:'autologin url settings', activ:false}],
        status_setting_rady:false,
        status_setting_data:[],
        api_keys_list:[],
        config_name:getClientConfig('name'),
        config_logo:null,
        db_setting:false,
        product_info:{date_from:null, date_to:null, access:false, subscribe_type:null},
        updates_info:'You have last version!',
        updateCacheModal:false,
        autologin_url_config:{"name":"autologin_url","lifetime":15,"onetime_url":"true"}
      }
    }
  
    componentDidMount(){
      this.GetStatuses();
      this.GetApiKeys();
      this.GetProductInfo();
      this.GetUpdatesInfo();
      this.GetAutologinUrlConfig();
    }

    

    GetApiKeys = () => {
      ApiReq('/api_keys/get_list/').then(res => {
        if(res != false){
          if(res.success == true){
            this.setState({
              api_keys_list:res.data
            })
          }
          else{
            notySend('error', 'error #'+res.error+'\n'+res.info)
          }
        }
      })
    }

    UpdateApiKeys = () => {
      ApiReq('/api_keys/update/', this.state.api_keys_list[0]).then(res => {
        if(res != false){
          if(res.success == true){
            notySend('success', 'success')
          }
          else{
            notySend('error', 'error #'+res.error+'\n'+res.info)
          }
        }
      })
    }

    GetStatuses = () => {
      ApiReq('/statuses_replacement/get_status_list/').then(res => {
        if(res != false){
          if(res.success == true){
            this.setState({
              status_setting_rady:true,
              status_setting_data:res.data
            })
          }
          else{
            notySend('error', 'error #'+res.error+'\n'+res.info)
          }
        }
      })
    }

    UpdateStatuses = () => {
      ApiReq('/statuses_replacement/update/', {status_setting_data:this.state.status_setting_data}).then(res => {
        if(res != false){
          if(res.success == true){
            notySend('success', 'success')
          }
          else{
            notySend('error', 'error #'+res.error+'\n'+res.info)
          }
        }
      })
    }

    UpdateStatusSetting = (p:number, data:any) => {
      let status_setting_data = this.state.status_setting_data;
      status_setting_data[p].replace_data = data;
      this.setState({
        status_setting_data:status_setting_data
      })
    }

   

    UpdateConfig = (param:any) => {
      if(param == 'name'){
        ApiReq('/system_config/set_config/', {name:'name', val:this.state.config_name}).then(res => {
          if(res != false){
            if(res.success == true){
              notySend('success', 'success')
              sync_config();
            }
            else{
              notySend('error', 'error #'+res.error+'\n'+res.info)
            }
          }
        })
      }
      if(param == 'logo'){
        if(this.state.config_logo != null){
          // const img = new FormData();
          // img.append('image', this.state.config_logo[0], this.state.config_logo[0].name);
          console.log(this.state.config_logo[0])
          var file = this.state.config_logo[0];
          var reader = new FileReader();
          reader.onloadend = function() {
            // console.log('RESULT', reader.result)
            ApiReq('/system_config/set_logo/', {logo:reader.result}).then(res => {
              if(res != false){
                if(res.success == true){
                  notySend('success', 'success!\n'+'Wait about 10 minutes for the cache to sync and do a full page refresh (on windows control + shift + R, on mac command + shift + R)')
                  sync_config();
                }
                else{
                  notySend('error', 'error #'+res.error+'\n'+res.info)
                }
              }
            })
          }
          reader.readAsDataURL(file);
        }
        else{
          notySend('error', 'choose an image for the logo')
        }
      }
    }

    GetProductInfo = () => {
      const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body:JSON.stringify({system_url:window.location.host}),
      };
      
      fetch(getCheckerDomain()+'/customers/get_customer_info/', requestOptions)
      .then(response => response.json())
      .then(data => {
          if(data.success == true){
             this.setState({
                product_info:data.data
             })
          }
          else{
            notySend('error', 'error #'+data.error+'\n'+data.info)
          }
      });
    }

    GetUpdatesInfo = () => {
      const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body:JSON.stringify({current_version:getClientConfig('version')}),
      };
      
      fetch(getCheckerDomain()+'/customers/check_update_system/', requestOptions)
      .then(response => response.json())
      .then(data => {
          if(data.success == true){
            let about_url = null;
            if(data.data.about_url != null){
              about_url = <a target={'_blank'} href={data.data.about_url} className={'btn btn-info'}>about</a>
            }
            let update = <div><a target={'_blank'} href={window.location.origin+'/api/update_system/index.html?download_url='+data.data.download_url+'&current_version='+getClientConfig('version')+'&new_version='+data.data.version} className='btn btn-success'>v. {data.data.version} update now</a> {about_url}</div>
            
            this.setState({
              updates_info:update
            })
          }
          else{
            this.setState({
              updates_info:'You have last version!'
            })
          }
      });
    }

    GetAutologinUrlConfig = () => {
      ApiReq('/system_config/get_autologin_url_config/').then(res => {
        if(res != false){
            // if(res.success == true){
              
            // }
            // else{
            //   notySend('error', 'error #'+res.error+'\n'+res.info)
            // }
            this.setState({autologin_url_config:res})
        }
      })
    }

    SetAutologinUrlConfig = () => {
      ApiReq('/system_config/set_autologin_url_config/', this.state.autologin_url_config).then(res => {
        if(res != false){
            if(res.success == true){
              notySend('success', 'success')
            }
            else{
              notySend('error', 'error #'+res.error+'\n'+res.info)
            }
        }
      })
    }

    SetPage = (name:string) => {
      let pages = this.state.pages;
      for(let i=0; i<pages.length; i++){
        if(pages[i].name == name){
          pages[i].activ = true;
        }
        else{
          pages[i].activ = false;
        }
      }
      this.setState({
        page:name,
        pages:pages
      })
    }

    RenderPageMenu = () => {
      return(
        <div className='row'>
          {this.state.pages.map((item:any) => {
            let activ = item.activ ? 'btn-primary':'btn-outline-light';
            return(
              <div key={item.id} className='col'>
                <button onClick={()=>{this.SetPage(item.name)}} style={{marginBottom:10, minWidth:100}} className={'btn '+activ+' form-control'}>{item.name}</button>
              </div>
            )
          })}
        </div>
      );
    }

    RenderPage = () => {
      if(this.state.page == 'system'){
        if(window.innerWidth > 500){
          return(
            <div>
              <h3>Product</h3>
              <div className='row'>
                  <div className='col-3'>type</div>
                  <div className='col-9'>{this.state.product_info.subscribe_type} version</div>
              </div>
              
              <hr></hr>
              <div className='row'>
                  <div className='col-3'>subscription end date</div>
                  <div className='col-9'>
                      <div className='row'>
                          <div className='col-2'>
                              <span className='badge bg-info rounded-pill ms-auto'>{this.state.product_info.date_to}</span>
                          </div>
                          <div className='col-10' style={{textAlign:'right'}}>
                              {/* <span className='badge bg-success rounded-pill ms-auto c_p'>renew subscription</span> */}
                          </div>
                      </div>
                  </div>
              </div>
              <br></br>
              <h3>Crone</h3>
              <div className='row'>
                  <div className='col-3'>update leads crone</div>
                  <div className='col-9'>{this.state.product_info.access ? <span className='badge bg-success rounded-pill ms-auto'>true</span> : <span className='badge bg-danger rounded-pill ms-auto'>false</span>} </div>
              </div>
              <hr></hr>
              <br></br>
              <h3>User</h3>
              <div className='row'>
                <div className='row'>
                    <div className='col-3'>login & role</div>
                    <div className='col-9'>{getUserData('login')} <span className="badge bg-primary rounded-pill ms-auto">{getUserData('role')}</span></div>
                </div>
              </div>
              <hr></hr>
              <br></br>
              <h3>System</h3>
              <div className='row'>
                  <div className='col-3'>name</div>
                  <div className='col-9'>
                    <div className='row'>
                      <div className='col'>
                        <input value={this.state.config_name} onInput={(t)=>{this.setState({config_name:t.currentTarget.value})}} className='form-control'></input>
                      </div>
                      <div className='col'>
                        <button onClick={()=>{this.UpdateConfig('name')}} className='btn btn-primary'>save</button>
                      </div>
                    </div>
                  </div>
              </div>
              <hr></hr>
              <div className='row'>
                  <div className='col-3'>logo</div>
                  <div className='col-9'>
                    <div className='row'>
                      <div className='col'>
                        <input type={'file'} onChange={(t)=>{this.setState({config_logo:t.currentTarget.files})}} className='form-control'></input>
                      </div>
                      <div className='col'>
                        <button onClick={()=>{this.UpdateConfig('logo')}} className='btn btn-primary'>save</button>
                      </div>
                    </div>
                  </div>
              </div>
              <hr></hr>
              <div className='row'>
                  <div className='col-3'>cache data of drop-down lists</div>
                  <div className='col-9'><span onClick={()=>{this.setState({updateCacheModal:true})}} className="badge bg-primary c_p rounded-pill ms-auto">update</span></div>
              </div>
              <hr></hr>
              <div className='row'>
                  <div className='col-3'>updates</div>
                  <div className='col-9'>{this.state.updates_info}</div>
              </div>
              <hr></hr>
              <div className='row'>
                  <div className='col-3'>version</div>
                  <div className='col-9'>{getClientConfig('version')}</div>
              </div>
              <hr></hr>
              <div className='row'>
                  <div className='col-3'>database</div>
                  <div className='col-9'><button onClick={()=>{this.setState({db_setting:true})}} className='btn btn-primary'>show settings</button></div>
              </div>
            </div>
          )
        }
        else{
          return(
            <div>
              <h3>Product</h3>
              <div className='row'>
                  <div className='col'>type</div>
                  <div className='col'>{this.state.product_info.subscribe_type} version</div>
              </div>
              
              <hr></hr>
              <div className='row'>
                  <div className='col'>subscription end date</div>
                  <div className='col'>
                      <div className='row'>
                          <div className='col-2'>
                              <span className='badge bg-info rounded-pill ms-auto'>{this.state.product_info.date_to}</span>
                          </div>
                          <div className='col-10' style={{textAlign:'right'}}>
                              {/* <span className='badge bg-success rounded-pill ms-auto c_p'>renew subscription</span> */}
                          </div>
                      </div>
                  </div>
              </div>
              <br></br>
              <h3>Crone</h3>
              <div className='row'>
                  <div className='col'>update leads crone</div>
                  <div className='col'>{this.state.product_info.access ? <span className='badge bg-success rounded-pill ms-auto'>true</span> : <span className='badge bg-danger rounded-pill ms-auto'>false</span>} </div>
              </div>
              <hr></hr>
              <br></br>
              <h3>User</h3>
              <div className='row'>
                <div className='row'>
                    <div className='col-3'>login & role</div>
                    <div className='col-9'>{getUserData('login')} <span className="badge bg-primary rounded-pill ms-auto">{getUserData('role')}</span></div>
                </div>
              </div>
              <hr></hr>
              <br></br>
              <h3>System</h3>
              <div className='row'>
                  <div className='col-3'>name</div>
                  <div className='col-9'>
                    <div className='row'>
                      <div className='col'>
                        <input value={this.state.config_name} onInput={(t)=>{this.setState({config_name:t.currentTarget.value})}} className='form-control'></input>
                      </div>
                      <div className='col'>
                        <button onClick={()=>{this.UpdateConfig('name')}} className='btn btn-primary'>save</button>
                      </div>
                    </div>
                  </div>
              </div>
              <hr></hr>
              <div className='row'>
                  <div className='col-3'>logo</div>
                  <div className='col-9'>
                    <div className='row'>
                      <div className='col'>
                        <input type={'file'} onChange={(t)=>{this.setState({config_logo:t.currentTarget.files})}} className='form-control'></input>
                      </div>
                      <div className='col'>
                        <button onClick={()=>{this.UpdateConfig('logo')}} className='btn btn-primary'>save</button>
                      </div>
                    </div>
                  </div>
              </div>
              <hr></hr>
              <div className='row'>
                  <div className='col-7'>cache data of drop-down lists</div>
                  <div className='col-5'><span onClick={()=>{this.setState({updateCacheModal:true})}} className="badge bg-primary c_p rounded-pill ms-auto">update</span></div>
              </div>
              <hr></hr>
              <div className='row'>
                  <div className='col'>updates</div>
                  <div className='col'>{this.state.updates_info}</div>
              </div>
              <hr></hr>
              <div className='row'>
                  <div className='col'>version</div>
                  <div className='col'>{getClientConfig('version')}</div>
              </div>
              <hr></hr>
              <div className='row'>
                  <div className='col'>database</div>
                  <div className='col'><button onClick={()=>{this.setState({db_setting:true})}} className='btn btn-primary'>show settings</button></div>
              </div>
            </div>
          )
        }
      }
      if(this.state.page == 'status setting'){
        if(this.state.status_setting_rady == false){
          return(
            <div className='text-center'><Loading></Loading></div>
          )
        }
        else{
          return(
            <div>
              {this.state.status_setting_data.map((item:any) => {
                return (
                  <div style={{marginTop:5}} key={this.state.status_setting_data.indexOf(item)} className='form-group'>
                    <label style={{fontSize:18}}>{item.name}</label>
                    {/* <textarea value={item.replace_data} placeholder={'examle_status, examle_status2, examle_status3'} onInput={(t)=>{this.UpdateStatusSetting(this.state.status_setting_data.indexOf(item), t.currentTarget.value)}} className='form-control'></textarea> */}
                    <TagsInput
                      value={item.replace_data}
                      onChange={(t:any)=>{this.UpdateStatusSetting(this.state.status_setting_data.indexOf(item), t)}}
                      name={item.name}
                      placeHolder="Write status and click enter"
                    />
                  </div>
                )
              })}
              <hr></hr>
              <div>
                <button onClick={()=>{this.UpdateStatuses()}} className='btn btn-primary form-control'>save</button>
              </div>
            </div>
          )
        }
      }
      if(this.state.page == 'api keys setting'){
        return(
          <div>
            {this.state.api_keys_list.map((item:any) => {
                return (
                  <div key={item.id} className='row'>
                    <div className='col'>
                      <label>Service name</label>
                      <input value={item.name} disabled={true} className='form-control'></input>
                    </div>
                    <div className='col'>
                      <label>Service url</label>
                      <input disabled={true} value={item.service_url} className='form-control'></input>
                    </div>
                    <div className='col'>
                      <label>Service data (key)</label>
                      <input defaultValue={item.data} onInput={(t)=>{let d=this.state.api_keys_list; d[0].data=t.currentTarget.value; this.setState({api_keys_list:d})}} className='form-control'></input>
                    </div>
                    <div className='col'>
                      <label>Service about</label>
                      <input defaultValue={item.about} className='form-control'></input>
                    </div>
                    <div className='col'>
                      <label>Save</label>
                      <button onClick={()=>{this.UpdateApiKeys()}} className='btn btn-outline-primary form-control'>save</button>
                    </div>
                  </div>
                )
              })}
          </div>
        )
      }
      if(this.state.page == 'autologin url settings'){
        return(
          <div className='form-group'>
              <label>param name (for partner answer)</label>
              <input className='form-control' onInput={(t)=>{let d=this.state.autologin_url_config; d.name=t.currentTarget.value; this.setState({autologin_url_config:d})}} value={this.state.autologin_url_config.name}></input>
              <label>lifetime (in sec.)</label>
              <input className='form-control' onInput={(t)=>{let d=this.state.autologin_url_config; d.lifetime=t.currentTarget.value; this.setState({autologin_url_config:d})}} value={this.state.autologin_url_config.lifetime} type={'number'}></input>
              <label>one-time url</label>
              <select value={this.state.autologin_url_config.onetime_url} onChange={(t)=>{let d=this.state.autologin_url_config; d.onetime_url=t.currentTarget.value; this.setState({autologin_url_config:d})}} className='form-control'>
                {(this.state.autologin_url_config.onetime_url == 'true') ? <><option value={'true'}>true</option><option value={'false'}>false</option></>:<><option value={'false'}>false</option><option value={'true'}>true</option></>}
              </select>
              <hr></hr>
              <button onClick={()=>{this.SetAutologinUrlConfig()}} className='btn btn-primary form-control'>save</button>
          </div>
        )
      }
    }
  
  
    render() {
      return (
        <div className='page-content'>
          {this.state.db_setting ? <StartSettingModal></StartSettingModal> : null}
          <UpdateCacheModal callback={()=>{this.setState({updateCacheModal:false})}} visible={this.state.updateCacheModal}></UpdateCacheModal>
          <div className="card">
            <div className='card-body'>
                {this.RenderPageMenu()}
                <hr></hr>
                {this.RenderPage()}
            </div>
          </div>
        </div>
      );
    }
}

