import React, { Component } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonSelect, IonSelectOption, IonItem } from '@ionic/react';
import { ApiReq, notySend } from '../components/function';
import Loading from '../pages/Loading';



export default class Header_page extends Component<any, any> {
    constructor(props: any){
      super(props);
      this.state = {
          page:null,
          mini_menu:false,
          notifications_show:false,
          
      }
    }
  
    componentDidMount(){
      
      document.getElementsByClassName('overlay')[0].addEventListener("click", () =>{
        document.getElementById('mainwrapper')?.classList.remove('toggled');
      })

      this.RenderAlarmSubscrube();
    }
  
    LogOut = () => {
      ApiReq('/auth/logout/').then(res => {
        if(res != false){
          if(res.success == true){
            localStorage.removeItem(window.location.origin+'_pixelcrm_user_login');
            window.location.replace('/login/')
          }
          else{
            localStorage.removeItem(window.location.origin+'_pixelcrm_user_login');
            window.location.replace('/login/')
            notySend('error', 'error #'+res.error+'\n'+res.info)
          }
        }
      })
    }

    ShowNavigationMobile = () => {
      
      let classList:any = document.getElementById('mainwrapper')?.classList;
      document.getElementById('mainwrapper')?.classList.add('toggled');
      
    }

    RenderAlarmSubscrube = () => {
      let subscrube_info_from_checker:any = '{}';
      subscrube_info_from_checker = localStorage.getItem('subscrube_info_from_checker');
      if(subscrube_info_from_checker != null && subscrube_info_from_checker != undefined){
        subscrube_info_from_checker = JSON.parse(subscrube_info_from_checker);
        if(subscrube_info_from_checker.subscribe_type == 'rent'){
          const date1:any = new Date(subscrube_info_from_checker.date_to);
          const date2:any = new Date();
          const diffTime = Math.abs(date2 - date1);
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
          // console.log(diffDays + " days");
          if(diffDays < 7){
            return (
                <li className="nav-item " style={{marginRight:15}}>
                  <button onClick={()=>{notySend('error', 'your subscription expires in '+diffDays+' days!')}} style={{borderRadius:20, paddingTop:2, paddingBottom:2}} className='btn btn-outline-danger'>{diffDays} days</button>
                </li>
            )
          }
        }
      }
      
      
    }

    SetColorMode = (val:string) => {
      if(val == 'dark'){
        window.localStorage.setItem(window.location.origin+'_pixelcrm_theme', 'dark');
      }
      else{
        window.localStorage.setItem(window.location.origin+'_pixelcrm_theme', 'ligth');
      }
      window.location.reload();
    }

    SetMagicBg = () => {
      (window.localStorage.getItem(window.location.origin+'_pixelcrm_magic_bg') == null)?window.localStorage.setItem(window.location.origin+'_pixelcrm_magic_bg', 'true'):window.localStorage.removeItem(window.location.origin+'_pixelcrm_magic_bg')
      window.location.reload();
    }

    RenderColorModeIcon = () => {
      if(window.localStorage.getItem(window.location.origin+'_pixelcrm_theme') == null){
        return(
          <span className='c_p' onClick={()=>{this.SetColorMode('light')}}>
            <div className="apps p-2 radius-10 text-center">
              <i style={{marginTop:6}} className="material-icons wb_sunny text-warning c_p">&#xe430;</i>
              <p className="mb-0 apps-name text-white">Theme</p>
            </div>
          </span>
        )
      }
      else{
        if(window.localStorage.getItem(window.location.origin+'_pixelcrm_theme') == 'dark'){
          return(
            <span className='c_p' onClick={()=>{this.SetColorMode('light')}}>
              <div className="apps p-2 radius-10 text-center">
                <i style={{marginTop:6}} className="material-icons wb_sunny text-warning c_p">&#xe430;</i>
                <p className="mb-0 apps-name text-white">Theme</p>
              </div>
            </span>
          )
        }
        else{
          return(
            <span className='c_p' onClick={()=>{this.SetColorMode('dark')}}>
              <div className="apps p-2 radius-10 text-center">
                <i style={{marginTop:6}} className="material-icons wb_sunny text-dark c_p">&#xe430;</i>
                <p className="mb-0 apps-name text-white">Theme</p>
              </div>
            </span>
          )
        }
      }
    }


    // RenderMiniMenu = () => {
    //   if(this.state.mini_menu == false){
    //     return(
    //       <li className="nav-item " style={{marginRight:15}}>
    //         <i onClick={()=>{this.setState({mini_menu:true})}} style={{marginTop:4}} className="material-icons c_p keyboard_control">&#xe5d3;</i>
    //       </li>
    //     )
    //   }
    //   else{
    //     return(
    //       <>
    //         <li className="nav-item " style={{marginRight:15}}>
    //           <i onClick={()=>{this.SetMagicBg()}}  style={{marginTop:0, background: "linear-gradient(to right, #30CFD0 0%, #ff00e1 100%)", WebkitBackgroundClip:'text', WebkitTextFillColor:'transparent'}} className="material-icons auto_fix_high c_p">&#xe663;</i>
    //         </li>
    //         <li className="nav-item " style={{marginRight:15}}>
    //           {this.RenderColorModeIcon()}
    //         </li>
    //         <li className="nav-item " style={{marginRight:15}}>
    //           <i style={{marginTop:6}} onClick={()=>{window.location.replace(window.location.href)}} className="material-icons replay text-info c_p">&#xe042;</i>
    //         </li>
    //       </>
    //     )
    //   }
    // }

    RenderNotifications = () => {
      if(this.state.notifications_show == true){
        return(
          <div className="dropdown-menu dropdown-menu-end p-0 show" style={{right:0, top:60}}>
            <div className="p-2 border-bottom m-2">
                <h5 className="h5 mb-0">Notifications</h5>
            </div>
            <div className="header-notifications-list p-2" style={{overflowY:'scroll'}}>
                <div style={{background:(localStorage.getItem(window.location.origin+'_pixelcrm_theme') == 'dark')?'rgba(255, 255, 255, 0.1)':'rgba(0, 0, 0, 0.1)', padding:10, borderRadius:10, marginBottom:10}}>
                  <div style={{float:'right'}}><i className="material-icons highlight_off text-danger c_p">&#xe888;</i></div>
                  <strong>Title</strong>
                  <div style={{ height:'auto'}}>You have recived new orders ваша подписка истекает через 7 дней</div>
                </div>
            </div>
            
            <div className="p-2">
                <div><hr className="dropdown-divider"></hr></div>
                {/* <a className="dropdown-item" href="#">
                  <div className="text-center">Clear all Notifications</div>
                </a> */}
            </div>
          </div>
        )
      }
      else{
        return null;
      }
    }

    RenderDropdawnMenu = () => {
      return(
        <div className={this.state.mini_menu?'dropdown-menu dropdown-menu-end show':'dropdown-menu dropdown-menu-end'} data-bs-popper="static" style={{marginTop:5, marginRight:2}}>
          <div className="row row-cols-3 gx-2">
            <div className="col">
              <span onClick={()=>{this.SetMagicBg()}} className='c_p'>
                <div className="apps p-2 radius-10 text-center">
                    <i style={{marginTop:6, background: "linear-gradient(to right, #30CFD0 0%, #ff00e1 100%)", WebkitBackgroundClip:'text', WebkitTextFillColor:'transparent'}} className="material-icons auto_fix_high c_p">&#xe663;</i>
                    <p className="mb-0 apps-name text-white">Magic BG</p>
                </div>
              </span>
            </div>
            <div className="col">
              {this.RenderColorModeIcon()}
            </div>
            <div className="col">
              <span className='c_p' onClick={()=>{window.location.reload()}}>
                <div className="apps p-2 radius-10 text-center">
                  <i style={{marginTop:6}} className="material-icons replay text-info c_p">&#xe042;</i>
                  <p className="mb-0 apps-name text-white">Reload</p>
                </div>
              </span>
            </div>
          </div>
      </div>
      )
    }
  
    render() {
      return (
        <header className="top-header">        
            <nav className="navbar navbar-expand gap-3">
                <div className="mobile-toggle-icon fs-3 text-white">
                    <i onClick={()=>{this.ShowNavigationMobile()}} className="material-icons notes">&#xe26c;</i>
                    {/* <i onClick={()=>{this.ShowNavigationMobile()}} className="fadeIn animated bx bx-menu"></i> */}
                </div>
                <span className='text-white'>{localStorage.getItem('page_name')}</span>
                <div className="top-navbar-right ms-auto">
                    <ul className="navbar-nav align-items-center">
                        
                        {/* <li className="nav-item ">
                            <div>
                              <span style={{fontSize:25}}>20:34</span>
                              <div style={{marginTop:-10, fontSize:10, textAlign:'center'}}>Fridey</div>
                            </div>                
                        </li> */}


                        {/* <li className="nav-item dropdown dropdown-large">
                          <span className="nav-link dropdown-toggle dropdown-toggle-nocaret"  data-bs-toggle="dropdown" style={{marginTop:6}}>
                            <div className="notifications">
                              <span className="notify-badge">8</span>
                              <i onClick={()=>{this.setState({notifications_show:!this.state.notifications_show})}} className="material-icons circle_notifications text-white c_p">&#xe994;</i>
                            </div>
                          </span>
                          {this.RenderNotifications()}
                        </li> */}

                        <li className="nav-item dropdown dropdown-large">
                          <span className="nav-link dropdown-toggle dropdown-toggle-nocaret"  data-bs-toggle="dropdown" style={{marginTop:6}}>
                            <div className="notifications">
                              {/* <span className="notify-badge">8</span> */}
                              <i onClick={()=>{this.setState({mini_menu:!this.state.mini_menu})}} className="material-icons apps text-white c_p">&#xe5c3;</i>
                            </div>
                          </span>
                          {/* {this.RenderNotifications()} */}
                          {this.RenderDropdawnMenu()}
                        </li>

                        {this.RenderAlarmSubscrube()}
                        {/* {this.RenderMiniMenu()} */}
                        <li className="nav-item ">
                            <i style={{marginTop:6}}  onClick={()=>{this.LogOut()}} className="material-icons power_settings_new text-danger c_p"></i>                          
                        </li>
                    </ul>
                </div>
            </nav>
        </header>
      );
    }
}




