import React, { Component } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonSelect, IonSelectOption, IonItem } from '@ionic/react';
import { ApiReq, notySend } from '../components/function';
import Loading from '../pages/Loading';
export default class UsersPage extends Component<any, any> {
    constructor(props: any){
      super(props);
      this.state = {
        table_data:[]
      }
    }
  
    componentDidMount(){
      this.GetUsers();
    }

    GetUsers = () => {
      ApiReq('/users/get_users/', {select:['id', 'name', 'email', 'role'], where:{}}).then(res => {
        if(res != false){
          if(res.success == true){
            this.setState({
              table_data:res.data
            })
          }
          else{
            notySend('error', 'error #'+res.error+'\n'+res.info)
          }
        }
      })
    }
  
    RenderTable = () => {
      if(this.state.table_data.length == 0){
        return <div className='text-center'><Loading></Loading></div>;
      }
      else{
        return (
          <div className='table-responsive'>
            <table className="table text-white">
              <thead>
                <tr>
                  <th>id</th>
                  <th>name</th>
                  <th>email</th>
                  <th>role</th>
                  <th>edit</th>
                </tr>
              </thead>
              <tbody>
                {this.state.table_data.map((item:any) => {
                  if(item.role != 'partner'){
                    return (
                      <tr key={item.id}>
                        <td>{item.id}</td>
                        <td>{item.name}</td>
                        <td>{item.email}</td>
                        <td><span className="badge bg-primary rounded-pill ms-auto">{item.role}</span></td>
                        <td><a href={'/edit_user/'+item.id+'/'} className="btn btn-outline-primary">edit</a></td>
                      </tr>
                    )
                  }
                  
                })}
              </tbody>
            </table>
          </div>
        )
      }
    }
  
    render() {
      return (
        <div className='page-content'>
          <div className="card">
            <div className='card-body'>
                <div style={{textAlign:'right'}}>
                    <a href='/add_user/' className='btn btn-primary'><i style={{fontSize:16, margin:5}} className="material-icons control_point c_p">&#xe3ba;</i> add user</a>
                </div>
                <br></br>
                {this.RenderTable()}
            </div>
          </div>
        </div>
      );
    }
}

